import { handleColor } from "../MaskEditor";

const colorList = document.querySelector(".js-color-list");
const colorGlitter = document.querySelectorAll(".js-color-glitter");

/*const ColorShade = (col, amt) => {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}*/
const addLight = function(color, amount){
  let cc = parseInt(color,16) + amount;
  let c = (cc > 255) ? 255 : (cc);
  c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
}
const lighten = (color, amount)=> {
  color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
  amount = parseInt((255*amount)/100);
  return color = `#${addLight(color.substring(0,2), amount)}${addLight(color.substring(2,4), amount)}${addLight(color.substring(4,6), amount)}`;
}
const subtractLight = function(color, amount){
  let cc = parseInt(color,16) - amount;
  let c = (cc < 0) ? 0 : (cc);
  c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
}
const darken = (color, amount) =>{
  color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
  amount = parseInt((255*amount)/100);
  return color = `#${subtractLight(color.substring(0,2), amount)}${subtractLight(color.substring(2,4), amount)}${subtractLight(color.substring(4,6), amount)}`;
}

const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
}

const DrawHtmlGlitter = (hexColor, container) => {
  const maxAmount = 5;

  const colorShades = [
    lighten(hexColor, 30),
    lighten(hexColor, 15),
    darken(hexColor, 30),
    darken(hexColor, 15),
  ];


  for (let i = 0; i < maxAmount; i++) {
    let glit = document.createElement("div")

    glit.style.backgroundColor = colorShades[getRndInteger(0, colorShades.length)];
    glit.style.top = getRndInteger(0, 20)+"px";
    glit.style.left = getRndInteger(0, 10)+"px";
    glit.classList.add("glit");
    container.appendChild(glit);
  }
}

colorGlitter.forEach( ( colorEl ) => {

  colorEl.style.backgroundColor = colorEl.dataset.colorvalue;

  colorEl.innerHTML = '';
  DrawHtmlGlitter(colorEl.dataset.colorvalue, colorEl)

})




const startGlitterColors = () => {
  colorGlitter.forEach( ( colorEl ) => {

    colorEl.style.backgroundColor = colorEl.dataset.colorvalue;

    colorEl.innerHTML = '';
    DrawHtmlGlitter(colorEl.dataset.colorvalue, colorEl)

  }
  )}



const HandleColors = () => {
  let timer = null;
  colorList.addEventListener("click", (evt) => {
    if (evt.target.dataset.colorvalue) {
      handleColor(evt.target.dataset.colorvalue, evt.target.classList.contains('color-list__item--glitter'));
    }
  });

  colorList.addEventListener("mouseover", (evt) => {
    timer = setInterval( () => {
      if(evt.target.classList.contains('color-list__item--glitter')) {
        evt.target.innerHTML = '';
        DrawHtmlGlitter(evt.target.dataset.colorvalue, evt.target);
      }
    }, 100)
  });
  colorList.addEventListener("mouseout", (evt) => {
    clearInterval(timer)
  });
};



/* visibityToggle.addEventListener("click", (evt) => {
  console.log(evt.currentTarget.checked);
  //colorVisibility = evt.currentTarget.checked;
  handleVisibility(evt.currentTarget.checked);
}); */

export { HandleColors };
