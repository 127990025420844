import { Mask } from "../components/Mask";

const avatarContainer = document.getElementById("my-masks");

const tabTriggerBtns = document.querySelectorAll('.tabs li.tab');



async function first_detected() {
  setAvatarScrollPosition();
}

async function update_mask() {
  updateAvatar();
}

function updateAvatar() {
  let ctx = avatarContainer.children[(Mask.myMask.selectedIndex+1)].firstChild.getContext('2d');
  Mask.myMask.render(ctx, Mask.SAMPLE_POINTS);
}

function createMaskList() {

  // Show amount masks in submenu
  let amountAvatars = Mask.myMask.regionColoring.length;
  tabTriggerBtns.forEach(function(tabTriggerBtn) {
    if(tabTriggerBtn.getAttribute("data-tab-trigger") === 'my-avatars') {
      tabTriggerBtn.innerHTML = `My Masks (${amountAvatars})`;
    }
  })

  avatarContainer.innerHTML = "";
  avatarContainer.style.display = '';
  const elMyMaskAddMask = document.createElement("div");
  elMyMaskAddMask.className="add-mask";
  const elMyMaskAddMaskInner = document.createElement("div");
  elMyMaskAddMaskInner.className="add-mask__inner icon-add";
  elMyMaskAddMaskInner.addEventListener("click", () => {
    Mask.myMask.generateNewRandomMask();
    createMaskList();
  })
  elMyMaskAddMask.appendChild(elMyMaskAddMaskInner);
  avatarContainer.appendChild(elMyMaskAddMask);

  // append avatar frames for each region & highlight current mask
  for(let i = 0; i < Mask.myMask.maskCount() ; i++) {

    const elMyMask = document.createElement("div");
    elMyMask.className="my-mask";

    const avatar = document.createElement('canvas');
    avatar.width = 1200;
    avatar.height = 900;
    avatar.className = 'my-mask__frame js-my-masks__frame';
    const maskControls = document.createElement('div');
    maskControls.className="mask-controls ";
    const btnCreateRandomMask = document.createElement('div');
    btnCreateRandomMask.className="js-generate-random-mask icon-renew-mask btn-renew icon overlay";
    const btnDeleteMask = document.createElement('div');
    btnDeleteMask.className="icon-delete btn-delete js-delete-mask icon overlay";

    maskControls.appendChild(btnDeleteMask)
    maskControls.appendChild(btnCreateRandomMask)
    elMyMask.appendChild(avatar)

    elMyMask.appendChild(avatar);
    elMyMask.append(maskControls)
    avatarContainer.appendChild(elMyMask);

    Mask.myMask.render(avatar.getContext('2d'), Mask.SAMPLE_POINTS, 0, i);



    // Select current mask on init
    Mask.myMask.selectedIndex === i
        ? elMyMask.classList.add("selected")
        : elMyMask.classList.remove('selected');

    elMyMask.addEventListener("click", (evt) => {
      evt.stopPropagation();
      evt.target.classList.add('selected');
      Mask.myMask.setSelectedIndex(i);
      createMaskList();
    })

    btnCreateRandomMask.addEventListener("click", (evt) => {
      //if(Mask.myMask.getSelectedIndex() === i) {
        if(confirm('Sure randomize this mask?')) {
          Mask.myMask.setSelectedIndex(i)
          evt.stopPropagation();
          Mask.myMask.generateRandom();
          updateAvatar();
        }
      //}
    })
    btnDeleteMask.addEventListener("click", (evt) => {
      //if(Mask.myMask.getSelectedIndex() === i) {
        if(confirm('Sure delete this mask?')) {
          evt.stopPropagation();
          Mask.myMask.deleteMask(i);
          createMaskList();
        }
      //}
    })
  }
}

async function room_enter() {
  //Mask.myMask.regionColoring.reverse();
  createMaskList();
}

async function room_leave() {
  // SFG: removed for redesign, because room.leave before enter?
  // Room.js 258
  // avatarContainer.style.display = 'none';
}

// update scroll position to show selected avatar
function setAvatarScrollPosition() {
  let current = document.querySelector('.js-my-masks__frame.selected');
  if (current) {
    current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }
}

export default { room_enter, room_leave, update_mask, first_detected };
