

/* Mod that manages the on-off stage logic
 * This is done by loading or unloading mods depending on role & state */


async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

const SEED = "welalgo-f4a1033bb1-";

async function is_presenter(room_code) {
  let code = new URLSearchParams(window.location.search).get('c');
  console.log("Using password: ", code);
  const hash = await digestMessage(SEED + code);
  console.log(hash, room_code);
  return hash === room_code;
}


async function room_enter(room) {

  const presenting = await is_presenter(room.room_def.room_code);
  console.log('Presenter', presenting);
  if (presenting) {
    room.add_mod("RoomTitle");
  } else {
    room.add_mod("RoomTitle");
    room.add_mod("HelpText");
    room.add_mod("StageClosed");
  }

}

export default { room_enter }
