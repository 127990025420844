


/* Activity recording */

let recording_targets;
let current_target;

let prev_pts;


function frame_delta(pts1, pts2) {

  // simplest delta function
  return pts1.pts.map(function(pt1,i) {
    return {
      x: pt1.x-pts2.pts[i].x,
      y: pt1.y-pts2.pts[i].y,
    };
  });

}

async function face_points(pts) {
  if (prev_pts) {
    let delta = frame_delta(prev_pts, pts);
    let delta_quant = delta.map(
      (pt => Math.abs(pt.x)+Math.abs(pt.y)))
        .reduce((a,b) => a+b);
  }
  prev_pts = pts;
}

async function room_enter(room) {

}


async function room_leave(room) {
}


function set_record_target(target) {
  current_target = target;


}

window.record = set_record_target;

export default { room_enter, room_leave, face_points }

