import { Mask } from "../components/Mask";
import MyMasks from "./MyMasks"
import { Hints } from "./maskeditor/Hints";
import { HandleColors } from "./maskeditor/ColorSelector";
import { HandleColorsTypes } from "./maskeditor/ColorTypeSelector";
import { handleClick } from "./maskeditor/ClickSelect";
import MyAvatar from "../components/MyAvatar";
import { clearForm } from "./StartRoom";

// One-time setup handlers for colors and color-types
HandleColors();
HandleColorsTypes();
Hints();

const main = document.querySelector("div#main");
const maskEditor = document.getElementById("mask-editor");
// const btnExpandLessPowder = document.querySelector( ".js-toggle-powder-room .expand-less");
// const btnExpandMorePowder = document.querySelector( ".js-toggle-powder-room .expand-more");

// REDESIGN
const avatarEditor = document.getElementById("edit-avatar");
const openEditor = document.querySelector(".js-open-editor");
const closeEditor = document.querySelectorAll('.js-modal-close');

let _current_room;

let selRegionObjArr = [];

let selectedRegionIndexes = [-1];
let currentType = "fill";


// Set after color change so the selection ants don't interfere with result
// Also set in meeting room
let hideSelection = false;

// Magix region indices
const REGION_IDX_ALL = 0;
const REGION_IDX_NONE = -1;


// Set active color based on selection
const SetColorActive = () => {
  let tempArr = [];
  selRegionObjArr.forEach((r) => {
    tempArr.push(r[currentType]);
  });
  const allColors = document
    .querySelector(".js-color-list")
    .querySelectorAll(".js-color");

  allColors.forEach((color, i) => {
    if (tempArr.includes(color.dataset.colorvalue)) {
      color.parentElement.classList.add("color-list__item--active");
    } else {
      color.parentElement.classList.remove("color-list__item--active");
    }
  });
};

const handleType = (value) => {
  currentType = value;
  hideSelection = false;
  SetColorActive();
};

const handleColor = (color, addGlitter = false) => {
  if (selectedRegionIndexes[0] === REGION_IDX_NONE) {
    return;
  }

  selRegionObjArr.forEach((r) => {
    r[currentType] = color;
  });





  if (selectedRegionIndexes[0] === REGION_IDX_ALL) {
    // All Selected
    for (let n = 1; n < Mask.myMask.current().length; n++) {
      Mask.myMask.current()[n][currentType] = color;
      if(currentType === "fill") {
        Mask.myMask.current()[n].glitterFill = addGlitter;
      }
    }
  } else {
    // push latest changed region to end

    selectedRegionIndexes.forEach((selectedRegionIndex) => {
      let selRegionIdx = Mask.myMask.current().findIndex(
        (r) => r.region === selectedRegionIndex
      );
      let extractElm = Mask.myMask.current().splice(selRegionIdx, 1);
      if(currentType === "fill") {
        extractElm[0].glitterFill = addGlitter
      }
      Mask.myMask.current().push(extractElm[0]);
    });
  }
  Mask.myMask.saveToLocalStorage();
  SetColorActive();
  hideSelection = true;
  _current_room.fire_event('update_mask', true);

  // RN: Show latest updated mask in my masks list (MyMasks.js), room_enter regenerates mask list
  MyMasks.room_enter();

};

const getSelectedRegionIndexes = () => {
  return selectedRegionIndexes;
};

const getHideSelection = () => {
  return hideSelection || !main.classList.contains('mask_editor');
};

const handleRegion = (regionIndex, toggleMultiSelect = false) => {
  hideSelection = false;

  if (toggleMultiSelect) {
    var index = selectedRegionIndexes.indexOf(regionIndex);
    if (index > -1) {
      selectedRegionIndexes.splice(index, 1);
    } else {
      selectedRegionIndexes.push(regionIndex);
    }
  } else {
    selectedRegionIndexes = [];
    selectedRegionIndexes.push(regionIndex);
  }

  selRegionObjArr = Mask.myMask.current().filter((r) =>
    selectedRegionIndexes.includes(r.region)
  );

  SetColorActive();
  document
    .querySelector(".js-select-all")
    .classList.toggle("color-type--active", regionIndex === REGION_IDX_ALL);
};


// btnExpandLessPowder.addEventListener("click", () => {
//   maskEditor.classList.remove("active");
//   main.classList.remove('mask_editor');
//
//   if (!_current_room.is_init) {
//     _current_room.send({ command: 'broadcast-metadata', metadata: Mask.myMask.serialize(), pts: MyAvatar.get_points() });
//   }
//
//   MyAvatar.save_points(_current_room.me);
// });
//
// btnExpandMorePowder.addEventListener("click", () => {
//   maskEditor.classList.add("active");
//   main.classList.add('mask_editor');
// });



// REDESIGN
openEditor.addEventListener("click", () => {
  avatarEditor.classList.add('modal-visible');
  maskEditor.classList.add("active");
  main.classList.add('mask_editor');
});

function handleClose() {
    maskEditor.classList.remove("active");
    main.classList.remove('mask_editor');
    avatarEditor.classList.remove('modal-visible');

    // SFG: is this ok here? inside loop?
    if (!_current_room.is_init) {
      _current_room.send({ command: 'broadcast-metadata', metadata: Mask.myMask.serialize(), pts: MyAvatar.get_points() });
    }



  clearForm();


    MyAvatar.save_points(_current_room.me);
  if (_current_room.me.return_to) {
    _current_room.switchTo(_current_room.me.return_to);
  }

}

// Revisit for neater js?
for (let i = 0; i < closeEditor.length; i++) {
  closeEditor[i].addEventListener("click", handleClose);

}


window.dump = function() {
  return JSON.stringify(Mask.myMask.current());
}

async function room_enter(room) {
  // For code-historical reasons, the mask-editor stuff is always present and just gets unhidden here

  document.getElementById('mask-editor').style.display = '';
  _current_room = room;
}

async function room_leave() {

  document.getElementById('mask-editor').style.display = 'none';
  _current_room = null;
}

async function click_me(evt) {
  handleClick(evt);
}

async function open_mask_editor() {

  maskEditor.classList.add("active");
  main.classList.add('mask_editor');

}

export {
  handleRegion,
  getSelectedRegionIndexes,
  getHideSelection,
  handleColor,
  handleType,
  currentType,
  REGION_IDX_ALL,
  REGION_IDX_NONE,
};

export default { room_enter, room_leave, click_me, open_mask_editor }
