// Main frame animation functio
// Renders self, others and sends own mask data

import { Settings } from "../globals";
import { Mask } from "../components/Mask";
import { Codec } from "../components/Codec";
import { Media } from "../components/Media";
import { Face } from "../components/Face";
import resize from "../components/Resizer";
import MyAvatar from "../components/MyAvatar";
import Smoother from "../components/Smoother";



console.log('#################### loaded facescan');

let _first_detected = false;
var _main = document.getElementById('main');
var _detect_failed = document.getElementById("_detect_failed");
var _room_element = document.getElementById("_room");
let _webcam = document.getElementById("_webcam");
let _imageData = document.getElementById("_imageData");

let _selfRender = document.getElementById("_selfRender");
let _selfRenderRoom = document.getElementById("u_me");

// const _doors = [null,
//   document.getElementById('door1'),
//   document.getElementById('door2'),
//   document.getElementById('door3'),
//   document.getElementById('door4')
// ];


// let _door_touch_started = null;

// self points on _selfRender, used for click-detection
var _ptsSelf = null;

var _messagesInFlight = 0;

let _my_pos = { x: 0, y: 0 };
let _my_tween = 0;

// _width and _height will depend on the video or image dimensions
let _width = Settings.CAMERA_WIDTH;
let _height = Settings.CAMERA_HEIGHT;

// Triggers replacement of my position on entering room
let _doResetPosition = true;
let faceMesh;

var lastTime;

var _current_room;

let smoothX = new Smoother(Settings.FPS);
let smoothY = new Smoother(Settings.FPS);

// This is initialized on room enter but runs thoughout the entire session
async function init() {
  if (faceMesh) return;

  console.log("Initialize BRF52");

  await Media.Init().then(BeginAnimate);

  faceMesh = new FaceMesh({locateFile: (file) => {
    return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
  }});
  faceMesh.setOptions({
    maxNumFaces: 1,
    refineLandmarks: true,
    minDetectionConfidence: 0.2, // changed from sample 0.5
    minTrackingConfidence: 0.2 // changed from sample 0.5
  });
  faceMesh.onResults(onResults);
}
  // document.getElementById('checkbox_door').checked = false;
// document.getElementById('checkbox_door').addEventListener('change', function() {
  // document.querySelector('.doors').style.display = this.checked ? 'block' : 'none';
// });


// Random positioning
function resetPosition() {
  _my_pos.x = (Math.random() - 0.5) * 2 * Settings.RESTRICT_INITIAL_POSITION;
  _my_pos.y = (Math.random() - 0.5) * 2 * Settings.RESTRICT_INITIAL_POSITION;
  console.log("Initial room position ", _my_pos);
  _doResetPosition = false;
}
resetPosition();

// Starts the rendering and detecion loop
function BeginAnimate() {

  resize();
  lastTime = performance.now();

  requestAnimationFrame(animate);
}



/// Returns the current webcam frame, cut to 640x480 as ArrayBuffer
function getFrameAsBuffer() {
  const ctx = _imageData.getContext("2d");
  let vw = _webcam.videoWidth;
  let vh = _webcam.videoHeight;
  if (vw / vh > 1.31 && vw / vh < 1.35) {
    // approximately 4/3. just draw the frame
    ctx.drawImage(
      _webcam,
      0, 0, _webcam.videoWidth, _webcam.videoHeight,
      0, 0, _width, _height
    );
  } else if (vw / vh < 4 / 3) {
    // probably portrait. Center vertically
    let margin = (_width - (_height / vh) * vw) / 2;
    ctx.drawImage(
      _webcam,
      0, 0, _webcam.videoWidth, _webcam.videoHeight,
      margin, 0, _width - margin * 2, _height
    );
  } else {
    // probably wide format 16/9. Center horizintally
    let margin = (_height - (_width / vw) * vh) / 2;
    ctx.drawImage(
      _webcam,
      0, 0, _webcam.videoWidth, _webcam.videoHeight,
      0, margin, _width, _height - margin * 2
    );
  }
  let buffer = new Uint8ClampedArray(_width * _height * 4);
  buffer.set(ctx.getImageData(0, 0, _width, _height).data);
  return buffer.buffer;
}

let lastBuffer;
let detectErrors = 0;
function onResults(results) {
  if (!results.multiFaceLandmarks || !results.multiFaceLandmarks[0]) {
    detectErrors += 1;
    if (!lastBuffer || detectErrors > 3) {
      worker_message({data: "not_detected" });

    }
    else {
        worker_message({data: lastBuffer});
    }
    return;
  }
  const face = Face.fromMediaPipe(results, _my_pos);

  //console.log("RESULTS", results, face);

  face.move({ w: _room_element.offsetWidth, h: _room_element.offsetHeight },
    _selfRenderRoom.offsetWidth);
  lastBuffer = face.getBuffer();
  detectErrors = 0;

  worker_message({data: lastBuffer});
}

// Main animate function. Notifies the worker to parse a frame
function animate(newTime) {
  requestAnimationFrame(animate);

  // Skip to reduce framerate to Settings.FPS
  let elapsed = newTime - lastTime;
  if (elapsed < 1000 / Settings.FPS || _messagesInFlight > 0) {
    return;
  }
  lastTime = newTime - (elapsed % (1000 / Settings.FPS));

  /*
  // Post camera frame to brfv5 worker
  _worker.postMessage({
    time: performance.now(),
    buffer: getFrameAsBuffer(),
    pos: _my_pos,
    me_size: _selfRenderRoom.offsetWidth,
    room: { w: _room_element.offsetWidth, h: _room_element.offsetHeight },
  });
  */


  getFrameAsBuffer();
  faceMesh.send({image: _imageData});

  _messagesInFlight += 1;
  return;
}


function worker_message(e) {
  _messagesInFlight -= 1;
  if (e.data === "not_loaded") return;
  if (!_current_room) return;
  if (_current_room.me.frozen) return;


  const inInitScreen =
    window.getComputedStyle(document.querySelector(".initScreen")).display !==
    "none";
  const notDetected = e.data === "not_detected";

  if (notDetected) {
    window.detect_confidence = 0;
    // initscreen has its own not-detected message
    if (!inInitScreen) {
      _detect_failed.style.display = "inherit";
    }
    return;
  }
  window.detect_confidence = 1;

  _detect_failed.style.display = "none";

  const doBlur = _current_room && _current_room.me.volume > Settings.AUDIO_BLUR_THRESHOLD;
  Codec.setMark(e.data, doBlur ? 1 : 0);
  // We enter the fitting room (or powder-room linked by url)
  // when we first detect

  if (!_first_detected) {

    // Start outside any room
    _first_detected = true;
    console.log('firing first_detected');
    _current_room.fire_event('first_detected');
    scaleSelf();
    return;
  }

  // If in a _room we should broadcast our points to peers
  _current_room.fire_event('face_scanned', e.data);

  // Render to large self  (_selfRender) in powder/fitting room
  if (!_current_room || _current_room.is_init || _main.classList.contains('mask_editor') || _main.classList.contains("zoomed")) {

    let selfData = Codec.decodeLandmarks(
      e.data,
      { width: _selfRender.width, height: _selfRender.height },
      { x: 0, y: 0 }
    );
    _ptsSelf = selfData.pts;
    if (_current_room) {
      _current_room.me.pts = selfData.pts;
    }
    scaleSelf();
    var ctxSelf = _selfRender.getContext("2d");
    ctxSelf.clearRect(0, 0, _selfRender.width, _selfRender.height);
    Mask.myMask.render(ctxSelf, selfData.pts);

  }
  else
  {
    // Render and move to correct position in MeetingRoom
    let pts = Codec.decodeLandmarks(
      e.data,
      { width: _selfRenderRoom.width, height: _selfRenderRoom.height },
      { x: 0, y: 0 }
    );
    _current_room.fire_event('face_points', pts);
    _ptsSelf = pts.pts;

    _my_pos = pts.pos;
    _current_room.me.pts = pts;
    if (!_current_room.me.fadeIn) {
      _my_tween = {
        type: 'chaos',
        progress: 1,
        auto_inc: true
      }
      _current_room.me.fadeIn = true;
    }

    // _doors[1].classList.toggle('active', _my_pos.door == 1);
    // _doors[2].classList.toggle('active', _my_pos.door == 2);
    // _doors[3].classList.toggle('active', _my_pos.door == 3);
    // _doors[4].classList.toggle('active', _my_pos.door == 4);
    //
    // // Tween through doors
    // if (_my_pos.door > 0 && document.getElementById('checkbox_door').checked) {
    //   const target_room = _current_room.room_def.doors[_my_pos.door-1];
    //
    //   if (target_room) {
    //     if (!_door_touch_started) {
    //       _door_touch_started = performance.now();
    //     } else if (performance.now() - _door_touch_started > Settings.DOOR_DELAY) {
    //       // move to room
    //       _door_touch_started = null;
    //       let entry_door = (_my_pos.door -1 + 2) % 4 + 1;
    //       console.log(entry_door);
    //       _my_pos = Settings.DOOR_ENTRIES[_my_pos.door - 1];
    //       _my_tween = {
    //         type: 'drop',
    //         dir: entry_door,
    //         progress: 1,
    //         auto_inc: true,
    //       };
    //
    //       _current_room.switchTo(target_room);
    //     } else {
    //       let pr = (performance.now() - _door_touch_started) / Settings.DOOR_DELAY;
    //       pr = Math.max(0,(pr - 0.5)*2);
    //       _my_tween = {
    //         type: 'drop',
    //         dir: _my_pos.door ,
    //         progress: pr
    //       };
    //
    //     }
    //   }
    // } else {
    //   _door_touch_started = null;
    //   if (_my_tween && _my_tween.type === 'drop' && !_my_tween.auto_inc) {
    //     _my_tween = 0;
    //   }
    // }

    Media.SetListenerPosition(_my_pos.x, _my_pos.y);
    let w = _room_element.offsetWidth;
    let h = _room_element.offsetHeight;
    let left = ((_my_pos.x + 1) / 2) * w - _selfRenderRoom.offsetWidth / 2;
    let top = ((_my_pos.y + 1) / 2) * h - _selfRenderRoom.offsetHeight / 2;
    _selfRenderRoom.style.left = left + "px";
    _selfRenderRoom.style.top = top + "px";

    // Render
    scaleCanvas(_selfRenderRoom);
    let ctxMe = _selfRenderRoom.getContext("2d");
    ctxMe.clearRect(0, 0, _selfRenderRoom.width, _selfRenderRoom.height);
    Mask.myMask.render(ctxMe, pts.pts, _my_tween, -1, doBlur);
    if (_current_room && _current_room.me.after_render) {
      _current_room.me.after_render(ctxMe, pts.pts);
    }

    if (_my_tween && _my_tween.auto_inc) {
      _my_tween.progress -= 0.1;
      if (_my_tween.progress < 0) {
        _my_tween = 0;
      }
    }
  }

  window.current_face_points = _ptsSelf;
};



function scaleSelf() {
  // Scale is triggered after a resize by setting dataset.scale
  // Actual scaling then happens on rendering to prevent flicker
  if (!_selfRender.dataset.scale) return;
  let marginX = window.innerWidth * (Settings.ZOOM_FITTING - 1);
  let marginY = window.innerHeight * (Settings.ZOOM_FITTING - 1);
  _selfRender.style.marginLeft = -marginX / 2 + "px";
  _selfRender.style.marginRight = -marginX / 2 + "px";
  _selfRender.style.marginTop = -marginY / 2 + "px";
  _selfRender.style.marginBottom = -marginY / 2 + "px";
  _selfRender.style.width = "calc(100% + " + marginX + "px)";
  _selfRender.style.height = "calc(100% + " + marginY + "px)";
  _selfRender.width = _selfRender.offsetWidth || 1000;
  _selfRender.height = _selfRender.offsetHeight || 1000;
  _current_room.me.width = _selfRender.width;
  _current_room.me.height = _selfRender.height;

  delete _selfRender.dataset.scale;
}

function scaleCanvas(canvas) {
  // Scale is triggered after a resize by setting dataset.scale
  // Actual scaling then happens on rendering to prevent flicker
  if (!canvas.dataset.scale) return;
  const user_size = canvas.dataset.scale;
  canvas.style.width = user_size + "px";
  canvas.style.height = user_size + "px";
  canvas.width = user_size;
  canvas.height = user_size;
  delete canvas.dataset.scale;
}

// Clicking on self fires an event to any listening mod
function clickHandler(evt) {
  _current_room.fire_event('click_me', evt);
}



async function room_enter(room) {
  console.log("onEnterRoom", room);
  _current_room = room;

  if (room.me.is_viewer && !room.me.is_dressing) return;
  await init();

  _selfRender.addEventListener('mousedown', clickHandler);
  room.send({ command: 'broadcast-metadata', metadata: Mask.myMask.serialize(), pts: MyAvatar.get_points() });
  resize();

};

async function room_leave() {
  _selfRender.removeEventListener('mousedown', clickHandler);
  _current_room = null;
}

export default { room_enter, room_leave };
