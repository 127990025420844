

import { get_room_def } from "../components/RoomDef";

let _current_room_name;

let _done = [];

async function drawRoom(room_name, def, x ,y) {

  // console.log('def', room_def);

  // draw actual room room_name on canvas at grid pos x,y
  let canvas = document.getElementById("_mapCanvas");
  canvas.classList.add("room_map");

  // SFG: now fixed to 3, how to calculate # to divide?
  // for now, use fixed items
  let roomCols = 6;
  let roomRows = 6;
  let posx = canvas.width / roomCols + x * canvas.width / roomCols;
  let posy = (y * canvas.height / roomRows) + canvas.height / roomRows;

  // console.log("posX", posx);
  // console.log("posY", posy);

  var ctx = canvas.getContext('2d');
  ctx.fillStyle = '#00a0ff';
  ctx.strokeStyle = '#00a0ff';
  ctx.strokeRect(posx, posy, canvas.width / roomCols, canvas.height / roomRows);

  ctx.font = '16px sans-serif';
  ctx.direction = 'ltr';
  ctx.textAlign = 'start';
  ctx.fillText(def.title, posx + 16, posy + 32);

  if (room_name === _current_room_name) {
    let midx = posx + canvas.width / roomCols / 2;
    let midy = posy + canvas.height / roomRows / 2;
    ctx.fillText('ME', midx,midy);

  }

  console.log("DRAWING", room_name, def, x, y);
}


async function iterAllRooms(func) {
  _done = [];
  await iterRoom('lobby',0,0,func);
}


async function iterRoom(room_name,x ,y,func) {

  if (_done.indexOf(room_name)>-1) {
    return;
  }

  let def = await get_room_def(room_name);

  func(room_name, def, x, y);

  _done.push(room_name);

  if (def.doors[0]) {
    await iterRoom(def.doors[0], x+1, y, func);
  }
  if (def.doors[1]) {
    await iterRoom(def.doors[1], x, y-1, func);
  }
  if (def.doors[2]) {
    await iterRoom(def.doors[2], x-1, y, func);
  }
  if (def.doors[3]) {
    await iterRoom(def.doors[3], x, y+1, func);
  }
}


let map_content = `
    <div class='map js-map'>
      <div class='map-close js-map-close overlay material-icons'>close</div>
        <div class='map__floorplan'>

        <canvas id='_mapCanvas' width="1200" height="900"></canvas>

        </div>

    </div>
`;


async function create_map(room) {


  iterAllRooms(drawRoom);
}

async function room_enter(room) {

  // show map icon in room controls when Map mod is available
  // document.querySelector('.js-map-open').classList.remove('invisible');
  //
  // let div = document.createElement('div');
  // div.className = 'map-container';
  // div.innerHTML = map_content;
  //
  //
  // document.querySelector('main').appendChild(div);
  // room.div_map = div;
  //
  // const map = document.querySelector('.js-map');
  // document.querySelector('.js-map-open').addEventListener("click", () => {
  //   map.classList.add('active');
  //   create_map(room);
  // });
  //
  // document.querySelector('.js-map-close').addEventListener("click", () => {
  //   map.classList.remove('active');
  // });
  //
  // // Pattern for mod communication:
  // // We add a function to the room object that can be used by other mods
  // room.set_map = function(html) {
  //   document.querySelector('.map__floorplan').innerHTML = html;
  // }
  //
  // room.show_map = function() {
  //   map.classList.add('active');
  // }

  _current_room_name = room.name;
}

async function room_leave(room) {
  // document.querySelector('main').removeChild(room.div_map);
  // room.div_map = null;
}


export default { room_enter, room_leave }
