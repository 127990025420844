

import { Room } from "../components/Room.js";
import { Feedback } from "./init/Feedback";
import { get_room_title }  from "../components/RoomDef";
import resize from "../components/Resizer";
import { get_room_def } from "../components/RoomDef";
import MyAvatar from "../components/MyAvatar";


let goHandler;

let _current_room;

/// Handle "GO" button
async function go() {

  document.getElementById("mask-editor").classList.remove("active");
  main.classList.add("in_room");

  // Extract room and custom mods from URL

  const roomName = decodeURIComponent(location.pathname.replace(/^\//, '')) || 'lobby';
  let mods_param = new URLSearchParams(window.location.search).get('mods');
  if (mods_param) {
    console.log(`Starting on  ${roomName} with custom mods ${mods_param}`);
    const mods = mods_param.split(/,/);
    Room.enter(roomName, mods );
  }
  else {
    Room.enter(roomName);
  }

  resize();
};


/* Mod that manages the on-off stage logic
 * This is done by loading or unloading mods depending on role & state */


async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

const SEED = "welalgo-f4a1033bb1-";

async function is_admin(room_code) {
  let code = new URLSearchParams(window.location.search).get('c');
  console.log("Using password: ", code);
  const hash = await digestMessage(SEED + code);
  console.log(hash, room_code);
  return hash === room_code;
}


async function room_enter(room) {
  _current_room = room;
  console.log("Init:room_enter called");
  room.is_init = true;

  if (window.self !== window.top) {
    console.log("Detected IFRAME, hiding header");
    document.querySelector('.masthead').style.display = 'none';
    document.querySelector('.menu-wrapper').style.display = 'none';
    document.querySelector('.theme-switch').style.display = 'none';
  }

  MyAvatar.load_points();


  const roomName = decodeURIComponent(location.pathname.replace(/^\//, '')) || 'lobby';
  const roomDef = await get_room_def(roomName);
  console.log(roomDef);

  room.me.is_admin = await is_admin(roomDef.room_code);

  room.me.is_viewer = !room.me.is_admin && roomDef.type === 'present' ;
  console.log('Admin: ', room.me.is_admin, 'Dressing', room.me.is_dressing);
  if (room.me.is_viewer && !room.me.is_dressing) {
    await room.fire_event('first_detected');
  } else {

    document.querySelector( ".js-room-controls" ).classList.remove( "hide" );
    // document.querySelector( ".js-toggle-powder-room" ).classList.remove( "hide" );
    document.querySelector( ".js-messaging" ).classList.remove( "hide" );
    document.querySelector( ".js-site-title" ).classList.remove( "hide" );

    if (room.me.is_dressing) {
      room.fire_event('open_mask_editor');
    }
  }
}


async function room_leave(room) {
  delete room.is_init;
  console.log('Leaving AND DELETE is_init');
  // const btnGo = document.querySelector(".js-btn-go");
  // btnGo.removeEventListener('click', goHandler);
}

async function first_detected() {
    for(let el of document.querySelectorAll(".js-status-message")) {
      el.style.display = "none";
    }
    main.classList.add("first_detected");

    // if body has class facelight_on, remove it,
    // facelight only on initialisation
    if(document.body.classList.contains('facelight_on'))  {
      document.body.classList.remove('facelight_on');
    }

    go();


}

export default { room_enter, room_leave, first_detected }
