import MyAvatar from "../components/MyAvatar";
import ctx from "../components/SvgCanvas";
import { Mask } from "../components/Mask";

let _movable;
let _current_room;
let coupon;

const products = {
  hoodie: {
    size: {
      width: 595, // these are the pixel sizes of the img
      height: 768
    },
    bounds: {
      left: 175,
      right: 413,
      top: 235,
      bottom: 467,
    },
    colors: {
      hex: ["#000000","#ec1520","#712c33","#deaba7","#ffbdcc","#201f32","#d5d5d5","#727564","#95bdda","#676d6b","#ef4718","#00725a","#ffffff","#353a3c","#c9b7a1","#324b47","#82252d","#6caa9b","#d85a5b","#003b98","#f8947c"],
      name: ["black","brightred","burgundy","canyonpink","cottonpink","frenchnavy","heathergrey","midheatherkhaki","skyblue","slubheathersteelgrey","tangerine","varsitygreen","white","darkheathergrey","desertdust","glazedgreen","heatherneppyburgundy","midheathergreen","midheatherred","royalblue","sunsetorange"],
      color: ["Black","Bright Red","Burgundy","Canyon Pink","Cotton Pink","French Navy","Heather Grey","Mid Heather Khaki","Sky blue","Slub Heather Steel Grey","Tangerine","Varsity Green","White","Dark Heather Grey","Desert Dust","Glazed Green","Heather Neppy Burgundy","Mid Heather Green","Mid Heather Red","Royal Blue","Sunset Orange"],
    }
  },
  sweatshirt: {
    size: {
      width: 595, // these are the pixel sizes of the img
      height: 768
    },
    bounds: {
      left: 171,
      right: 427,
      top: 180,
      bottom: 430,
    },
    colors: {
      hex: ["#000000","#325447","#ff7716","#ec1520","#712c33","#b78a6b","#ffbdcc","#43536c","#201f32","#f5d300","#d5d5d5","#727564","#95bdda","#ef4718","#00725a","#ffffff","#deaba7","#676d6b"],
      name: ["black","bottlegreen","brightorange","brightred","burgundy","camel","cottonpink","darkheatherblue","frenchnavy","goldenyellow","heathergrey","midheatherkhaki","skyblue","tangerine","varsitygreen","white","canyonpink","slubheathersteelgrey"],
      color: ["Black","Bottle Green","Bright Orange","Bright Red","Burgundy","Camel","Cotton Pink","Dark Heather Blue","French Navy","Golden Yellow","Heather Grey","Mid Heather Khaki","Sky Blue","Tangerine","Varsity Green","White","Canyon Pink","Slub Heather Steel Grey"],
    }
  },
  tshirt: {
    size: {
      width: 595, // these are the pixel sizes of the img
      height: 768
    },
    bounds: {
      left: 176,
      right: 412,
      top: 225,
      bottom: 467,
    },
    colors: {
      hex: ["#585858","#0DA3CE","#000000","#325447","#FF7716","#ec1520","#712C33","#b78a6b","#FFBDCC","#43536c","#353A3C","#3B496D","#c9b7a1","#ede0d8","#201F32","#f5d300","#2b3131","#D5D5D5","#82252d","#5A594F","#6caa9b","#727564","#d85a5b","#594765","#C02C41","#003B98","#95BDDA","#adadaf","#23535f","#ef4718","#00725A","#F0ECE7","#ffffff"],
      name: ["anthracite","azur","black","bottlegreen","brightorange","brightred","burgundy","camel","cottonpink","darkheatherblue","darkheathergrey","darkheatherindigo","desertdust","ecruneppymandarine","frenchnavy","goldenyellow","heatherblackdenim","heathergrey","heatherneppyburgundy","khaki","midheathergreen","midheatherkhaki","midheatherred","plum","red","royalblue","skyblue","slubheathergrey","stargazer","tangerine","varsitygreen","vintagewhite","white"],
      color: ["Anthracite","Azur","Black","Bottle Green","Bright Orange","Bright Red","Burgundy","Camel","Cotton Pink","Dark Heather Blue","Dark Heather Grey","Dark Heather Indigo","Desert Dust","Ecru Neppy Mandarine","French Navy","Golden Yellow","Heather Black Denim","Heather Grey","Heather Neppy Burgundy","Khaki","Mid Heather Green","Mid Heather Khaki","Mid Heather Red","Plum","Red","Royal Blue","Sky blue","Slub Heather Grey","Stargazer","Tangerine","Varsity Green","Vintage white","White"],
    }
  },
  bag: {
    size: {
      width: 595, // these are the pixel sizes of the img
      height: 768
    },
    bounds: {
      left: 162,
      right: 413,
      top: 364,
      bottom: 613,
    },
    colors: {
      hex: ["#000000","#D5D5D5","#41465A","#EFE9DE"],
      name: ["black","heathergrey","midnightblue","natural"],
      color: ["Black","Heather Grey","Midnight Blue","Natural"],
    }
  }
}


// SFG: doing a avatar editor hack yeah!
// in redesign, mask editor is in modal stylo.
// we want to keep merch, so need to hack our way into 'old school' stylo

// no room controls in wear-wealgo
if(window.location.pathname === '/wear-wealgo') {
  document.querySelector('.room-controls').style.display = 'none';
}

// const main = document.querySelector("div#main");
const footer = document.querySelector('footer');

// put old school buts back into da dom
const toggleBtnsInner = `<div class="mask-toggle js-toggle-powder-room active">
                            <div class="tooltip editor-close">
                              <button class="icon expand-less overlay hide icon-close-editor"></button>
                              <span class="tooltiptext north">Hide Editor</span>
                            </div>
                            <div class="tooltip editor-open">
                              <button class="icon expand-more overlay icon-open-editor"></button>
                              <span class="tooltiptext north">Edit Avatar</span>
                            </div>
                          </div>`;



function getPrintableArea() {
  let product = products[document.getElementById('product').value];
  var bg = document.getElementById('_selfRender').parentNode;

  // the hoodie image is centered and maximized with object-fit
  // we must first check if its bounded by height of width
  if (product.size.width / product.size.height < bg.offsetWidth / bg.offsetHeight) {
    // bound by vertical size
    const imgpxPerScreenpx = product.size.height / bg.offsetHeight;
    const leftMargin = (bg.offsetWidth - (product.size.width / imgpxPerScreenpx)) / 2;
    return {
      left: leftMargin + (product.bounds.left / imgpxPerScreenpx),
      right: leftMargin + (product.bounds.right / imgpxPerScreenpx),
      top: product.bounds.top / imgpxPerScreenpx,
      bottom: product.bounds.bottom / imgpxPerScreenpx
    };
  }
  else {
    // bound by vertical size
    const imgpxPerScreenpx = product.size.width / bg.offsetWidth;
    const topMargin = (bg.offsetHeight - (product.size.height / imgpxPerScreenpx)) / 2;
    return {
      left: product.bounds.left / imgpxPerScreenpx,
      right: product.bounds.right / imgpxPerScreenpx,
      top: topMargin + (product.bounds.top / imgpxPerScreenpx),
      bottom: topMargin + (product.bounds.bottom / imgpxPerScreenpx)
    };

  }
}


async function room_enter(room) {

  _current_room = room;

  coupon = new URLSearchParams(window.location.search).get('coupon');

  // background & other colors, light grey ... dont invert logo animated
  document.documentElement.style.setProperty('--bg-color', '#f5f5f5');
  document.documentElement.style.setProperty('--bg-color-alpha', 'rgba(245,245,245, 0.86)');
  document.documentElement.style.setProperty('--txt-color', 'rgba(0,0,0,.82)');
  document.documentElement.style.setProperty('--txt-color-inv', 'rgba(255,255,255,.82)');
  document.documentElement.style.setProperty('--bg-color-inv', 'rgba(0,0,0,.82)');
  document.documentElement.style.setProperty('--border-color', 'rgba(0,0,0,.1)');
  document.documentElement.style.setProperty('--filter', 'invert(0)');
  document.querySelector('header div.title').style.display = 'none';
  document.querySelector('nav.menu').style.display = 'none';


  // add (legacy) toggle mask editor buttons
  let toggleBtns = document.createElement('div');
  toggleBtns.innerHTML = toggleBtnsInner;
  footer.appendChild(toggleBtns);
  const btnExpandLessPowder = document.querySelector( ".js-toggle-powder-room .expand-less");
  const btnExpandMorePowder = document.querySelector( ".js-toggle-powder-room .expand-more");


  // Toggle Mask Editor
  btnExpandLessPowder.addEventListener("click", () => {
    maskEditor.classList.remove("active");
    main.classList.remove('mask_editor');
    btnExpandLessPowder.classList.add('hide');
    btnExpandMorePowder.classList.remove('hide');

    if (!_current_room.is_init) {
      _current_room.send({ command: 'broadcast-metadata', metadata: Mask.myMask.serialize(), pts: MyAvatar.get_points() });
    }

    MyAvatar.save_points(_current_room.me);
  });

  btnExpandMorePowder.addEventListener("click", () => {
    maskEditor.classList.add("active");
    main.classList.add('mask_editor');
    btnExpandLessPowder.classList.remove('hide');
    btnExpandMorePowder.classList.add('hide');
  });



  // set smallScreens div, replace w/ merch_selectors toggle
  // let smallScreens = document.createElement('div');
  // smallScreens.innerHTML = `
  //     <div class='help active'>
  //         <div class='help__text center'>
  //           We're sorry ... Wear Wealgo is currently not very functional on mobile.<br><br>
  //           We are bussy working to support this, in the meantime, try this page on a larger device ...
  //           or <a href="https://where.wealgo.org/lobby">Join The Lobby</a>
  //         </div>
  //     </div>
  // `;
  //
  // smallScreens.classList.add('small_screens');
  // document.querySelector('.video-container').appendChild(smallScreens);


  let elm = document.createElement('div');
  elm.innerHTML = `
    <form id="merch" method="post" target="_blank" action="` + process.env.MERCH_SERVER + `">

      <div class="tooltip snap-avatar">
        <button id="freeze" class="icon overlay icon-snap-avatar"></button>
        <span class="tooltiptext north">Snap Avatar</span>
      </div>


      <div class="tooltip snap-cancel">
        <button id="cancel" class="icon overlay again icon-snap-again"></button>
        <span class="tooltiptext north">Snap Again</span>
      </div>


      <input type="hidden" id="svg" name="svg">

      <div class="tooltip cart">
        <button type="submit" value="checkout" id="checkout" class="hoodie overlay icon icon-cart"></button>
        <span class="tooltiptext north">Proceed to Checkout</span>
      </div>


      <p id="position" class="hoodie red overlay"  style='display:none;' /><span class="icon icon-warning"></span>Position Avatar in printable area</p>


      <div class='merch_selectors_wrapper js-merch-selectors'>
       <div class="merch_selectors">

        <!-- toggle merch selectors on small screens -->
        <div class="merch_toggle">
         <div class="icon overlay merch_selectors_toggle" id="js-merch-toggle"></div>
        </div>

        <div class='merch_product'>
          <label for='product'>Product:</label>
          <select id='product' name="product">
            <option value="hoodie" selected>Hoodie <span class="product-price">(€80)</span></option>
            <option value="sweatshirt">Sweatshirt <span class="product-price">(€75)</span></option>
            <option value="tshirt">Tshirt <span class="product-price">(€35)</span></option>
            <option value="bag">Tote Bag <span class="product-price">(€20)</span></option>
          </select>
        </div>

        <p id="coupon_warning" class="coupon-warning xs" style="display:none;">Your Gift Coupon cannot not be redeemed for this product ... choose: <span class="capitalize smll">${coupon}</span></p>

        <div class='merch_color'>
        <div class='merch_color__wrapper'>
          <label for='color'>Color:</label>
            <div id='merch_color_text'>Heather Grey</div>
            <input type='hidden' name='color_hex' id='color_hex' value='#D5D5D5'>
            <input type='hidden' name='color_name' id='color_name' value='heathergrey'>
            <input type='hidden' name='color_color' id='color_color' value='Heather Grey'>
        </div>

          <div id='product_colors' class='colors'></div>


        </div>

        <div class='merch_size'>
        <label for='size'>Size:</label>
          <input type='hidden' name='size' id='size' value='M'>
          <div class="sizes">
            <div class="size" data-size="XS">XS</div>
            <div class="size" data-size="S">S</div>
            <div class="size selected" data-size="M">M</div>
            <div class="size" data-size="L">L</div>
            <div class="size" data-size="XL">XL</div>
            <div class="size" data-size="XXL">XXL</div>
          </div>
        </div>

        <!-- store avatar size for printing ... hide -->
        <div class='avatar_size'>
          <input type='hidden' id="margin-left" name='left'>
          <input type='hidden' id="margin-right" name='right'>
          <input type='hidden' id="margin-top" name='top'>
          <input type='hidden' id="margin-bottom" name='bottom'>
        </div>

       </div> <!-- merch_selectors -->
      </div> <!-- merch_selectors_wrapper -->

    </form>
    `;


  // REDESIGN
  // room-functions no longer exists, recreate it
  let controls = document.createElement('div');
  controls.classList.add('room-functions');
  document.querySelector('footer').appendChild(controls);

  // create new mask editor container ...
  let me_container = document.createElement('div');
  me_container.classList.add('me-container');
  document.querySelector('footer').appendChild(me_container);

  // mask-editor is now modal based, clone to historical location (footer)
  let mask_editor = document.getElementById('mask-editor');
  document.querySelector('.me-container').appendChild(mask_editor);

  // trying some
  const maskEditor = document.getElementById("mask-editor");


  document.getElementsByClassName('room-functions')[0].appendChild(elm);
  let sr  = document.getElementById('_selfRender');
  let freeze = document.getElementById('freeze');
  let cancel = document.getElementById('cancel');
  let submit = document.getElementById('checkout');
  cancel.style.display = 'none';
  submit.style.display = 'none';

  freeze.onclick = function(e) {
    console.log("exporting", room.me.pts, Mask.myMask);
    let c = new ctx(sr.width,sr.height);
    Mask.myMask.render(c, room.me.pts);
    room.me.frozen = true;
    let ser = c.getSerializedSvg();
    document.getElementById('svg').value = ser;
    cancel.style.display = '';
    submit.style.display = '';
    freeze.style.display = 'none';
    createResizer(room.me.pts);

    // SFG: hiding mask editor pencil when avatar = snapped
    document.querySelector('.mask-toggle').style.display = 'none';
    document.getElementById('mask-editor').style.display = 'none';

    return false;
  }
  cancel.onclick = function() {
    var bg = document.getElementById('_selfRender');
    bg.style.display = '';
    updateBoundsBox();
    _movable.destroy();
    document.getElementById('wear-border').style.display = 'none';
    document.getElementById('wear-border').innerHTML = '';


    cancel.style.display = 'none';
    submit.style.display = 'none';
    freeze.style.display = '';
    delete room.me.frozen;

    // SFG: show editor pencil when canceled
    document.querySelector('.mask-toggle').style.display = 'block';
    document.getElementById('mask-editor').style.display = 'block';
    document.getElementById('position').style.display = 'none';

    return false;
  }

  var _main = document.getElementById('main');
  _main.classList.add('zoomed');

  if (coupon) {
      document.getElementById('product').value = coupon;
  }

  let prod = coupon || 'hoodie';

  if(prod == 'bag') {
    document.querySelector('.merch_size').style.display = 'none';
  } else {
    document.querySelector('.merch_size').style.display = 'block';
  }
  // SFG: set default product/color combination to: hoodie_yellow
  const canvasBgTags = `<img id="product_img" src="/images/merch/${prod}_heathergrey.png" style="position: absolute; object-fit: contain; width:100%;height:100%; object-position: center;display:block;" > `;
  const canvasBg = document.createElement('div');
  canvasBg.innerHTML = canvasBgTags;


  // sr.classList.add('hoodie');
  var bg = document.getElementById('_selfRender');
  bg.parentNode.insertBefore(canvasBg, bg);

  bg.parentNode.addEventListener('mousedown', function(ev) {
    if (_movable && ev.target.tagName !== 'CANVAS') {
        document.getElementsByClassName('moveable-control-box')[0].style.display = 'none';

    }
  })

  let border = document.createElement('div');
  border.id = 'wear-border';
  border.style.position = 'absolute';
  border.style.display = 'none';
  bg.parentNode.insertBefore(border, bg);

  let bounds = document.createElement('div');
  bounds.id = 'bounds-box';
  bounds.style.position = 'absolute';
  bounds.style.background = 'black';
  bounds.style.opacity = '40%';
  bounds.style.display = 'none';
  bounds.style.border = '1px solid #fff';
  bg.parentNode.insertBefore(bounds, border);
  updateBoundsBox();



  // remove colorswatches from dom
  function clearColorSwatches() {
    document.querySelectorAll('.color').forEach(e => e.remove());
  }


  function setimg() {


    let product = document.getElementById('product').value;
    let color = document.getElementById('color_name').value;
    var product_img = document.getElementById('product_img');

    // SFG: hack to always set color if no color selected but product changed
    if(color) {
      product_img.src = "/images/merch/" + product + "_" + color + ".png";
    } else {
      product_img.src = "/images/merch/" + product + "_heathergrey.png";
    }
  }


  function changeColor(e) {

    // get the hex color
    let color = e.target.getAttribute("data-name");
    let color_hex = e.target.getAttribute("data-hex");
    let color_text = e.target.getAttribute("data-color");
    // add hex to hidden color input field
    document.getElementById("color_name").value = color;
    document.getElementById("color_hex").value = color_hex;
    document.getElementById("color_color").value = color_text;
    document.getElementById("merch_color_text").innerHTML = color_text;

    setimg();
  }

  // SFG: dynamically create color swatches based on color definition in products
  function setColorSwatches() {
    let product = products[document.getElementById('product').value];
    for(var i=0; i < product.colors.color.length; i++) {
      // console.log(product.colors.color[i]);
      let swatch = document.createElement('div');
      swatch.classList.add('color');
      swatch.onclick = changeColor;
      swatch.style.background = product.colors.hex[i];
      swatch.setAttribute("data-hex", product.colors.hex[i]);
      swatch.setAttribute("data-name", product.colors.name[i]);
      swatch.setAttribute("data-color", product.colors.color[i]);
      swatch.setAttribute("title", product.colors.color[i]);
      document.getElementById('product_colors').appendChild(swatch);
    }
  }

  setColorSwatches();

  // SFG:
  // * if product type == bag, remove size select
  // * switch product image onchange select
  document.getElementById('product').onchange = function() {
    let prod_name = document.getElementById('product').value;
    let product = products[prod_name];
    let show_warning = coupon && (coupon !== prod_name);
    document.getElementById('coupon_warning').style.display = show_warning ? 'block' : 'none';
    let color = document.getElementById('color_name').value;
    if(this.value == 'bag') {
      document.querySelector('.merch_size').style.display = 'none';
    } else {
      document.querySelector('.merch_size').style.display = 'block';
    }
    clearColorSwatches();
    setColorSwatches();

    if (product.colors.name.indexOf(color) === -1) {
      document.getElementById("color_name").value = product.colors.name[0];
      document.getElementById("color_hex").value = product.colors.hex[0];
      document.getElementById("merch_color_text").innerHTML = product.colors.color[0];

    }
    setimg();
  }



  function clearSelected() {
    // remove selected from default & this clickec
    var selected = document.querySelectorAll('.selected');
    for (var n = 0; n < selected.length; n++) {
        selected[n].classList.remove('selected');
    }
  }


  // set size
  var sz = document.getElementsByClassName("size");
  for( var i = 0; i < sz.length; i++) {
    sz[i].addEventListener("click", function(e) {

    clearSelected();

    this.classList.add('selected');

    // set size to hidden input field
    let size = e.target.getAttribute('data-size');
    document.getElementById('size').value = size;

    });
  }

  setupResizer();


  window.addEventListener('resize', updateBoundsBox);

  merchToggle();

}

// SFG: small screens toggle merch-selectors
// needs another update: allow drag svg & not jump to checkout straighaway
function merchToggle() {
  let m_selectors = document.querySelector('.js-merch-selectors');
  let m_toggle = document.getElementById('js-merch-toggle');
  if(m_toggle) {
    m_toggle.addEventListener('click', function(){
      m_selectors.classList.toggle('show_selectors');
    });
  }
}


function updateBoundsBox() {
  let bounds = document.getElementById('bounds-box');

  // if we're still in selfrender mode, we hide this box
  let selfRender = document.getElementById('_selfRender');
  if (selfRender.style.display !== 'none') {
    bounds.style.display = 'none';
    return;
  }
  const b = getPrintableArea();
  bounds.style.left = b.left + 'px';
  bounds.style.top = b.top + 'px';
  bounds.style.width = (b.right-b.left) + 'px';
  bounds.style.height = (b.bottom-b.top) + 'px';

  let r = _movable.getRect();
  console.log('bounds', b,r);
  if (r.left >= b.left && r.top >= b.top && r.left+r.width <= b.right && r.top+r.height <= b.bottom) {
    // It fits!
    //

    bounds.style.display = 'none';
    document.getElementById('checkout').style.display = '';
    document.getElementById('position').style.display = 'none';
  } else {
    bounds.style.display = '';
    document.getElementById('checkout').style.display = 'none';
    document.getElementById('position').style.display = '';
  }

  // Add margins within bounding box to hidden input fields
  document.getElementById('margin-left').value = Math.round(r.left - b.left);
  document.getElementById('margin-top').value = Math.round(r.top - b.top);
  document.getElementById('margin-right').value = Math.round(b.right - (r.left+r.width));
  document.getElementById('margin-bottom').value = Math.round(b.bottom - (r.top+r.height));

}

function createResizer(pts) {
  // calculate pixelwidth of selfrender:
  let selfRender = document.getElementById('_selfRender');
  console.log(selfRender.width, selfRender.offsetWidth);
  document.getElementById('_selfRender').style.display = 'none';
  const x1 = Math.min(...(pts.map((p) => p.x)));
  const y1 = Math.min(...(pts.map((p) => p.y)));
  const x2 = Math.max(...(pts.map((p) => p.x)));
  const y2 = Math.max(...(pts.map((p) => p.y)));

  let pts2 = pts.map(p => { return { x: p.x-x1, y: p.y-y1 }});



  const elm = document.getElementById('wear-border');
  elm.style.display = '';
  let canvas = document.createElement('canvas');
  canvas.addEventListener('mousedown', function(ev) {

    // SFG: cursor draggable
    elm.classList.add('dragging');

    console.log('cv.mousdown');
    if (_movable) {
      let elm =   document.getElementsByClassName('moveable-control-box')[0];
      elm.style.display = '';
    }
  });
  canvas.style.width = x2-x1;
  canvas.style.height = y2-y1;
  canvas.width = x2-x1;
  canvas.height = y2-y1;
  elm.style.width = (x2-x1) + 'px';
  elm.style.height = (y2-y1) + 'px';
  console.log('off', selfRender.offsetLeft, x1);
  elm.style.left = (parseInt(selfRender.style.marginLeft) + x1) + 'px';
  elm.style.top = (parseInt(selfRender.style.marginTop) + y1) + 'px';
  Mask.myMask.render(canvas.getContext('2d'), pts2);
  elm.appendChild(canvas);



  let doc = new Moveable(document.body, {
    target: elm,
    container: elm.parentNode,
    scalable: true,
    draggable: true,
    origin: false,
    keepRatio: true,
    pinchable: true,
  });
  console.log(doc);
  _movable = doc;
  updateBoundsBox();
  /* scalable */
  doc.on("scaleStart", ({ target, clientX, clientY }) => {
    console.log("onScaleStart", target);
  }).on("scale", ({ target, scale, dist, delta, transform, clientX, clientY, }) => {
    console.log("onScale scale", scale);
    target.style.transform = transform;
    updateBoundsBox();
  }).on("scaleEnd", ({ target, isDrag, clientX, clientY }) => {
    console.log("onScaleEnd", target, isDrag);
  });

  doc.on("dragStart", ({ target, clientX, clientY }) => {
    console.log("onDragStart", target);
  }).on("drag", ({ target, transform, left, top, right, bottom, beforeDelta, beforeDist, delta, dist, clientX, clientY }) => {
    console.log("onDrag left, top", left, top);
    target.style.left = `${left}px`;
    target.style.top = `${top}px`;
    updateBoundsBox();
    // console.log("onDrag translate", dist);
    // target!.style.transform = transform;
  }).on("dragEnd", ({ target, isDrag, clientX, clientY }) => {
    console.log("onDragEnd", target, isDrag);

    // SFG: cursor draggable
    elm.classList.remove('dragging');

  });
}

function setupResizer() {
  const resizeLib = document.createElement('script')
  resizeLib.src = '//daybrush.com/moveable/release/latest/dist/moveable.min.js';
  resizeLib.onload = () => {
  };
  document.head.append(resizeLib);
}


async function room_leave(room) {
}





export default { room_enter, room_leave }
