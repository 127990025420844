// SFG: this file is obsolete?!

import { Mask } from "../components/Mask";
import { Media } from "../components/Media";

// const help_text = `
//   Chat me Up ...
//   <br /><br />
// `;


const chat_container = `
  <div class='chat-inner'>

    <ul id='chat'></ul>

    <form id="chat-form" class='chat-form'>
      <input type='text' class='chat-message' autofocus>
    </form>
  </div>

`;


async function handle_chat(user, msg) {
  addChatLine(user.mask, msg.message);
}

function addChatLine(avatar, text) {
    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 900;
    canvas.style.width = "60px";
    canvas.style.height = "40px";
    let li = document.createElement('li');
    if (avatar) avatar.render(canvas.getContext('2d'), Mask.SAMPLE_POINTS);
    li.appendChild(canvas);
    let p = document.createElement('p');
    p.innerText = text;
    li.appendChild(p);
    document.getElementById('chat').appendChild(li);
    li.scrollIntoView();
}



async function room_enter(room) {

  // room.set_help_text(help_text);
  // room.show_help();

  // SFG: hide mute button and set mute (always muted)
  document.getElementById('js-mute').style.display = 'none';
  Media.LocalAudioTrack.enabled = false;

  // create chat-container & append to main
  let chat = document.createElement('div');
  chat.className = 'chat-container';
  chat.innerHTML = chat_container;
  document.querySelector('main').appendChild(chat);
  room.div_chat = chat

  // set autofocus on input
  document.querySelector('.chat-message').focus();

  // chat form
  var sendMessage = function(evt) {
    evt.preventDefault();
    let chat_message = document.querySelector('.chat-message');
    let text = chat_message.value;
    addChatLine(Mask.myMask, text);
    room.send({command: 'chat', message: text});
    document.querySelector('.chat-message').value = '';

  };

  var form = document.getElementById("chat-form");
  form.addEventListener("submit", sendMessage, true);

}

async function room_leave(room) {

  document.querySelector('main').removeChild(room.div_chat);

  // SFG: show mute button & enable mic
  document.getElementById('js-mute').style.display = '';
  Media.LocalAudioTrack.enabled = true;


}


export default { room_enter, room_leave, handle_chat }
