import "./components/GlobalControls";



import { Room } from "./components/Room";

import "../scss/main.scss";



Room.enter('_init');
