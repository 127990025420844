
import { Mask } from "../components/Mask";
import { Media } from "../components/Media";
import { generateMask } from "../components/GenerateMask";
import MyAvatar from "../components/MyAvatar";


// SFG: doing logo animation status indication
let logoStatus = document.querySelector('.js-room-status-logo')
let tooltipStatus = document.querySelector('.js-room-status-tooltip')

let _room;

async function room_enter(room) {
  _room = room;
  if (room.is_init) return;


  if (room.room_def.programming === 'scheduled') {
    set_live(false);

  }
}

function set_live(live) {

  console.log('setting live');

  _room.is_live = live;

  //enable disable audio
  for(let u of _room.users) {
    if (u.track) {
      u.track.enabled = live;
    }
  }

  if (live) {
    _room.hide_help();
  } else {
    _room.show_help();
  }

  // enable 'show/hide' room info
  document.querySelectorAll('#room-info .js-modal-close').forEach(el => {
    el.classList.toggle('hide', !live);
  })

  // Room Live, remove Awaiting Presenter(s)
  logoStatus.classList.toggle('waiting', !live);

  if (live) {
    tooltipStatus.innerHTML = 'Room is Live';
  } else {
    tooltipStatus.innerHTML = 'Awaiting Presenter(s)';
  }
}

async function handle_live() {

  set_live(true);
}

async function user_remove() {
  // No more presenters? No longer live
  if (_room.room_def.programming === 'scheduled')
  {
    if (!_room.users.find(u => !u.is_viewer)) {
      set_live(false);
    }
  }
}


export default { room_enter, handle_live, user_remove }
