
import { Mask } from "../components/Mask";

let _room = null;
let _boxElm = null;

async function room_enter(room) {

  _room = room;

  _boxElm = document.createElement('ol');
  _boxElm.className = ('avatarBox');
  _boxElm.innerHTML = 'HIGH SCORE';


  document.querySelector('main').appendChild(_boxElm);

  room.me = room.me || {};
  room.me.miniAvatarElm = createRow();
  room.me.setAvatarText = function(t) {
    room.me.miniAvatarElm.children[1].innerText = t;
  }

  Mask.myMask.render(room.me.miniAvatarElm.children[0].getContext('2d'), Mask.SAMPLE_POINTS);
}

function createRow() {
  const canvas = document.createElement('canvas');
  canvas.width = 1200;
  canvas.height = 900;
  canvas.style.width = "80px";
  canvas.style.height = "60px";
  let li = document.createElement('li');
  li.appendChild(canvas);
  let p = document.createElement('p');

  // SFG: temp create dummy content for css styling
  // p.innerHTML = '12:34';

  li.appendChild(p);
  _boxElm.appendChild(li);
  return li;
}

async function room_leave(room) {
  document.querySelector('main').removeChild(_boxElm);
  room.me.miniAvatarELm = null;
  room.me.setAvatarText = null;
}

async function user_add(user) {
  user.miniAvatarElm = createRow();
  user.setAvatarText = function(t) {
    user.miniAvatarElm.children[1].innerText = t;
  }
}

async function user_remove(user) {
  _boxElm.removeChild(user.miniAvatarElm);
  user.miniAvatarElm = null;
}

async function handle_userMetadata(user, msg) {
  if (!user.miniAvatarElm) {
    return;
  }
  let mask = new Mask();
  mask.deserialize(msg.metadata);
  user.avatar_pts = msg.pts;
  mask.render(user.miniAvatarElm.children[0].getContext('2d'), msg.pts.pts);

}

export default { room_enter, room_leave, user_add, user_remove, handle_userMetadata };
