// fip player

let _room = null;
let fip_controls = document.getElementById('fip-player');
let fip_active = false;

const fip_player = `
  <audio id='lobbyFip' style='display:none;' src="https://icecast.radiofrance.fr/fip-midfi.mp3">
    Your browser does not support the audio element.
  </audio>
`;

async function update_room_settings(room_def) {

  room_def = room_def || _room.room_def;
  console.log('FIP setting', room_def.room_fip, fip_active);
  if (room_def.room_fip === 1 && !fip_active) {
    load_fip();
    fip_active = true;
  }
  else if (room_def.room_fip !== 1 && fip_active) {
    unload_fip();
    fip_active = false;
  }


}



async function load_fip() {
   console.log('LOADING FIP');
   fip_controls.classList.remove('hide');

   let fip = document.createElement('div');
   fip.className = 'fip-container';
   document.querySelector('main').appendChild(fip);
   fip.innerHTML = fip_player;
   _room.div_fip = fip

   // set fip audio
   let audio = document.getElementById("lobbyFip");
   audio.volume = 0.1;
   audio.controls = false;
   audio.play();
   console.log('fip playing');

   fip_controls.addEventListener("click", function(){
     if(this.classList.contains('muted')) {
       this.classList.remove('muted');
       lobbyFip.play();
       console.log('fip playing');
     } else {
       this.classList.add('muted');
       lobbyFip.pause();
       console.log('fip paused');
     }
   })
}


async function unload_fip() {
  console.log('UNLOADING FIP');
  document.querySelector('main').removeChild(_room.div_fip);
  fip_controls.classList.add('hide');
  delete _room.div_fip;
}


async function room_enter(room) {
  _room = room;
  update_room_settings();


}

async function room_leave(room) {

  // document.querySelector('main').removeChild(room.div_fip);

}


export default { room_enter, room_leave, update_room_settings }
