import { DefaultRegionColoring, Regions } from "../regions";
import { generateMask } from "./GenerateMask";
// /import { HandleColors, startGlitterColors, stopGlitterColors } from "../mods/maskeditor/ColorSelector";


// TODO refactor to room.me
import {
  getSelectedRegionIndexes,
  getHideSelection,
  currentType,
  REGION_IDX_NONE,
} from "../mods/MaskEditor";

let frameCounter = 0;

// chaotic points used for fade in/out
// generated random but resticted to radius .25
const chaosPoints = [
  { x: 0.506, y: 0.324 }, { x: 0.372, y: 0.496 }, { x: 0.53, y: 0.298 }, { x: 0.67, y: 0.441 }, { x: 0.277, y: 0.57 }, { x: 0.744, y: 0.512 }, { x: 0.644, y: 0.396 }, { x: 0.505, y: 0.636 },
  { x: 0.373, y: 0.377 }, { x: 0.728, y: 0.495 }, { x: 0.595, y: 0.359 }, { x: 0.564, y: 0.695 }, { x: 0.63, y: 0.322 }, { x: 0.544, y: 0.608 }, { x: 0.341, y: 0.345 }, { x: 0.603, y: 0.349 },
  { x: 0.691, y: 0.453 }, { x: 0.73, y: 0.529 }, { x: 0.555, y: 0.436 }, { x: 0.293, y: 0.502 }, { x: 0.46, y: 0.315 }, { x: 0.279, y: 0.471 }, { x: 0.692, y: 0.406 }, { x: 0.309, y: 0.362 },
  { x: 0.451, y: 0.743 }, { x: 0.381, y: 0.348 }, { x: 0.519, y: 0.461 }, { x: 0.45, y: 0.642 }, { x: 0.438, y: 0.564 }, { x: 0.545, y: 0.742 }, { x: 0.412, y: 0.308 }, { x: 0.287, y: 0.604 },
  { x: 0.507, y: 0.402 }, { x: 0.445, y: 0.455 }, { x: 0.555, y: 0.328 }, { x: 0.694, y: 0.591 }, { x: 0.486, y: 0.292 }, { x: 0.291, y: 0.538 }, { x: 0.612, y: 0.373 }, { x: 0.481, y: 0.661 },
  { x: 0.471, y: 0.725 }, { x: 0.579, y: 0.479 }, { x: 0.544, y: 0.437 }, { x: 0.428, y: 0.7 }, { x: 0.617, y: 0.629 }, { x: 0.363, y: 0.432 }, { x: 0.734, y: 0.562 }, { x: 0.508, y: 0.624 },
  { x: 0.377, y: 0.505 }, { x: 0.546, y: 0.583 }, { x: 0.584, y: 0.501 }, { x: 0.699, y: 0.414 }, { x: 0.642, y: 0.434 }, { x: 0.26, y: 0.473 }, { x: 0.492, y: 0.41 }, { x: 0.504, y: 0.503 },
  { x: 0.393, y: 0.576 }, { x: 0.603, y: 0.407 }, { x: 0.287, y: 0.6 }, { x: 0.582, y: 0.436 }, { x: 0.652, y: 0.544 }, { x: 0.747, y: 0.488 }, { x: 0.419, y: 0.384 }, { x: 0.54, y: 0.362 },
  { x: 0.658, y: 0.31 }, { x: 0.454, y: 0.39 }, { x: 0.683, y: 0.445 }, { x: 0.369, y: 0.473 },
];


// Points of a sample face
const _samplePoints = [
  {x:736,y:320},{x:728,y:378},{x:719,y:431},{x:709,y:486},{x:696,y:543},{x:669,y:592},{x:633,y:632},{x:582,y:664},{x:517,y:676},{x:444,y:668},{x:390,y:637},{x:343,y:595},{x:314,y:544},{x:300,y:486},{x:289,y:423},{x:280,y:371},{x:270,y:312},{x:722,y:256},{x:703,y:213},{x:653,y:200},{x:598,y:206},{x:549,y:223},{x:485,y:221},{x:432,y:206},{x:377,y:199},{x:327,y:211},{x:301,y:256},{x:514,y:262},{x:514,y:297},{x:515,y:336},{x:520,y:375},{x:568,y:422},{x:542,y:426},{x:512,y:431},{x:483,y:427},{x:459,y:420},{x:675,y:294},{x:655,y:282},{x:622,y:281},{x:583,y:290},{x:618,y:300},{x:651,y:305},{x:441,y:288},{x:409,y:275},{x:379,y:277},{x:352,y:289},{x:379,y:297},{x:412,y:293},{x:604,y:519},{x:570,y:501},{x:539,y:493},{x:513,y:502},{x:483,y:496},{x:448,y:506},{x:418,y:524},{x:451,y:537},{x:483,y:537},{x:511,y:537},{x:542,y:533},{x:572,y:533},{x:591,y:517},{x:540,y:511},{x:512,y:514},{x:483,y:512},{x:431,y:519},{x:481,y:514},{x:512,y:515},{x:540,y:511}
  ];


// Local storage versioning
if (window.localStorage.getItem("version") !== "4") {
  console.log('Updating local storage version');
  window.localStorage.clear();
  window.localStorage.setItem("version", "4");
}


class Mask {
  constructor() {
    // Copy default
    this.regionColoring = JSON.parse(JSON.stringify(DefaultRegionColoring));
    this.selectedIndex = 1;
  }

  current() {
    return this.regionColoring[this.selectedIndex];
  }

  maskCount() {
    return this.regionColoring.length;
  }

  generateRandom() {
    this.regionColoring[this.selectedIndex] = generateMask();
  }

  generateNewRandomMask(){
    /*this.regionColoring[ this.regionColoring.length ] = generateMask();
    this.setSelectedIndex(this.regionColoring.length-1);*/
    if(this.regionColoring.length < 25) {
      this.regionColoring.unshift(generateMask());
      this.setSelectedIndex(0);
      this.current();
    } else {
      alert("Maximum of 25 masks reached");
    }
  }

  static SAMPLE_POINTS = _samplePoints;

  static loadFromStorage() {
    let mask = new Mask();

    let randomMask = generateMask();

    DefaultRegionColoring.unshift(randomMask)

    if (window.localStorage.getItem("wealgoRegions") === null) {

      window.localStorage.setItem( "wealgoRegions", JSON.stringify(DefaultRegionColoring));
      window.localStorage.setItem( "currentRegionIndex", "0");
    }
    mask.regionColoring = JSON.parse(
      window.localStorage.getItem("wealgoRegions")
    );
    mask.selectedIndex = JSON.parse(
      window.localStorage.getItem("currentRegionIndex")
    );
    return mask;
  }

  static myMask = Mask.loadFromStorage();



  saveToLocalStorage() {
      window.localStorage.setItem( "wealgoRegions", JSON.stringify(this.regionColoring));
      window.localStorage.setItem( "currentRegionIndex", JSON.stringify(this.selectedIndex));
  }

  setSelectedIndex(idx) {
     this.selectedIndex = idx;
     this.saveToLocalStorage();
  }

  deleteMask(idx) {
    if(this.regionColoring.length > 1) {
      this.regionColoring.splice(idx, 1);
      if(idx === this.regionColoring.length) {
        this.setSelectedIndex(this.regionColoring.length -1);
      }
    }else{
      alert("You need to have at least 1 mask")
    }
  }

  getSelectedIndex() {
      return this.selectedIndex
  }

  // Special render function which shows fade-in/out state instead of actual mask
  render_tweened(ctx, pts, tween) {
    const _radius = 2;
    let w = ctx.canvas.clientWidth;
    let h = ctx.canvas.clientHeight;

    for (var n = 0; n < 68; n++) {
      let chaos = {
        x: chaosPoints[n].x * w,
        y: chaosPoints[n].y * h,
      };
      let x = chaos.x + (pts[n].x - chaos.x) * tween;
      let y = chaos.y + (pts[n].y - chaos.y) * tween;
      ctx.beginPath();
      ctx.arc(x, y, _radius, 0, 2 * Math.PI);
      ctx.fillStyle = "#ff0000";
      ctx.fill();
    }
  }

  // Special render function shows fade-in/out to a drop instead of mask
  render_dropped(ctx, pts, pr,dir) {
    // determine bounds of face
    let x2 = Math.max(...(pts.map((p) => p.x)));
    let x1 = Math.min(...(pts.map((p) => p.x)));
    let y2 = Math.max(...(pts.map((p) => p.y)));
    let y1 = Math.min(...(pts.map((p) => p.y)));

    // turn `x1,y1 - x2,y2` into a line from nose `pts[30]` to bound in
    // the direction of door `dir`
    // This start of this line `pts[40`] moves to the bound at progress `pr`
    if (dir === 1) {
      y1 = pts[30].y;
      y2 = pts[30].y;
      x1 = pts[30].x + (x2 - pts[30].x) * pr;
    } else if (dir === 2) {
      x1 = pts[30].x;
      x2 = pts[30].x;
      y2 = pts[30].y + (y1 - pts[30].y) * pr;
    } else if (dir === 3) {
      y1 = pts[30].y;
      y2 = pts[30].y;
      x2 = pts[30].x + (x1 - pts[30].x) * pr;
    } else if (dir === 4) {
      x1 = pts[30].x;
      x2 = pts[30].x;
      y2 = pts[30].y + (y2 - pts[30].y) * pr;
    }
    let pts2 = pts.map((p) => {
      // find the nearest point on line (x1,y1-x2,y2) to this point `p`
      let tx= Math.max(Math.min(p.x,x2),x1);
      let ty= Math.max(Math.min(p.y,y2),y1);

      // find tween towards this point with progress `pr`
      return {
        x: tx + (p.x - tx) * pr,
        y: ty + (p.y - ty) * pr
      }
    });
    // normal render function using transformed points
    this.render(ctx, pts2);
  }


  // Main mask render function
  //
  // Renders `this` mask to canvas context `ctx` using detected points `pts`
  //
  // tween: Use in-progress fade variant instead of normal render
  // index: Use specific mask from mask-set instead of current
  // blur: Add I-am-speaking blur


  // Glitter functions
  addLight (color, amount){
    let cc = parseInt(color,16) + amount;
    let c = (cc > 255) ? 255 : (cc);
    c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
    return c;
  }
  lighten (color, amount) {
    color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
    amount = parseInt((255*amount)/100);
    return color = `#${this.addLight(color.substring(0,2), amount)}${this.addLight(color.substring(2,4), amount)}${this.addLight(color.substring(4,6), amount)}`;
  }
  subtractLight (color, amount){
    let cc = parseInt(color,16) - amount;
    let c = (cc < 0) ? 0 : (cc);
    c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
    return c;
  }
  darken (color, amount) {
    color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
    amount = parseInt((255*amount)/100);
    return color = `#${this.subtractLight(color.substring(0,2), amount)}${this.subtractLight(color.substring(2,4), amount)}${this.subtractLight(color.substring(4,6), amount)}`;
  }

  getRndInteger (min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  drawGlitter(ctx, xMin,xMax, yMin, yMax, hexColor="#ff0000", glitterRadius=12) {
    const glitterDensity = (40 * glitterRadius) + Math.sqrt(glitterRadius);
    const maxAmount = Math.round(((xMax - xMin) * (yMax - yMin)) / glitterDensity);
    const colorShades = [
      this.lighten(hexColor, 15),
      this.lighten(hexColor, 30),
      this.darken(hexColor, 15),
      this.darken(hexColor, 30),
    ];

    for (let i = 0; i < maxAmount; i++) {
      ctx.fillStyle = colorShades[this.getRndInteger(0, colorShades.length)];
      ctx.beginPath();
      ctx.arc(this.getRndInteger(xMin, xMax), this.getRndInteger(yMin, yMax), glitterRadius, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
    }
  }

  render(ctx, pts, tween = 0, index = -1, blur = false) {
    //const start = Date.now();

    if (index === -1) {
      index = this.selectedIndex;
    }

    function pathFromArray(ctx, t) {
      ctx.moveTo(pts[t[0]].x, pts[t[0]].y);
      for (let n = 1; n < t.length; n++) {
        ctx.lineTo(pts[t[n]].x, pts[t[n]].y);
      }
      ctx.lineTo(pts[t[0]].x, pts[t[0]].y);
    }

    if (tween) {
      let pr = 1-tween.progress;
      if (tween.type === 'chaos')
      {
        this.render_tweened(ctx, pts, pr);
        return;
      } else if (tween.type === 'drop') {
        this.render_dropped(ctx, pts, pr,tween.dir);
        return;
      }
    }

    let maxRegions = this.regionColoring[index].length;

    ctx.setLineDash([]);
    for (let i = 1; i < maxRegions; i++) {
      let regionColoring = this.regionColoring[index][i];

      // blurring for talking heads?
      // works well, yet only for filled masks ... stroke dont do much
      // const toggleBlur = document.querySelector('.js-do_blur');
      if (blur) {
         ctx.shadowColor = regionColoring.fill;
         ctx.shadowBlur = 100;
       }

      ctx.beginPath();
      // Create paths of triangles
      Regions[regionColoring.region].triang.forEach((t) => {
            pathFromArray(ctx, t)
          }
      );

      // Fill
      if (regionColoring.fill !== 'transparent') {
        ctx.fillStyle = regionColoring.fill;
        ctx.fill();

        if (regionColoring.glitterFill) {
          ctx.save();
          ctx.beginPath();
          pathFromArray(ctx, Regions[regionColoring.region].perim);
          ctx.clip();
          this.drawGlitter(
              ctx,
              Math.min(...Regions[regionColoring.region].dots.map(t => pts[t].x)),
              Math.max(...Regions[regionColoring.region].dots.map(t => pts[t].x)),
              Math.min(...Regions[regionColoring.region].dots.map(t => pts[t].y)),
              Math.max(...Regions[regionColoring.region].dots.map(t => pts[t].y)),
              regionColoring.fill
          );
          ctx.restore();
        }
      }

      // stroke
      ctx.beginPath();
      ctx.strokeStyle = regionColoring.stroke;
      Regions[regionColoring.region].triang.forEach((t) => {
            pathFromArray(ctx, t)
          }
      );
      if (regionColoring.stroke !== 'transparent') {
        ctx.stroke();
      }

      // set blur & offset back to zero or blur on blur gives negative performance
      ctx.shadowBlur = 0;

      // Dots only at perimeter
      const _radius = 2;
      Regions[regionColoring.region].dots.forEach((t) => {
        if (regionColoring.dots !== 'transparent') {
          ctx.beginPath();
          ctx.arc(pts[t].x, pts[t].y, _radius, 0, 2 * Math.PI);
          ctx.fillStyle = regionColoring.dots;
          ctx.fill();
        }
      });
    }

    // Draw selection ants
    getSelectedRegionIndexes().forEach((rIndex) => {
      if (rIndex !== REGION_IDX_NONE && !getHideSelection()) {

        ctx.beginPath();
        ctx.strokeStyle = "#000000";
        if (currentType === "fill") {
          pathFromArray(ctx, Regions[rIndex].perim);

        } else {
          Regions[rIndex].triang.forEach((t) => pathFromArray(ctx, t));
        }

        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.strokeStyle = "#FFFFFF";

        // Marching ants in 10 frames
        ctx.setLineDash([5, 5]);
        ctx.lineDashOffset = frameCounter % 10;
        frameCounter += 1;

        if (currentType === "fill") {
          pathFromArray(ctx, Regions[rIndex].perim);
        } else {
          Regions[rIndex].triang.forEach((t) => pathFromArray(ctx, t));
        }
        ctx.stroke();
        ctx.closePath();
      }
    });
  }

  serialize() {
    return this.current().map((r) => {
      return [r.region, r.fill, r.stroke, r.dots, r.glitterFill];
    });
  }

  deserialize(obj) {
    for (var n in this.current()) {
      // destructured assign
      [
        this.current()[n].region,
        this.current()[n].fill,
        this.current()[n].stroke,
        this.current()[n].dots,
        this.current()[n].glitterFill
      ] = obj[n];
    }
  }
}



export { Mask };
