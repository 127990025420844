// RoomSettings ... allows user to configurate her room

let _room = null;
let fip_controls = document.getElementById('fip-player');

async function handle_reloadRoomSettings() {
  // Get room info after post ...
  const getUrl = process.env.ROOMS_DB_SERVER + 'rooms/' + _room.room_def.name; // remote
  // console.log(getUrl);
  fetch( getUrl )
  .then( response => response.json() )
  .then( response => {
    console.log(response);
    _room.room_def = response;
    _room.fire_event('update_room_settings');
  });
}

async function update_room_settings(room_def) {
    const r = room_def || _room.room_def;
    setRoomSettings(r);
}

function setRoomSettings(room_def) {

  // Load Room settings from Rooms_DB
  let room_bg_color = room_def.room_bg_color;
  if(room_bg_color.length > 0) {
    document.documentElement.style.setProperty('--bg-color', room_bg_color);
    document.documentElement.style.setProperty('--bg-color-alpha', 'rgba(' + hexToRgb(room_bg_color) + ', .86)');

    // check value of each color; when > 150 invert theme (black), else text-color = white
    let checkHex = hexToRgb(room_bg_color).match(/\d+/g);
    const isAboveThreshold = (currentValue) => currentValue > 150;
    if(checkHex.every(isAboveThreshold)) {
      document.documentElement.style.setProperty('--txt-color', 'rgba(0,0,0,.82)');
      document.documentElement.style.setProperty('--txt-color-inv', 'rgba(255,255,255,.82)');
      document.documentElement.style.setProperty('--bg-color-inv', 'rgba(0,0,0,.82)');
      document.documentElement.style.setProperty('--border-color', 'rgba(0,0,0,.1)');
      document.documentElement.style.setProperty('--filter', 'invert(0)');
    } else {
      document.documentElement.style.setProperty('--txt-color', 'rgba(255,255,255,.82)');
      document.documentElement.style.setProperty('--txt-color-inv', 'rgba(0,0,0,.82)');
      document.documentElement.style.setProperty('--bg-color-inv', 'rgba(255,255,255,.82)');
      document.documentElement.style.setProperty('--border-color', 'rgba(255,255,255,.1)');
      document.documentElement.style.setProperty('--filter', 'invert(1)');
    }


    // set colorpicker color value to room_bg_color
    document.getElementById('bg_color').value = room_bg_color;
  }

  // set Background Image
  let room_bg_image = room_def.room_bg_image;
  if(room_bg_image) {
    document.getElementById('_room').style.backgroundImage = 'url(' + room_bg_image + ')';
    document.getElementById('remove_bg_image').style.display = '';
  }
  else {
    document.getElementById('remove_bg_image').style.display = 'none';
    document.getElementById('_room').style.backgroundImage = '';
  }

  // check halo checkbox if room_halo = 1
  document.getElementById('halo').checked = (room_def.room_halo == 1);

  // check fip checkbox if room_fip = 1
  document.getElementById('fip').checked = (room_def.room_fip == 1);

}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(result){
      var r= parseInt(result[1], 16);
      var g= parseInt(result[2], 16);
      var b= parseInt(result[3], 16);
      return r+","+g+","+b;//return 23,14,45 -> reformat if needed
  }
  return null;
}


async function room_enter(room) {

  _room = room;

  setRoomSettings(_room.room_def);

  // update data directly on input change
  const bg_color = document.getElementById('bg_color');
  bg_color.addEventListener("input", function() {
    // create a temporary room_def for showing unsaved changes
    let temp_room_def = Object.assign({}, _room.room_def);
    temp_room_def.room_bg_color = bg_color.value;
    setRoomSettings(temp_room_def);
  });
  const bg_image = document.getElementById('bg_image');
  bg_image.addEventListener("change", function() {
    let file = bg_image.files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
      // create a temporary room_def for showing unsaved changes
      let temp_room_def = Object.assign({}, _room.room_def);
      temp_room_def.room_bg_image = e.target.result;
      setRoomSettings(temp_room_def);
    }
    reader.readAsDataURL(file); // convert to base64 string
  });

  const remove_bg_image = document.getElementById('remove_bg_image');
  remove_bg_image.addEventListener('click', function(e) {
    e.preventDefault();
    let temp_room_def = Object.assign({}, _room.room_def);
    temp_room_def.room_bg_image = '';
    setRoomSettings(temp_room_def);
    bg_image.value = '';
  });

  const fip_check = document.getElementById('fip');
  fip_check.addEventListener('click', function(e) {
    let temp_room_def = Object.assign({}, _room.room_def);
    temp_room_def.room_fip = fip_check.checked ? 1 : 0;

    _room.fire_event('update_room_settings',  temp_room_def);


  });


  const form = document.getElementById('room-settings-form');
  form.room_name.value = _room.name;
  const ok_button = document.getElementById('submit-settings');
  const cancel_button = document.getElementById('cancel-settings');

  cancel_button.addEventListener('click', function(e) {
    _room.fire_event('update_room_settings');
  });

  // Process Settings Form
  // console.log('initializing form', form);
  ok_button.addEventListener('click', function(e) {
    e.preventDefault();


    // Tommie to update ROOMS_DB_SERVER to point to single url ...
    // for now, use local & remove url manually
    const url = process.env.ROOMS_DB_SERVER + 'update/';

    let data = new FormData(form);
    let respons = fetch(url, {
      method: 'POST',
      body: data,
    }).then(handle_reloadRoomSettings)
    .then(function() {

      _room.send({command: 'reloadRoomSettings'});
    });
  });

}

async function room_leave(room) {


}


export default { room_enter, room_leave, handle_reloadRoomSettings, update_room_settings }
