


/*
 *
 *
 */



// Points of a sample face
const _samplePoints = [
  {x:736,y:320},{x:728,y:378},{x:719,y:431},{x:709,y:486},{x:696,y:543},{x:669,y:592},{x:633,y:632},{x:582,y:664},{x:517,y:676},{x:444,y:668},{x:390,y:637},{x:343,y:595},{x:314,y:544},{x:300,y:486},{x:289,y:423},{x:280,y:371},{x:270,y:312},{x:722,y:256},{x:703,y:213},{x:653,y:200},{x:598,y:206},{x:549,y:223},{x:485,y:221},{x:432,y:206},{x:377,y:199},{x:327,y:211},{x:301,y:256},{x:514,y:262},{x:514,y:297},{x:515,y:336},{x:520,y:375},{x:568,y:422},{x:542,y:426},{x:512,y:431},{x:483,y:427},{x:459,y:420},{x:675,y:294},{x:655,y:282},{x:622,y:281},{x:583,y:290},{x:618,y:300},{x:651,y:305},{x:441,y:288},{x:409,y:275},{x:379,y:277},{x:352,y:289},{x:379,y:297},{x:412,y:293},{x:604,y:519},{x:570,y:501},{x:539,y:493},{x:513,y:502},{x:483,y:496},{x:448,y:506},{x:418,y:524},{x:451,y:537},{x:483,y:537},{x:511,y:537},{x:542,y:533},{x:572,y:533},{x:591,y:517},{x:540,y:511},{x:512,y:514},{x:483,y:512},{x:431,y:519},{x:481,y:514},{x:512,y:515},{x:540,y:511}
  ];





async function create() {

  let bot = {};
  let rot = 0;

  let t=0;

  bot.next_points = async function() {
    t = t + 0.05;

    let p = rotate(_samplePoints, Math.sin(t) * 0.8, Math.sin(2*t)*0.4);

    return p;


  }
  return bot;

}


function rotate(pts,rotx,roty) {

  // to rotate we project each point on a circle with a diamater `rad_marg` bigger
  // then the head
  // The cirlce differs per point; ie the tip of the nose is rotated over a bigger circle

  // These are (x,y) rad_margs for all 68 points
  const rad_margins = [
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.23,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],[0.2,0.3],
    [0.2,0.3],[0.2,0.3],[0.2,0.3]];

  roty = roty || rotx;

  return pts.map((p,idx) => {


    const rad_marg_x = rad_margins[idx][0];
    const x1 = Math.min(...(pts.map((p) => p.x))) * (1-rad_marg_x);
    const x2 = Math.max(...(pts.map((p) => p.x))) * (1+rad_marg_x);
    const xs = (x2 - x1)/2;
    const xt = (x2 + x1)/2;
    let rx = (p.x - xt) / xs;
    let rtx = Math.cos(Math.acos(rx)+rotx);

    const rad_marg_y = rad_margins[idx][1];
    const y1 = Math.min(...(pts.map((p) => p.y))) * (1-rad_marg_y);
    const y2 = Math.max(...(pts.map((p) => p.y))) * (1+rad_marg_y);
    const ys = (y2 - y1)/2;
    const yt = (y2 + y1)/2;
    let ry = (p.y - yt) / ys;
    let rty = Math.cos(Math.acos(ry)+roty);
    return {
      x: rtx * xs + xt,
      y: rty * ys + yt
    };

  })
}



export default { create };




