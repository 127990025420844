// Allows creation of Spotify embed player

let spotify = `<iframe src="https://open.spotify.com/embed/playlist/5iP6FqkUC37hbaSX75g2tp?theme=0" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;

async function room_enter(room) {

  _room = room;

  // create spotify embed
  let _spotify = document.createElement('div');
  _spotify.className = 'spotify';
  _spotify.innerHTML = spotify;
  document.querySelector('main').appendChild(_spotify);
  room.div_spotify = _spotify;


}


async function room_leave(room) {
  _room = null
  document.querySelector('main').removeChild(room.div_spotify);
}


export default { room_enter, room_leave }
