
import { Media } from "../components/Media";

// let help_text = `
//           Just a little fun
//           <br /><br />
//           Speak to light up
// `;


let detectionNode;
let localNode;

function createVolumeDetector(me) {

  // setup a analyzer
  let analyser = Media.AudioCtx.createAnalyser();
  analyser.smoothingTimeConstant = 0.3;
  analyser.fftSize = 1024;

  detectionNode = Media.AudioCtx.createScriptProcessor(512, 1, 1);

  me.volume = 0;

  detectionNode.onaudioprocess = function(audioProcessingEvent) {
    // The input buffer is the song we loaded earlier
    var inputBuffer = audioProcessingEvent.inputBuffer;

    // The output buffer contains the samples that will be modified and played
    var outputBuffer = audioProcessingEvent.outputBuffer;

    // Loop through the output channels (in this case there is only one)
    var inputData = inputBuffer.getChannelData(0);
    var outputData = outputBuffer.getChannelData(0);

    // Loop through the 4096 samples
    let sum = 0;
    for (var sample = 0; sample < inputBuffer.length; sample++) {
      // make output equal to the same as the input
      outputData[sample] = inputData[sample];

      let x = inputData[sample];
    	sum += x * x;
      // add noise to each output sample
      //outputData[sample] += ((Math.random() * 2) - 1) * 0.2;
    }
    var rms =  Math.sqrt(sum / inputBuffer.length);

    me.volume = Math.max(rms, me.volume*0.9);
  }

    // get the average, bincount is fftsize / 2
    //var array =  new Uint8Array(analyser.frequencyBinCount);
    //analyser.getByteFrequencyData(array);
    //var average = getAverageVolume(array)

     //console.log('VOLUME:' + average); //here's the volume

 function getAverageVolume(array) {
      var values = 0;
      var average;

      var length = array.length;

      // get all the frequency amplitudes
      for (var i = 0; i < length; i++) {
          values += array[i];
      }

      average = values / length;
      return average;
  }

  Media.AudioCtx.resume();
  localNode = Media.AudioCtx.createMediaStreamSource(Media.LocalStream);
  localNode.connect(detectionNode);
  detectionNode.connect(Media.AudioDestination);
  //Media.LocalAudioTrack = Media.AudioDestination;
}


let _room;
async function room_enter(room) {

  _room = room;

  if (room.room_def.room_halo) {
    createVolumeDetector(room.me);
  }

}

async function room_leave(room) {
  if (room.room_def.room_halo) {
    detectionNode.disconnect();
    localNode.disconnect()
    detectionNode = null;
    localNode = null;
    room.me.volume = 0;
  }
}

async function update_room_settings() {
  if (_room.room_def.room_halo && !detectionNode) {
    createVolumeDetector(_room.me);
  } else if (!_room.room_def.room_halo && detectionNode) {
    detectionNode.disconnect();
    localNode.disconnect()
    detectionNode = null;
    localNode = null;
    _room.me.volume = 0;
  }
}




export default { room_enter, room_leave, update_room_settings };
