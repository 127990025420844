// start a new Room
var _room = null;
const startRoom = document.getElementById('start-room');
const form = document.getElementById('start-room-form');
let btnContinue = document.querySelector('.btn-start-room-continue')
const ok_button = document.getElementById('submit-start-room');
const btnCancel = document.querySelector('.js-clear-form');

// Handle MultiStep Forms (Start new Room)
let stepIndex = 0;
const steps = document.querySelectorAll('form .tab-content');
const steppers = document.querySelectorAll('[data-stepper]');

// check if room has room_name content, if not prevent 'continue' button
form.room_name.addEventListener("input", function() {
  //btnContinue.classList.remove('invisible');
  this.value.length > 0
      ? btnContinue.classList.remove('hide')
      : btnContinue.classList.add('hide');
});


form.room_privacy.forEach((radio) => {
    radio.addEventListener("change", function() {
        btnContinue.classList.remove('hide');
    })
})

form.room_type.forEach((radio) => {
    radio.addEventListener("change", function() {
        btnContinue.classList.remove('hide');
    })
})


form.room_programming.forEach((radio) => {
    radio.addEventListener("change", function() {
        btnContinue.classList.remove('hide');
    })
})

  // hide Start/Stop input step if room programming is continuous
  function hideStartStop(event) {
    if(event.target.value == '2') { // 2=continuous in db
      document.getElementById('room-start-stop').classList.add('hide');
      btnContinue.classList.add('hide');
      ok_button.classList.remove('hide');
    } else {
      document.getElementById('room-start-stop').classList.remove('hide');
      btnContinue.classList.remove('hide');
      ok_button.classList.add('hide');
    }
  }
  document.querySelectorAll("input[name='room_programming']").forEach((input) => {
    input.addEventListener('change', hideStartStop);
  });

for(const stepper of steppers) {


  stepper.addEventListener('click', () => incrementStep());


}


function incrementStep() {

    for(const step of steps) {
        step.classList.remove('open');
    }


    stepIndex++;

    btnContinue.classList.toggle('hide', stepIndex !== 3);

    if(stepIndex === 5 ) {
        // at last step, we remove continue & show Save buttons
        btnContinue.classList.add('hide');
        ok_button.classList.remove('hide');
    }

    steps[stepIndex].classList.add('open');
}

const clearForm = () => {
    Array.from(form.querySelectorAll('.tab-content')).forEach((el) => el.classList.remove('open'));
    form.firstElementChild.classList.add('open');
    form.reset();
    console.log('form reset');
}

// clear form/reset stepper on cancel
btnCancel.addEventListener("click", function(){
    clearForm();
})


async function room_enter(room) {
  _room = room;

  // Process Start a Room form
  const form = document.getElementById('start-room-form');
  const ok_button = document.getElementById('submit-start-room');
  ok_button.addEventListener('click', function(e) {
    e.preventDefault();

    // revisit, room title & room name may not be the same!
    const newRoom = form.room_name.value;

    // const url = "http://rooms.wealgo.loc/create-ajax/";
    const url = "https://rooms.wealgo.org/create-ajax/";

        let data = new FormData(form);
        let respons = fetch(url, {
          method: 'POST',
          body: data,
        }).then(res => res.json())
          .then(res => {

            // hide form, make room for created room info
            form.classList.add('hide');
            btnCancel.classList.add('hide');
            ok_button.classList.add('hide');

            // console.log("Room created! response:", res);

            // set room created info
            let div = document.createElement('div');
            div.className = 'tab-room-created';
            // div.innerHTML = "this is your public url: " + res.url_public + ".<br> this is your private url: " + res.url_private;

            div.innerHTML = `
                            Excellent; we've prepared your Room.<br>
                            The links below will take you right there. <br><br>

                            Your (Private) link:<br><a class='underline' href="${res.url_private}">${res.url_private}</a><br><br>
                            For invites (Public):<br><a class="underline" href="${res.url_public}">${res.url_public}</a><br><br>

                            <span class="smll">
                             <i>Keep your Private link safe and share only with people you trust.</i><br>
                            </span>
                            `;

            startRoom.querySelector('.modal-body').appendChild(div);
    });;
  });
}


async function room_leave() {
  _room = null;

}
export {
    clearForm
}
export default { room_enter, room_leave }
