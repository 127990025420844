// Help text aka Info modals
// sets Room info details via top right icon
// TODO: revisit to give room_admin edit rights


function is_programmed(room) {
  if(room.room_def.programming === 'scheduled') {
    let start_stop = "<span class='xs'>programming: </span>" + room.room_def.room_start + ' - ' + room.room_def.room_stop + ' CET';
    return start_stop;
  } else {
    let start_stop = '';
    return start_stop;
  }
}

function room_title(room) {
  return room.room_def.title;
}
function room_topic(room) {
  return room.room_def.topic;
}
function room_type(room) {
  return room.room_def.type;
}
function room_privacy(room) {
  return room.room_def.privacy;
}

async function room_enter(room) {

  let div = document.createElement('div');
  div.className = 'modal';
  div.id = 'room-info';


  let help_text = `
    <div class='modal-overlay flex'>

      <div class='modal-dialogue__fs'>

        <div class='modal-header'>
          <button class='js-modal-close modal-close icon close-icon'></button>
          <div class='modal-title'>
            ${room_title(room)}
          </div>
        </div>

        <div class="modal-subheader">
          <nav class="modal-subheader-nav">
            <ul class="tabs">
              <li class="tab overlay active" data-tab-trigger="public">Public Info</li>
              <li class="tab overlay private hide" data-tab-trigger="admin">Admin</li>
            </ul>
          </nav>
        </div>

        <div class="modal-body">
          <div class="tab-content open" data-tab-content="public">
            <p class="align-left white-space">${room_topic(room)}</p>
            <p class="align-left"><br>
             <span class="xs">privacy: </span><span class="capitalize">${room_privacy(room)}</span><br>
             <span class="xs">type: </span><span class="capitalize">${room_type(room)}</span><br>
             ${is_programmed(room)}
            </p>
          </div>

          <div class="tab-content" data-tab-content="admin">
           <p class="align-left white-space editable" contenteditable="true">${room_topic(room)}</p>
           <p class="align-left"><br>
            <span class="xs">privacy: </span><span class="capitalize">${room_privacy(room)}</span><br>
            <span class="xs">type: </span><span class="capitalize">${room_type(room)}</span><br>
            <span class="xs"><span class="capitalize">${is_programmed(room)}</span><br>
           </p>
          </div>

        </div>

        <div class='modal-footer'>
          <button class='js-modal-close button-primary'>Done</button>
        </div>
      </div>
    </div>
  </div>
  `;


  div.innerHTML = help_text;
  document.querySelector('main').appendChild(div);
  room.div_help_text = div;


  // COPY OF: Global modal subnav tabs handlers
  // help text not yet loaded, so we need to duplicate here?
  var tabTriggerBtns = document.querySelectorAll('.tabs li.tab');

  tabTriggerBtns.forEach(function(tabTriggerBtn, index){
      tabTriggerBtn.addEventListener('click', function(){
        var currentTabData = document.querySelector('.tab-content[data-tab-content="' + this.dataset.tabTrigger + '"]');

        // remove classess only in nearest modal-body & modal-subheader classes
        let modalBodyClosest = currentTabData.closest(".modal-body");
        Array.from(modalBodyClosest.querySelectorAll('.tab-content.open')).forEach((el) => el.classList.remove('open'));
        let modalNav = tabTriggerBtn.closest(".modal-subheader");
        Array.from(modalNav.querySelectorAll('.tabs li.active')).forEach((el) => el.classList.remove('active'));

        // add classes
        currentTabData.classList.add('open');
        this.classList.add('active');
      });
  });


  let help = document.getElementById("room-info");
  let help_close = document.querySelectorAll('.js-modal-close');
  help_close.forEach(y => {
    y.addEventListener('click', function(){
      help.classList.remove('modal-visible');
    });
  });


  // Pattern for mod communication:
  // We add a function to the room object that can be used by other mods
  room.set_help_text = function(html) {
    document.querySelector('.help__text').innerHTML = html;
  }

  room.show_help = function() {
    help.classList.add('modal-visible');
  }
  room.hide_help = function() {
    help.classList.remove('modal-visible');
  }

}

async function room_leave(room) {
  document.querySelector('main').removeChild(room.div_help_text);
  room.div_help_text = null;
}


export default { room_enter, room_leave }
