
const help_text = `
  Custom Background
  <br /><br />
  While it's possible to switch themes through Night Mode, here's a room to set your Room's background.
  <br /><br />
  Images must be JPG or PNG ... and remember, we don't centrally store any data so when you leave your room ... your background
  is whiped also.
  <br /><br />
  <br /><br />
  <form id="custom-background-form" class='custom-background-form' method="post">
    <input required name="file" type='file'>
    <input class='button' type="submit" value='Set Background'/>
  </form>


`;


let _current_room;

function setBackground(dataUrl) {
  if (dataUrl) {
    document.getElementById('_room').style.backgroundImage = 'url(' + dataUrl + ')';
  }
  else {
    document.getElementById('_room').style.backgroundImage = '';
  }
}

async function user_add(user) {
  // if a new user comes in we must send him the background
  // but only one of us needs to do it
  let me_uid = _current_room.me.uid || 0;
  if (_current_room.users.findIndex((u) => u.uid < me_uid) > -1) {
    return;
  }

  let img = document.getElementById('_room').style.backgroundImage;
  if (!img) {
    return;
  }

  img = img.substr(4, img.length-5); // remove url(..) wraooer
  user.send({ command: 'background', background: img });
}

async function handle_background(user, msg) {
  setBackground(msg.background);
}


async function room_enter(room) {

  room.set_help_text(help_text);
  room.show_help();
  _current_room = room;

  const form = document.getElementById('custom-background-form');
  form.addEventListener('submit', function(e) {
    e.preventDefault();

    console.log(form.file.files);

    let file = form.file.files[0];
    var reader = new FileReader();

    reader.onload = function(e) {
      setBackground(e.target.result);
      room.send({ command: 'background', background: e.target.result });
    }

    reader.readAsDataURL(file); // convert to base64 string

    // close help_text on submit
    document.querySelector('.js-help').classList.remove('active');

    return false;


  });

}

async function room_leave(room) {


  setBackground(null);

}


export default { room_enter, room_leave, handle_background, user_add }
