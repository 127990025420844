/// Handles the websocket connection for an active room

// Signalling server
const WEBSOCKET_URL = process.env.SIGNALLING_SERVER;

console.log('Websocket URL:', WEBSOCKET_URL);

let room = null;

import { Settings } from "../globals";
import resize from "../components/Resizer";
import Mods from "../mods/*.js";
import { get_room_def } from "./RoomDef";

// periodic ping
window.setInterval(sendPing, 18000);
function sendPing() {
  if (room && room.websocket && room.websocket.readyState === WebSocket.OPEN) {
    room.send({ command: "ping" });
  }
}

async function do_remove_user(user, outgoing) {
  let idx = room.users.findIndex((u) => u.uid == user.uid);
  room.users.splice(idx, 1);
  user.outgoing = outgoing;
  await room.fire_event("user_remove", user);
}

function create_user(user, incoming) {
  user.send = function (msg) {
      msg.uid = user.uid;
      room.send(msg);
  };
  console.log('User:' , user);
  user.incoming = incoming;
  user.handle_data = async function (data) {
    if (!room) return;
    await room.fire_event("user_data", user, data);
  };
  return user;
}

async function do_add_user(u, incoming) {
  let user = create_user(u, incoming);
  room.users.push(user);
  await room.fire_event("user_add", user);
}

function func_name(cmd_name) {
  return (
    "handle_" +
    cmd_name.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    })
  );
}

async function connect() {
  return new Promise(function (resolve, reject) {
    room.websocket.onmessage = async (ev) => {
      if (!room) return;
      let msg = JSON.parse(ev.data);
      console.debug("RECV", msg);
      let user = null;
      if (msg.uid && msg.command !== "user-enter") {
        user = room.users.find((user) => user.uid === msg.uid);
        if (!user) {
          console.warn("Receiving message from not available user:", msg);
          return;
        }
      }

      switch (msg.command) {
        case "connect":
          resolve(msg);
          break;

        case "user-enter":
          await do_add_user(msg.user, true);
          break;

        case "user-leave":
          await do_remove_user(user, true);
          break;

        case "pong":
          break;

        default:
          // call handler in all mods
          let f = func_name(msg.command);
          await room.fire_event(f, user, msg);
      }
    };

    room.websocket.onclose = async function () {};
  });
}


// For non system rooms we override the mod list to local config
function overrideModList(room) {
  let mods = [
    "Rtc", "FaceScan", "RenderFaces", "MaskEditor",
    "PlaySounds", "RoomTitle", "HelpText", "RoomQr",
    "RoomSettings", "StartRoom",
    "VolumeDetect", "RoomControls", "ReadChat",
    "ExploreRooms", "Fip", "AvatarOverview", "Overlay"];

  if (room.me.is_viewer) {
    mods.push("Viewer");
  } else {
    mods.push("VoiceDistort");
  }

  if (!room.me.is_viewer && room.room_def.programming === 'scheduled') {
    mods.push("GoLive");
  }

  // only add playrecording if room has video
  if(room.room_def.room_video) {
    mods.push("PlayRecording");
  }

  // push DownloadSvg in /wear-wealgo
  if(room.room_def.name === 'wear-wealgo') {
    mods.push("DownloadSvg");
  }

  // push WearWeAlgo promo popover ... unless your there already
  if(room.room_def.name !== "wear-wealgo") {
    mods.push("WearPopover");
  }


  room.room_def.mods = mods;
}

const Room = {
  current: function () {
    return room;
  },

  enter: async function (_roomToken, mods) {
    let [_roomName, _botName] = _roomToken.split(":");


    // load room definition
    let room_def = await get_room_def(_roomName);
    if (mods) room_def.mods = mods;
    console.log("Downloaded room_def", room_def);

    // create or pass over me
    let me = {};
    if (room) {
      me = room.me; // carry over me from previous room
      await Room.leave();
      document.getElementById("js-room-title").style.display = "none";
    }

    console.log( "room.enter ", _roomName, " with current ", room,
      " and bot ", _botName, ' and, viewer=', me ? me.is_viewer:'<new-me>');



    let url = WEBSOCKET_URL + encodeURIComponent(_roomName);
    if (me.is_viewer) url += '?view';
    room = {
      websocket: new WebSocket(url),
      name: _roomName,
      room_def: room_def,
      users: [],
      me: me,
      is_init: _roomName === "_init",
      send: function (msg) {
        console.debug("SEND", msg);
        room.websocket.send(JSON.stringify(msg));
      },
      switchTo: async function (roomName, roomdef) {
        await Room.enter(roomName, roomdef);
      },
    };

    window.current_room = room;


    // For non-system rooms we override the mod-list here
    if (!room_def.room_system) {
      overrideModList(room);
    }

    let msg = await connect();
    room.users = msg.users.map((user) => create_user(user, false));
    room.me.is_queued = room.me.is_viewer && (room.users.length >= Settings.MAX_USERS);
    if (room.me.is_queued) {
      let i = room.room_def.mods.indexOf("Rtc");
      room.room_def.mods.splice(i, 1);
    }

    let found_mods = room.room_def.mods.filter((m) => Mods[m]);
    if (found_mods.length !== room.room_def.mods.length) {
      console.error('Some mods could not be loaded; found', found_mods, 'requested', room.room_def.mods);
    }

    room.mods = found_mods.map((m) => Mods[m].default);

    if (_roomName !== '_init') {
      history.replaceState( null, "WeAlgo - " + _roomName, "/" + _roomToken + location.search);
    }

    // Sends event "f" to all modules
    // Events are:
    // user_add
    // user_remove
    // room_enter
    // room_leave
    room.fire_event = async function (f, ...args) {
      for (let n in this.mods) {
        let mod_name = this.room_def.mods[n];
        let mod = this.mods[n];
        if (mod[f]) {
          if (f !== "user_data" && f !== "face_scanned") {
            console.debug("FIRE", f, mod_name);
          }
          await mod[f](...args);
        }
      }
    };

    room.add_mod = async function (mod_name, ...args) {
      this.room_def.mods.push(mod_name);
      this.mods.push(Mods[mod_name].default);
      let mod = this.mods[this.mods.length-1];
      if (mod.room_enter) {
        await mod.room_enter(this);
      }
      if (mod.user_add) {
        // Add existing users
        await Promise.all(room.users.map((u) => mod.user_add(u)));
      }
    }


    room.remove_mod = async function (mod_name, ...args) {
      let idx = this.room_def.mods.indexOf(mod_name);
      if (idx < 0) {
        console.error("Trying to remove mod " + mod_name + " that is not loaded");
      }
      this.room_def.mods.splice(idx,1);
      let mod = this.mods.splice(idx,1)[0];
      if (mod.room_leave) {
        await mod.room_leave(this);
      }
      if (mod.user_remove) {
        // Add existing users
        Promise.all(room.users.map((u) => mod.user_remove(u)));
      }
      return mod_name;
    }

    // Hide/show the doors
    // let any_door = false;
    // for (let n = 0; n < 4; n++) {
    //   let target_name = room.room_def.doors[n];
    //   let target_door = document.getElementById("door" + (n + 1));
    //   target_door.style.display = target_name ? "block" : "none";
    //   if (target_name) {
    //     target_door.children[0].innerText = target_name;
    //     any_door = any_door | !!target_name;
    //   }
    // }
    // document.querySelector('.showdoors').style.display = any_door ? 'block' : 'none';

    // Invoke room_enter and user_add events for all mods
    await room.fire_event("room_enter", room);

    // setup initial users
    await Promise.all(room.users.map((u) => room.fire_event("user_add", u)));
  },

  leave: async function () {
    while (room.users.length > 0) {
      await do_remove_user(room.users[room.users.length - 1]);
    }

    await room.fire_event("room_leave", room);
    room.websocket.close();
    room = null;
  },
};

window.enter = async function (_roomName) {
  document.getElementById("mask-editor").classList.remove("active");

  await Room.enter(_roomName);

  main.classList.add("in_room");

  resize();
};

export { Room };
