

import ctx from "./SvgCanvas";
import { Mask } from "./Mask";
import { Room } from "./Room";



let MyAvatar = {
  save_points: function(me) {
    /// crop
    let pts = me.pts
    if(!pts.length) {
      pts = pts.pts;
    }
    if (!pts) return;
    const x1 = Math.min(...(pts.map((p) => p.x)));
    const y1 = Math.min(...(pts.map((p) => p.y)));
    const x2 = Math.max(...(pts.map((p) => p.x)));
    const y2 = Math.max(...(pts.map((p) => p.y)));

    let pts2 = pts.map(p => { return { x: p.x-x1, y: p.y-y1 }});

    MyAvatar.set_avatar(pts2);

    window.localStorage.setItem( "mypts", JSON.stringify(pts2));

    // reload avatar list overview to match current mask
    this.update_canvas(Room.current().me.miniAvatarElm.children[0]);
  },
  set_avatar: function(pts) {
    const w = Math.max(...(pts.map((p) => p.x)));
    const h = Math.max(...(pts.map((p) => p.y)));


    let c = new ctx(w,h);
    Mask.myMask.render(c, pts);
    let ser = c.getSerializedSvg();
    let full_url = "data:image/svg+xml," + encodeURIComponent(ser);
    for(let link of document.querySelectorAll("link[rel~='icon']")) {
      console.log('setting icon');
      link.href = full_url;
    }


    // SFG adding MyAvatar to WearWeAlgo promo banner?
    let wear = new ctx(w,h);
    Mask.myMask.render(wear, pts);
    let setwear = wear.getSerializedSvg();
    let full_wear = "data:image/svg+xml," + encodeURIComponent(setwear);
    let wear_avatar = document.querySelector('.wear-avatar');
    wear_avatar.style.backgroundImage = "url(' " + full_wear + " ')";



  },
  load_points: function() {

    let pts = JSON.parse(window.localStorage.getItem( "mypts"));
    if (pts) {
      MyAvatar.set_avatar(pts);
    }
  },

  get_points: function() {
    let pts =  JSON.parse(window.localStorage.getItem( "mypts"));
    if(!pts) {
      pts = Mask.SAMPLE_POINTS;
    }
    const width = Math.max(...(pts.map((p) => p.x)));
    const height = Math.max(...(pts.map((p) => p.y)));
    return { pts, width, height };


  },

  update_canvas: function(canvas, mask, pts) {

    if (!mask) mask = Mask.myMask;
    if (!pts) pts = MyAvatar.get_points();

    canvas.width = pts.width;
    canvas.height = pts.height;
    canvas.style.height = '32px';
    canvas.style.width =  (32 * pts.width / pts.height) + 'px';
    mask.render(canvas.getContext('2d'), pts.pts);
  }


}

export default MyAvatar;
