
async function get_room_def(roomName) {

  let url = process.env.ROOMS_DB_SERVER + 'rooms/' + encodeURIComponent(roomName);
  console.log("Rooms DB Server:", url);
  let respons = await fetch(url);
  let room_def = await respons.json();
  return room_def;
}

async function get_room_title(roomName) {
  return (await get_room_def(roomName)).title || roomName;
}

export { get_room_def, get_room_title };
