// For expositions with in-situ terminals.
// users shouldnt be able to use certain buttons ... hide them from them

async function room_enter(room) {


  // SFG: hide all room controls (home, mute, share, map, help_text)
  // document.querySelector(".js-return-fitting-room").style.display = 'none';
  // document.querySelector(".js-help-open").style.display = 'none';
  // document.querySelector(".js-share-room").style.display = 'none';
}

async function room_leave(room) {

  // SFG: show mute button & enable mic
  // document.querySelector(".js-return-fitting-room").style.display = '';
  // document.querySelector(".js-help-open").style.display = '';
  // document.querySelector(".js-share-room").style.display = '';


}


export default { room_enter, room_leave }
