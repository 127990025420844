// play sounds on enter / leave user room

let audio = new Audio('../images/videos/Wealgo-EnterLeave.mp3');

function user_add() {
  audio.play();
}


export default { user_add };
