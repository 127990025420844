
import { Room } from "../components/Room";

const help_text = `
<div id='slides'>

  <div class='slides_container'>

    <div class='slides_slider'>
      <div class='slides'>

        <div id='slide-1'>
          <div class='slide_inner'>
            <div class='slide_title'>
              on Privacy <br /><br />
            </div>
            <div class='quote'>
              “The captive portal through which you experience everything else.”
            </div>
            <div class='quote_author'>
              <br />
              — Satya Nadella, on Microsoft Teams
            </div>
          </div>
        </div>
        <div id='slide-2'>
          <div class='slide_inner'>
            <div class='slide_title'>
              on Identity <br /><br />
            </div>
            <div class='quote'>
              “The essential thing is to recognize that consciousness is a biological process like digestion, lactation, photosynthesis, or mitosis.”
            </div>
            <div class='quote_author'>
              <br />
              — Ray Kurzweil, The Singularity is Near
            </div>
          </div>
        </div>
        <div id='slide-3'>
          <div class='slide_inner'>
            <div class='slide_title'>
              on Pollution <br /><br />
            </div>
            <div class='quote'>
              “The eyes of all future generations are upon you. And if you choose to fail us, I say - we will never forgive you.”
            </div>
            <div class='quote_author'>
              <br />
              — Greta Thunberg, UN Climate Summit, New York
            </div>
          </div>
        </div>

      </div> <!-- .hints -->
    </div> <!-- .hints_slider -->

    <div class='slides_nav'>
      <a href="#slide-1">1</a>
      <a href="#slide-2">2</a>
      <a href="#slide-3">3</a>
    </div>

  </div>
</div>

<span id='backToLobby' class="back fab overlay blue material-icons">keyboard_backspace</span>

`;



async function room_enter(room) {

  room.set_help_text(help_text);
  room.show_help();

  // SFG: hide mask editor & close help in this room
  document.querySelector('.js-help-close').style.display = 'none';

  // back to Lobby
  let backToLobby = document.getElementById('backToLobby');
  backToLobby.addEventListener('click', function(evt) {
    Room.enter('lobby');
  });


}

async function room_leave(room) {
  document.querySelector('.js-help-close').style.display = '';
}


export default { room_enter, room_leave }
