
// Work around a bug that caused Chrome not to apply echo cancellation when using Audio API
//
// We create a local web rtc loop: two peer connections. We feed the Media.AudioDestination to it
//
// Taken from: https://gist.github.com/alexciarlillo/4b9f75516f93c10d7b39282d10cd17bc
//
// See also: https://bugs.chromium.org/p/chromium/issues/detail?id=687574


let rtcConnection = null;
let rtcLoopbackConnection = null;
let loopbackStream = new MediaStream(); // is the stream you will read from for actual audio output

const offerOptions = {
  offerVideo: true,
  offerAudio: true,
  offerToReceiveAudio: false,
  offerToReceiveVideo: false,
};

let offer, answer;

// initialize the RTC connections

async function InitRtcLoopBack(inputStream, outputElm) {

  //outputElm.srcObject = inputStream;
  //return;
  rtcConnection = new RTCPeerConnection();
  rtcLoopbackConnection = new RTCPeerConnection();

  rtcConnection.onicecandidate = e =>
    e.candidate && rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
  rtcLoopbackConnection.onicecandidate = e =>
    e.candidate && rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

  rtcLoopbackConnection.ontrack = e => {
    let stream = new MediaStream();
    stream.addTrack(e.track, stream);
    stream.enabled = true;
    outputElm.srcObject = stream;
  };

  // setup the loopback
  rtcConnection.addTrack(inputStream.getAudioTracks()[0]);

  offer = await rtcConnection.createOffer(offerOptions);
  await rtcConnection.setLocalDescription(offer);

  await rtcLoopbackConnection.setRemoteDescription(offer);
  answer = await rtcLoopbackConnection.createAnswer();
  await rtcLoopbackConnection.setLocalDescription(answer);

  await rtcConnection.setRemoteDescription(answer);
}

export { InitRtcLoopBack };

