const Colors = {
  bgColor: "#161625", // SFG: dark color
  bgColorLight: "#f5f5f5", // light colored background
  colorMain: "#00A0FF",
  colorMainTransparent: "transparent",
  colorWhite: "#FFFFFF",
  colorDots: "#FF0000",
  colorRed: "#FF0000",

  // SFG: new color definitions, used in regions.js
  wealgoBlue: '#00a0ff',
  wealgoRed: '#ff0000',
  wealgoOrange: '#ff8d00',
  wealgoYellow: '#ffff00',
  wealgoGreen: '#00ff00',
  wealgoPurple: '#c700c7',
  wealgoBrown: '#932e00',
  wealgoGrey: '#b3b3b3',
  wealgoWhite: '#ffffff',
  wealgoBlack: '#000000',

  // SFG: new colors, rainbow
  // wealgoBlack: '#000000',
  // wealgoBrown: '#984C22',
  // wealgoRed: '#ff0000',
  // wealgoOrange: '#FFA500',
  // wealgoYellow: '#FFFF00',
  // wealgoGreen: '#00FF00',
  // wealgoBlue: '#00A0FF',
  // wealgoUv: '#5B00FF',
  // wealgoLightBlue: '#5BCDFF',
  // wealgoWhite: '#FFFFFF',
  // wealgoGrey: '#B3B3B3',
  // wealgoPurple: '#FF0087'
};

const Settings = {

  // Factor of nose position to determine move-speed
  MOVE_SPEED_X: 1 / 300,
  MOVE_SPEED_Y: 1 / 100,

  // The number of frames to use to calibrate center of nose
  NOSE_SAMPLES_NEEDED: 2000,

  // Requested webcam resolution
  CAMERA_WIDTH: 640,
  CAMERA_HEIGHT: 480,
  CAMERA_FRAME_RATE: 20,

  // We don't need full requestAnimationFrame frame-rate
  // Skip frames to approximate this FPS
  FPS: 13,

  // Take only this (center) part of the screen as initial random position
  RESTRICT_INITIAL_POSITION: 0.4,

  // We zoom by ZOOM_FACTOR * (<num_users> ^ ZOOM_EXPONENT)
  ZOOM_FACTOR: 1.7,
  ZOOM_EXPONENT: .25,

  ZOOM_FITTING: 1.4,

  // Waiting time when hitting a door before entering
  DOOR_DELAY: 800,

  AUDIO_BITRATE: 50,

  AUDIO_BLUR_THRESHOLD: 0.1,

  MAX_USERS: 15,

  SMOOTHING: 4,

  // Entry positions in new room after hitting a door
  DOOR_ENTRIES: [
    { x: -1, y: 0 },
    { x: .2, y: 1 },
    { x: 1, y: 0 },
    { x: .2, y: -1 }
  ]
};

export { Colors, Settings };
