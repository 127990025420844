/// Module to allow selection of elements by clicking
//

//import { GetCurrentFacePoints } from "./Animate";
import { Regions } from "../../regions";
import { handleRegion } from "../MaskEditor";

function handleClick(evt) {
  console.log('clicking ', evt.x, evt.y);
  let x =
    evt.x -
    Number.parseFloat(
      document.getElementById("_selfRender").style.marginLeft
    );
  let y =
    evt.y -
    Number.parseFloat(document.getElementById("_selfRender").style.marginTop);

  doSelect(x, y, evt);
}

function GetCurrentFacePoints() {
  return window.current_face_points;

}

// Handle selection by mouseclick
function doSelect(x, y, evt) {
  const ptsSelf = GetCurrentFacePoints();
  function is_in_triangle(px, py, ax, ay, bx, by, cx, cy) {
    //credit: http://www.blackpawn.com/texts/pointinpoly/default.html

    var v0 = [cx - ax, cy - ay];
    var v1 = [bx - ax, by - ay];
    var v2 = [px - ax, py - ay];

    var dot00 = v0[0] * v0[0] + v0[1] * v0[1];
    var dot01 = v0[0] * v1[0] + v0[1] * v1[1];
    var dot02 = v0[0] * v2[0] + v0[1] * v2[1];
    var dot11 = v1[0] * v1[0] + v1[1] * v1[1];
    var dot12 = v1[0] * v2[0] + v1[1] * v2[1];

    var invDenom = 1 / (dot00 * dot11 - dot01 * dot01);

    var u = (dot11 * dot02 - dot01 * dot12) * invDenom;
    var v = (dot00 * dot12 - dot01 * dot02) * invDenom;

    return u >= 0 && v >= 0 && u + v < 1;
  }

  let foundRegion = false;

  Regions.forEach((region, index) => {
    if (index === 0) {
      // Don't select all
      return;
    }
    let found = region.triang.some((triang) => {
      let c = [ptsSelf[triang[0]], ptsSelf[triang[1]], ptsSelf[triang[2]]];
      let in_triangle = is_in_triangle(
        x,
        y,
        c[0].x,
        c[0].y,
        c[1].x,
        c[1].y,
        c[2].x,
        c[2].y
      );
      return in_triangle;
    });
    if (found) {
      let toggleMultiRegionSelect = false;
      if (evt.shiftKey) {
        toggleMultiRegionSelect = true;
      } else {
        toggleMultiRegionSelect = false;
      }
      handleRegion(index, toggleMultiRegionSelect);
      foundRegion = true;
      return;
    }
  });
  if (!foundRegion) {
    // deselect
    handleRegion(-1);
  }
}

export { handleClick }
