import { Settings } from "../globals";
import { Message } from "./Messaging";


import { InitRtcLoopBack } from "./MediaRtcLoopBack";

let Media = {
  LocalAudioTrack: null,
  AudioCtx: null,

  AudioDestination: null
};




function OpenDevices() {
  let _webcam = document.getElementById("_webcam");
  return new Promise(function (resolve, reject) {
    window.navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,

          autoGainControl: true,
          noiseSuppression: true

        } ,
        video: {
          width: Settings.CAMERA_WIDTH,
          height: Settings.CAMERA_HEIGHT,
          frameRate: Settings.CAMERA_FRAME_RATE,
          facingMode: "user",
        },
      })
      .then(function (mediaStream) {
        let tracks = mediaStream.getAudioTracks();
        console.log('Audiotracks', tracks);
        Media.LocalStream = mediaStream;
        Media.LocalAudioTrack = tracks[0];
        Media.LocalAudioTrack.enabled = false;

        _webcam.srcObject = mediaStream;
        _webcam.play();

        Message("detecting-first");
        _webcam
          .play()
          .then(resolve)
          .catch(function (e) {
            reject(e);
          });
      })
      .catch(function (e) {
        console.log("user rejected", e);
        Message("camera-not-allowed");
      });
  });
}

// Handle mute button
const muteBtn = document.getElementById('js-mute'); // mute button
const muteText = muteBtn.querySelector('.tooltiptext'); // mute icon tooltip text
muteBtn.addEventListener("click", function (ev) {
  if (Media.LocalAudioTrack) {
    let muted = Media.LocalAudioTrack.enabled;
    Media.LocalAudioTrack.enabled = !muted;
    ev.currentTarget.classList.toggle("muted", !Media.LocalAudioTrack.enabled);
    console.log("Set muted: ", muted);
    // toggle mute tooltip text
    if (muteText.innerHTML === "Microphone Muted") {
      muteText.innerHTML = "Mute Microphone";
    } else {
      muteText.innerHTML = "Microphone Muted";
    }
  }
});


Media.Init = async function() {
  await OpenDevices();

  window.AudioContext = window.AudioContext || window.webkitAudioContext;

  if (window.AudioContext) {
    Media.AudioCtx = new AudioContext();
    //Media.SetListenerPosition(0,0);
    //Media.AudioDestination = Media.AudioCtx.createMediaStreamDestination();
    Media.AudioDestination = Media.AudioCtx.createMediaStreamDestination();
    Media.LocalNode = Media.AudioCtx.createMediaStreamSource(Media.LocalStream);
    Media.LocalNode.connect(Media.AudioDestination);


    console.log("AUDIO NAAR SPEAKER")
    //Media.LocalNode.connect(Media.AudioCtx.destination);

    console.log('setting source object to ', Media.AudioDestination);
    document.getElementById('audio_remote_all').srcObject = Media.AudioDestination.stream;
    //
    await InitRtcLoopBack(Media.AudioDestination.stream, document.getElementById('audio_remote_all'));
  }
  else {
    Media.AudioDestination = Media.LocalAudioTrack;
    console.warn("AudioContext not supported");
  }
}




Media.SetListenerPosition = function(x,y) {
  if (!Media.AudioCtx) {
    return;
  }
  let listener = Media.AudioCtx.listener;
  if (listener.positionX) {
    Media.AudioCtx.listener.positionX.value = x;
    Media.AudioCtx.listener.positionY.value = y;
    Media.AudioCtx.listener.positionZ.value = 0.7;
  }
  else {
    listener.setPosition(x,y,0.7);
  }
}

export { Media };
