
import { Mask } from "../components/Mask";
import { Media } from "../components/Media";

// const help_text = `
//   Talking to Me? ...
//   <br /><br />
//   This experimental room (dd20210206) is different in some ways, bear with us: <br />
//   1. Here we're using browser based 'Speech to Text' recognition aka:
//   <a href='https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API' target='_blank'>webkitSpeechRecognition</a>.
//   <em>Note</em>: On some browsers, like Chrome, using Speech Recognition on a web page
//   involves a server-based recognition engine. Your audio is sent to a web service for
//   recognition processing, so it won't work offline. With regard to data logging —we did <em>not</em> opt-in—, Google
//   <a href='https://cloud.google.com/speech-to-text/docs/data-logging'  target='_blank'>writes</a>:
//   "By default, Speech-to-Text does not log customer audio data or transcripts." We're not using Google Cloud service, so the real
//   question is wether your Chrome browser uses it.
//   Check <a href='https://stackoverflow.com/questions/44572914/user-data-privacy-in-google-speech-to-text-api-google-cloud-speech-solution'  target='_blank'>this thread</a> on SO for further reading.
//   <br /><br />
//   2. You can make Speech recognition work on Firefox by manually enabling it in about->config like <a href='https://caniuse.com/speech-recognition'  target='_blank'>so</a>.
//   <br /><br />
//   3. You can switch voice recognition languages: English (EN) and Dutch (NL)
//   <br /><br />
//   4. Wealgo does <em>not</em> collect, store or sell any user data.
// `;


const chat_container = `
  <div id="chat-inner" class='chat-inner'>

    <ul id='chat'></ul>

    <form id="chat-form" class='chat-form voicechat'>
      <p class='chat-message'></p>
      <span class='voice-status waiting'>
        <span class='material-icons mic'>mic</span>
        <span class='material-icons sending'>send</span>
      </span>
    </form>
  </div>

`;

let voice_status;
let sending;
let recognition;
let lang = 'en-US';
let end_recognize = false;
let recognizing = false;
let interim_transcript = '';

async function handle_chat(user, msg) {
  addChatLine(user.mask, msg.message);
}

function addChatLine(avatar, text) {
    let chat = document.getElementById('chat');
    if (!chat) return;
    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 900;
    canvas.style.width = "60px";
    canvas.style.height = "40px";
    let li = document.createElement('li');
    if (avatar) avatar.render(canvas.getContext('2d'), Mask.SAMPLE_POINTS);
    li.appendChild(canvas);
    let p = document.createElement('p');
    p.innerText = text;
    li.appendChild(p);
    chat.appendChild(li);
    li.scrollIntoView();
}

let _current_room;

function setVoiceStatus(st, temporary) {
  voice_status.className = 'voice-status ' + st;
  if (temporary) {
    window.setTimeout(function() {
      voice_status.className = 'voice-status waiting';
    },2500);
  }
}

async function room_enter(room) {
  _current_room = room;

  // room.set_help_text(help_text);
  // room.show_help();

  // SFG: hide mute button and set mute (always muted)
  document.getElementById('js-mute').style.display = 'none';
  Media.LocalAudioTrack.enabled = false;


  // create chat-container & append to main
  let chat = document.createElement('div');
  chat.className = 'chat-container open';
  chat.innerHTML = chat_container;
  document.querySelector('main').appendChild(chat);
  room.div_chat = chat

  // set autofocus on input
  // document.querySelector('.chat-message').focus();

  // chat form
  var sendMessage = function(evt) {
    evt.preventDefault();
    let chat_message = document.querySelector('.chat-message');
    let text = chat_message.innerText;
    addChatLine(Mask.myMask, text);
    room.send({command: 'chat', message: text, lang: lang});
    document.querySelector('.chat-message').innerText = '';

  };

  // var form = document.getElementById("chat-form");
  // form.addEventListener("submit", sendMessage, true);

  setup_recognize();

}

function addMyLine(text) {
  addChatLine(Mask.myMask, text);
  _current_room.send({command: 'chat', message: text, lang: lang});
}


async function room_leave(room) {
  if (recognition) {
    end_recognize = true;
    recognition.stop();
    recognition = null;
  }

  Media.LocalAudioTrack.enabled = true;
  document.querySelector('main').removeChild(room.div_chat);

  // SFG: show mute button
  document.getElementById('js-mute').style.display = '';
}

function getRecognizer() {
  console.log("Init Recognizer", lang);
  let r = new webkitSpeechRecognition();
  r.continuous = true;
  r.interimResults = true;
  r.lang = lang;
  r.maxAlternatives = 1;

  let chat_message = document.querySelector('.chat-message');
  r.onstart = function() {
    recognizing = true;
  };

  r.onerror = function(evt) {
    setVoiceStatus('error', true);
  };

  r.onend = function() {
    //recognizing = false;
    console.log('RECOGNIZE END');
    if (!end_recognize) {
      if (r.lang !== lang) {
        recognition = null;
        recognition = getRecognizer();
      }
      else {
        r.start();
      }
    }

  };


  // setting classes on mic / sending for input recognition
  voice_status = document.querySelector('.voice-status');
  sending = document.querySelector('.sending');

  r.onspeechend = function(ev) {
    sending.classList.remove('hide');
    console.warn('speechend', ev);
  };

  r.onspeechstart = function(ev) {
    setVoiceStatus('active');
    console.warn('speechstart', ev);
  };
  r.onsoundend = function(ev) {
    console.warn('soundend', ev);
  };
  r.onsoundstart = function(ev) { console.warn('soundstart', ev); };
  r.onnomatch = function(ev) {
    setVoiceStatus('error', true);
  }
  r.onresult = function(event) {
    var interim_transcript = '';
    for (var i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        addMyLine(event.results[i][0].transcript);
        chat_message.innerText = '';
        chat_message.classList.remove('error');
        setVoiceStatus('sending', true);
      } else {
        interim_transcript += event.results[i][0].transcript;
        console.log('Interim:', interim_transcript);
        chat_message.innerText = interim_transcript;
      }
    }
  };
  r.start();
  return r;
}


function setup_recognize() {

  if (!('webkitSpeechRecognition' in window)) {
    return;
  }

  const languageSwitch = `
    <div class='inputLanguage'>
      <div class='inputLanguageEn'>Voice recognition: </div>
      <div class='inputLanguageNl hide'>Stem herkenning: </div>
    </div>
    <span><input type="radio" name="lang" id="lang_en" value="en-US" checked><label for="lang_en">EN</label></span>
    <span><input type="radio" name="lang" id="lang_nl" value="nl-NL"        ><label for="lang_nl">NL</label></span>
  `;

  let sw = document.createElement('div');
  sw.classList.add('languageSwitch');

  sw.innerHTML = languageSwitch;
  document.getElementById('chat-inner').insertBefore(sw, document.getElementById('chat'));

  let inputLanguageEn = document.querySelector('.inputLanguageEn');
  let inputLanguageNl = document.querySelector('.inputLanguageNl');

  for (var el of document.querySelectorAll('.languageSwitch input')) {
    el.addEventListener('change', function(ev) {
      if (this.checked) {
        lang = this.value;

        if(lang == 'nl-NL') {
          inputLanguageNl.classList.remove('hide');
          inputLanguageEn.classList.add('hide');
        }
        if(lang == 'en-US') {
          inputLanguageNl.classList.add('hide');
          inputLanguageEn.classList.remove('hide');
        }

        recognition.abort();
        //recognition = null;
        //recognition = getRecognizer(this.value);
        //recognition.lang = this.value;
      }
    });
  }
  recognition = getRecognizer('en-EN');

}


export default { room_enter, room_leave, handle_chat }
