// Main frame animation functio
// Renders self, others and sends own mask data

import { Settings } from "../globals";
import { Mask } from "../components/Mask";
import { Codec } from "../components/Codec";
import resize from "../components/Resizer";
import MyAvatar from "../components/MyAvatar";


var _room_element = document.getElementById("_room");
let _selfRenderRoom = document.getElementById("u_me");

// Incoming peer. Create canvas and rescale
async function user_add(user) {

  let canvas = document.createElement("canvas");
  canvas.id = "u_" + user.uid;
  canvas.classList.add("remote");
  _room_element.insertBefore(canvas, _selfRenderRoom);
  user.canvas = canvas;

  user.send({ command: 'send-metadata', metadata: Mask.myMask.serialize(), pts: MyAvatar.get_points() });

  // Rearrange based on number of videos
  resize();
};

function guessOriginDoor(pos) {
  if (pos.x<-Settings.RESTRICT_INITIAL_POSITION*1.1) {
    return 3;
  } else if (pos.x>Settings.RESTRICT_INITIAL_POSITION*1.1) {
    return 1;
  } else if (pos.y<-Settings.RESTRICT_INITIAL_POSITION*1.1) {
    return 2;
  } else if (pos.y>Settings.RESTRICT_INITIAL_POSITION*1.1) {
    return 4;
  }
  else {
    return 0;
  }
}

function scaleCanvas(canvas) {
  // Scale is triggered after a resize by setting dataset.scale
  // Actual scaling then happens on rendering to prevent flicker
  if (!canvas.dataset.scale) return;
  const user_size = canvas.dataset.scale;
  canvas.style.width = user_size + "px";
  canvas.style.height = user_size + "px";
  canvas.width = user_size;
  canvas.height = user_size;
  delete canvas.dataset.scale;
}

async function user_remove(user) {

  if (!user.canvas) {
    return;
  }
  if (!user.mask) {
    user.canvas.remove();
    resize();
    return;
  }

  let tween = null;
  if (user.outgoing) {
    let door = guessOriginDoor(user.pos);
    if (!door) {
      tween = {
        type: 'chaos',
        progress: 0
      }
    } else {
      tween = {
        type: 'drop',
        dir: door,
        progress: 0
      }
    }
  }

  // Mini render room for fade out
  function animateFadeOut() {
    // Skip to reduce framerate to Settings.FPS
    let ctxRemote = user.canvas.getContext("2d");
    ctxRemote.clearRect(0, 0, user.canvas.width, user.canvas.height);
    user.mask.render(ctxRemote, user.pts, tween);
    if (tween) tween.progress += 0.16;
    if (!tween || tween.progress >=1) {
      user.canvas.remove();
      resize();
    } else {
      window.setTimeout(animateFadeOut, 1000 / Settings.FPS);
    }
  }

  animateFadeOut();
};

// User data is a new set of landmarks from a peer
async function user_data(user, msg) {
  // user gone?
  if (!user.canvas) {
    console.error("Receiving points before entering");
    return;
  }

  // user no mask
  if (!user.metadata && !user.mask) {
    // Not really a problem though a bit weird that data channel is faster than socket
    console.warn("Receiving points before mask");
    return;
  }

  // Don't render if page is minimized or tabbed away
  if (document.hidden) return;

  // Load new mask if any
  if (user.metadata) {
    user.mask = new Mask();
    user.mask.deserialize(user.metadata);
    delete user.metadata;
  }
  window.all_data = window.all_data || [];
  window.all_data.push({u:user.uid, t: performance.now()});


  // decode and render
  const data = new Uint16Array(msg);
  const doBlur = Codec.getMark(data) === 1;
  const canvasRemote = user.canvas;
  let pts = Codec.decodeLandmarks(
    data,
    { width: canvasRemote.width, height: canvasRemote.height },
    { x: 0, y: 0 }
  );

  // Check fade in
  if (user.incoming && !user.fadeIn) {
    user.fadeIn = true;
    let door = guessOriginDoor(pts.pos);
    if (!door) {
      user.tween = {
        type: 'chaos',
        progress: 1,
        auto_inc: true
      }
    } else {
      user.tween = {
        type: 'drop',
        dir: door,
        progress: 1,
        auto_inc: true
      }
    }
  }

  // position canvas
  const left =
    ((pts.pos.x + 1) / 2) * _room_element.offsetWidth -
    canvasRemote.offsetWidth / 2;
  const top =
    ((pts.pos.y + 1) / 2) * _room_element.offsetHeight -
    canvasRemote.offsetHeight / 2;
  canvasRemote.style.left = left + "px";
  canvasRemote.style.top = top + "px";
  scaleCanvas(canvasRemote);

  let ctxRemote = canvasRemote.getContext("2d");
  ctxRemote.clearRect(0, 0, canvasRemote.width, canvasRemote.height);

  user.mask.render(ctxRemote, pts.pts, user.tween, -1, doBlur);
  if (user.after_render) {
    user.after_render(ctxRemote, pts.pts);
  }
  user.pts = pts.pts;
  user.pos = pts.pos;

  if (user.tween && user.tween.auto_inc) {
    user.tween.progress -= 0.16;
    if (user.tween.progress < 0) {
      user.tween = null;
    }
  }
};

async function handle_userMetadata(user, msg) {
  user.metadata = msg.metadata;
  user.avatar_pts = msg.pts;
}

export default { user_add, user_remove, user_data, handle_userMetadata };

