/* Room switching and related layout changes */

let _room;

// is_viewer is audience
// is_admin is room ownwer

const liveStatus = document.querySelector('.js-status-live'); // live status indicator
const goLive = document.querySelector('js-golive'); // golive button in present / scheduled
const roomInfo = document.querySelector('.js-room-info'); // room info icon
const faceLight = document.querySelector('.js-nightmode'); // facelight switch, can go, only on init?
const mute = document.querySelector('.js-mute'); // mute mic icon
const muteTooltip = mute.querySelector('.tooltiptext');
const startRoom = document.querySelector('.js-room-start'); // start room icon
const exploreRooms = document.querySelector('.js-rooms-explore'); // explore room icon
const shareRoom = document.querySelector('.js-room-share'); // share room icon
const roomSettings = document.querySelector('.js-room-settings'); // room settings icon
const editAvatar = document.querySelector('.js-edit-avatar'); // edit avatar icon
const roomChat = document.querySelector('.js-room-chat'); // chat icon
let roomName;

async function room_enter(room) {

  _room = room;

  // roomName for special case Lobby: everyone has RoomSettings, also short URLS / non admins
  roomName = room.room_def.name;

  // editAvatar.style.display = room.me.is_viewer ? 'none' : 'inherit'; // return here when edit avatar for viewer is ready
  // mute.style.display = room.me.is_viewer ? 'none' : 'inline-block';
  // roomSettings.style.display = !room.me.is_admin ? 'none' : 'inherit';

  // is_admin in play
  if(room.me.is_admin) {
    // console.log('im admin');
    document.querySelector('li.private').classList.remove('hide'); // show room info Admin
  }

  // !is_admin, room != lobby (non admins have roomSettings in Lobby)
  if(!room.me.is_admin && roomName !== 'lobby') {
    // console.log('im not admin');
    roomSettings.style.display = 'none';
  }

  // is_viewer (in present) w/ programming == scheduled, not live
  if(room.me.is_viewer) {
    // console.log('im viewer');
    mute.style.display = 'none';
    editAvatar.style.display = 'none';
  }

  // is_presenter (in present) w/ programming == scheduled, not live
  if(!room.me.is_viewer) {
    // console.log('im not viewer');
  }

  // remove fadeOut from tooltipText mute icon
  if(!room.me.is_viewer) {
    setTimeout(function(){
      mute.classList.remove("fadeOut");
    }, 3000);
  }


  // is_presenter in present w/ programming == scheduled, live


}

async function room_leave(room) {


}


export default { room_enter, room_leave }
