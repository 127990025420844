
import { Mask } from "../components/Mask";
import MyAvatar from "../components/MyAvatar";

let _room = null;
let _boxElm = null;
let _containerElm = null;

let _btnElm = null;

let avatarContainerIsHidden = true;

async function room_enter(room) {

    _room = room;
    // _containerElm = document.createElement('div');
    // _containerElm.className = ('avatar-overview');
    // _btnElm = document.createElement('button');
    // _btnElm.innerHTML = "Show Audience";
    // _btnElm.className = ('avatar-overview-toggle');

    _containerElm = document.querySelector('.avatar-overview');
    _boxElm = document.createElement('ul');
    _boxElm.className = 'avatar-list';

    _containerElm.appendChild(_boxElm);

    // document.querySelector('main').appendChild(_btnElm);
    // document.querySelector('main').appendChild(_containerElm);

    // _btnElm.addEventListener('click', function(evt){
    //     if(avatarContainerIsHidden){
    //         _containerElm.classList.remove('avatar-overview--closed');
    //         _containerElm.classList.add('avatar-overview--open');
    //         _btnElm.innerHTML = "Hide Audience";
    //         avatarContainerIsHidden = false;
    //     } else {
    //         _containerElm.classList.remove('avatar-overview--open');
    //         _containerElm.classList.add('avatar-overview--closed');
    //         _btnElm.innerHTML = "Show Audience";
    //         avatarContainerIsHidden = true;
    //     }
    //     console.log("avatarContainerIsHidden ", avatarContainerIsHidden)
    // })

    room.me = room.me || {};
    room.me.miniAvatarElm = createRow();
    room.me.setAvatarText = function(t) {
        room.me.miniAvatarElm.children[1].innerText = t;
    }

    MyAvatar.update_canvas(room.me.miniAvatarElm.children[0]);
}

function createRow() {
    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 900;
    canvas.style.width = "60px";
    canvas.style.height = "40px";
    let li = document.createElement('li');
    li.appendChild(canvas);
    let p = document.createElement('p');
    li.appendChild(p);
    _boxElm.appendChild(li);
    return li;
}

async function room_leave(room) {
    // document.querySelector('main').removeChild(_containerElm);
    room.me.miniAvatarELm = null;
    room.me.setAvatarText = null;
}

async function user_add(user) {
    user.miniAvatarElm = createRow();
    user.setAvatarText = function(t) {
        user.miniAvatarElm.children[1].innerText = t;
    }
}

async function user_remove(user) {
    if(user.miniAvatarElm) {
        _boxElm.removeChild(user.miniAvatarElm);
        user.miniAvatarElm = null;
    }
}

async function handle_userMetadata(user, msg) {
    if (!user.miniAvatarElm) {
        return;
    }
    let mask = new Mask();
    mask.deserialize(msg.metadata);
    user.avatar_pts = msg.pts;

    MyAvatar.update_canvas(user.miniAvatarElm.children[0], mask, msg.pts);

}

export default { room_enter, room_leave, user_add, user_remove, handle_userMetadata };
