

import { Codec } from "../components/Codec";
import { Mask } from "../components/Mask";
import { Record } from "../components/Record";


import SampleBot from "../bots/SampleBot";

const _width = 1200;
const _height = 900;

let bot;
let timer;


function get_bounds(pts) {
  const x2 = Math.max(...(pts.map((p) => p.x)));
  const x1 = Math.min(...(pts.map((p) => p.x)));
  const y2 = Math.max(...(pts.map((p) => p.y)));
  const y1 = Math.min(...(pts.map((p) => p.y)));
  return {x: x1, y: y1, width: x2-x1, height: y2-y1};
}




async function user_data(user, msg) {
  const data = new Uint16Array(msg);
  const pts = Codec.decodeLandmarks(
    data,
    { width: 1000, height: 1000 },
    { x: 0, y: 0 }
  );

  // record
  Record.record_pts(user.uid, pts.pts);

}

let pts;
async function room_enter(room) {
  bot = await SampleBot.create();
  pts = await bot.next_points();
  //console.log('bot', bot);

  room.send({ command: 'broadcast-metadata', metadata: Mask.myMask.serialize() });

  async function run_timer() {
    pts = Record.get_pts(pts);
    console.log('x',pts);
    const frame = Codec.encodeLandmarks(
          { width: _width, height: _height },
          get_bounds(pts),
          pts,
          {x:0,y:0}
        );
    room.broadcast_data(frame);
  }

  timer = window.setInterval(run_timer, 100);
}

async function user_add(user) {


  user.send({ command: 'send-metadata', metadata: Mask.myMask.serialize() });

};



async function room_leave() {
  window.clearInterval(timer);
}

export default { room_enter, room_leave, user_data, user_add }

