// record video & audio message to send to room or socials?
import { Media } from "../components/Media";


// let recBtn = document.createElement('button');
// recBtn.className = 'start-recording';
// recBtn.innerHTML = 'start recording';
// document.querySelector('main').appendChild(recBtn);
//
// let stopBtn = document.createElement('button');
// stopBtn.className = 'stop-recording';
// stopBtn.innerHTML = 'stop recording';
// document.querySelector('main').appendChild(stopBtn);


async function room_enter(room) {

  // Find the canvas element to capture
  // var canvasRec = document.getElementById('u_me');


  // A new video element (no need to add to DOM)
  let video = document.createElement('video');

  // The canvas that contains the heat
  // let canvas = document.querySelector("#heatmapdiv > canvas")
  let canvas = document.getElementById("u_me")

  // Capture the stream
  var videoStream = canvas.captureStream(24);

  // add local audiostream
  videoStream.addTrack(Media.LocalAudioTrack);

  var mediaRecorder = new MediaRecorder(videoStream, {
     mimeType: "video/webm; codecs=vp9"
  });

  mediaRecorder.ondataavailable = e => {
      var a = document.createElement('a');
      a.className = 'download-recording btn overlay';
      a.download = 'video.webm';
      a.href = URL.createObjectURL(e.data);
      a.textContent = 'download video';
      document.querySelector("main").appendChild(a);

  };
  // Now we're prepared

  // start recording
  recBtn.addEventListener("click", (evt) => {
    mediaRecorder.start();
  });

  // this will add a download link to page
  stopBtn.addEventListener("click", (evt) => {
    mediaRecorder.stop();
  });

}



export default { room_enter }
