// explore rooms (https://rooms.wealgo.org)

let _room = null;
let _rooms = null;

let room_system = [];
let room_featured = [];
let room_recent = [];
let initialized = false;

// const system_tab = document.body.querySelector('.tab-content[data-tab-content="system"]');
const featured_tab = document.body.querySelector('.tab-content[data-tab-content="featured"]');
const recent_tab = document.body.querySelector('.tab-content[data-tab-content="recent"]');

function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
};

async function set_rooms() {
    console.log('Opening room list');
    let url = process.env.ROOMS_DB_SERVER + 'rooms';
    let _rooms = await (await fetch(url)).json();
    // console.log(_rooms);

    for (let i = 0; i < _rooms.length; i++) {

      // push rooms in categories array
      if(_rooms[i].room_system == 1) {
        room_system.push(_rooms[i]);
      }
      else if(_rooms[i].room_featured) {
        room_featured.push(_rooms[i]);
      }
      else if(_rooms[i].privacy === 'open') {
        room_recent.push(_rooms[i]);
      }

    }

    // set system rooms
    // system_tab.innerHTML = '<ul>' + room_system.map(function (room) {
    //   let content = `<li>
    //                   <div class="room_card pbottom">
    //                    <a  class="underline" href="${process.env.PUBLIC_SERVER}${room.name}">
    //                     <div class="room_visual">
    //                      <video class="video_card" loop muted playsinline autoplay >
    //                       <source src="${room.room_video}" type="video/mp4">
    //                      </video>
    //                     </div>
    //                    </a>
    //                     <div class='room_info'>
    //                      <h3 class="align-left">${room.title}</h3>
    //                      <p class="xs">${truncate(room.topic, 120)}</p><br>
    //                      <p class='xs'>
    //                       Privacy: <span>${room.privacy}</span><br>
    //                       Type: <span>${room.type}</span><br>
    //                       Programming: <span>${room.programming}</span>
    //                      </p>
    //                     </div>
    //                    </div>
    //                  </li>
    //                  `;
	  //   return content;
    //  }).join('') + '</ul>';

    // set featured rooms
    featured_tab.innerHTML = '<ul>' + room_featured.map(function (room) {
      let content = `<li>
                      <div class="room_card pbottom">
                       <a class="underline" href="${process.env.PUBLIC_SERVER}${room.name}" title="Join ${room.title}" alt="${room.title}">
                        <div class="room_visual">
                         <video class="video_card" loop muted playsinline autoplay >
                          <source src="${room.room_video[0]}" type="video/mp4">
                         </video>
                        </div>
                       </a>
                        <div class='room_info'>
                         <h3 class="align-left">${room.title}</h3>
                         <p class="xs">${truncate(room.topic, 120)}</p><br>
                         <p class='xs'>
                          Privacy: <span>${room.privacy}</span><br>
                          Type: <span>${room.type}</span><br>
                          Programming: <span>${room.programming}</span>
                         </p>
                        </div>
                       </div>
                     </li>
                     `;
	    return content;
     }).join('') + '</ul>';

    // set recent rooms, newest first (reverse())
    recent_tab.innerHTML = '<ul>' + room_recent.reverse().map(function (room) {
  	    return '<li><a  class="underline" href="' + process.env.PUBLIC_SERVER + room.name + ' ">' + room.title + '</a></li>';
    }).join('') + '</ul>';


}




async function room_enter(room) {

  _room = room;
  let buttons = document.getElementsByClassName('explore-btn');
  for(let b of buttons) {
    b.addEventListener('click', ev => {
      if (!initialized) {
        initialized = true;
        set_rooms();
      }
    });
  };

  //set_rooms();

}


async function room_leave(room) {

}



export default { room_enter, room_leave }
