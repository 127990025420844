//
//
import { Settings } from "../globals";

const _room = document.getElementById('_room');
const _selfRender = document.getElementById('_selfRender');

/// Simple resizing of canvases based on number of users and screen zsize
function resize() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const size = Math.min(_room.offsetWidth, _room.offsetHeight);
  const user_count = _room.childElementCount;
  const user_size = (size / Math.pow(user_count, Settings.ZOOM_EXPONENT)) * Settings.ZOOM_FACTOR;

  for(let i=0; i < _room.children.length; i++) {
    _room.children[i].dataset.scale = user_size;
  }

  // fitting room self zoom
  _selfRender.dataset.scale = true;
}

window.addEventListener('resize', resize);
window.addEventListener('load', resize);
window.addEventListener("orientationchange", resize);

export default resize;

