// show WearWeAlgo promo popover in room ... not in /wear-wealgo (rooms.js)

function showMerch(){
    setTimeout(function(){
    document.getElementById('wear-wealgo').classList.add('show');
  }, 10000);
};


async function room_enter(room) {

  // get/set local storage showPromo
  window.showPromoStorage = localStorage;
  if (!showPromoStorage.getItem("showPromo")) {
    showPromoStorage.setItem("showPromo", "show");
    showMerch();
  }
  if(window.localStorage.getItem("showPromoStorage") === "show") {
    showMerch();
  }

  // hide WearWeAlgo promo popover
  let popOverClose = document.querySelector('.js-popover-close');
  let popOver = document.getElementById('wear-wealgo');
  popOverClose.addEventListener('click', function(){

    // close popover
    popOver.classList.remove('show');

    // set local storage to not show again
    showPromoStorage.setItem("showPromo", "dontShow");
    console.log('Wear promo Dont show stored');
  })


}


export default { room_enter }
