// SFG: trying to generate multiple masks with random regionColoring
// copy of avatarBox.js

import { Mask } from "../components/Mask";
import { generateMask } from "../components/GenerateMask";

let _room = null;
let _boxElm = null;

async function room_enter(room) {

  _room = room;

  _boxElm = document.createElement('ol');
  _boxElm.className = ('sampleMaskBox');


  document.querySelector('main').appendChild(_boxElm);

  room.me = room.me || {};
  room.me.miniAvatarElm = createRow();
  room.me.setAvatarText = function(t) {
    room.me.miniAvatarElm.children[1].innerText = t;
  }

  Mask.myMask.render(room.me.miniAvatarElm.children[0].getContext('2d'), Mask.SAMPLE_POINTS);

  for (let n = 0; n < 50; n++) {
    const elm = createRow();
    Mask.myMask.generateRandom();
    Mask.myMask.render(elm.children[0].getContext('2d'), Mask.SAMPLE_POINTS);

  }

}

function createRow() {
    const canvas = document.createElement('canvas');
    canvas.width = 1200;
    canvas.height = 900;
    canvas.style.width = "260px";
    canvas.style.height = "180px";
    let li = document.createElement('li');
    li.appendChild(canvas);
    let p = document.createElement('p');

    li.appendChild(p);
    _boxElm.appendChild(li);
    return li;
  }

// SFG: draw 50 masks

async function room_leave(room) {
  document.querySelector('main').removeChild(_boxElm);
  room.me.miniAvatarELm = null;
  room.me.setAvatarText = null;
}

async function user_add(user) {
  // user.miniAvatarElm = createRow();
  // user.setAvatarText = function(t) {
  //   user.miniAvatarElm.children[1].innerText = t;
  // }
}

async function user_remove(user) {
  _boxElm.removeChild(user.miniAvatarElm);
  user.miniAvatarElm = null;
}

async function handle_userMetadata(user, msg) {
  if (!user.miniAvatarElm) {
    return;
  }
  let mask = new Mask();
  mask.deserialize(msg.metadata);
  mask.render(user.miniAvatarElm.children[0].getContext('2d'), Mask.SAMPLE_POINTS);

}

export default { room_enter, room_leave, user_add, user_remove, handle_userMetadata };
