// GoLive
// shows GoLive button for presenters in room
// onclick, room is live for viewers, info curtain goes up ...

// SFG: doing logo animation status indication
let logoStatus = document.querySelector('.js-room-status-logo')
let tooltipStatus = document.querySelector('.js-room-status-tooltip')

async function handle_live(live) {
  _room.is_live = true;
  let btn_container = document.querySelector('li.golive');
  btn_container.classList.add('hide');

  // Room Live, remove Awaiting Presenter(s)
  logoStatus.classList.remove('waiting');
  tooltipStatus.innerHTML = 'Room is Live';
}

let _room;

function go_live() {
}

async function room_enter(room) {
  _room = room;
  if (room.room_def.programming !== 'scheduled' || room.me.is_viewer)
  {
    return;
  }

  // Awaiting Presenter(s)
  logoStatus.classList.add('waiting');
  tooltipStatus.innerHTML = 'Awaiting Presenter(s)';


  let btn_container = document.querySelector('li.golive');
  let btn = document.querySelector( ".js-golive input" );
  btn.checked = false;
  document.querySelector(".js-golive").classList.remove( "hide" );
  btn_container.addEventListener('click', function(e) {

    if (btn.checked) {
      room.send({command: 'live', live: true});
      handle_live(true);

    }
    else {
      btn.checked = true;
    }

 })

}

async function room_leave(room) {


}


async function user_add(user) {
  if (_room.is_live) {
      user.send({command: 'live', live: true});
  }
}


export default { room_enter, room_leave, handle_live, user_add }
