
import { detectBlink } from "../components/brfv5/utils/utils__blink_detection";


let _leftEyeBlinked         = false;
let _rightEyeBlinked        = false;

let _leftEyeTimeOut         = -1;
let _rightEyeTimeOut        = -1;

const _leftEyeLidDistances  = [];
const _rightEyeLidDistances = [];


let _timerElm = null;
let _timer = 0;
let _high_score = 0;

function render_timer(ctx, elapsed, pts) {

  if(!pts) {
    return;
  }

  let secs = (elapsed/1000).toFixed(2);
  if(elapsed < 200) {

    ctx.fillStyle = "#ffffff";
    ctx.moveTo(pts[42].x, pts[42].y);
    ctx.lineTo(pts[43].x, pts[43].y);
    ctx.lineTo(pts[44].x, pts[44].y);
    ctx.lineTo(pts[45].x, pts[45].y);
    ctx.lineTo(pts[46].x, pts[46].y);
    ctx.lineTo(pts[47].x, pts[47].y);
    ctx.fill();


    ctx.moveTo(pts[36].x, pts[36].y);
    ctx.lineTo(pts[37].x, pts[37].y);
    ctx.lineTo(pts[38].x, pts[38].y);
    ctx.lineTo(pts[39].x, pts[39].y);
    ctx.lineTo(pts[40].x, pts[40].y);
    ctx.lineTo(pts[41].x, pts[41].y);
    ctx.fill();

    }

  ctx.fillStyle = '#00a0ff';
  let x = pts[18].x + 16;
  let y = pts[18].y + 16;
  ctx.font = '44px monospace';
  ctx.fillText(secs, x,y);
}

async function room_enter(room) {

  // room.set_help_text(help_text);
  // room.show_help();
  _timer = Date.now();
  _high_score = 0;

  _timerElm = document.createElement('div');
  _timerElm.className = ('blink_timer');

  document.querySelector('main').appendChild(_timerElm);

  room.me.after_render = function(ctx, pts) {
    let elapsed = Date.now() - _timer;
    if (elapsed > _high_score) {
      room.me.setAvatarText((elapsed / 1000).toFixed(2));
      _high_score = elapsed;
    }
    render_timer(ctx, elapsed, pts);
    detect_blink(room,pts);
    if (!pts) {
      _timer = Date.now();
      room.send({
        command: 'blinkdata',
        last_blink: _timer,
        high_score: _high_score
      });
    }

  }

  room.send({
    command: 'blinkdata',
    last_blink: _timer,
    high_score: _high_score
  });
}

async function room_leave(room) {

  // SFG: temp location for show/hide mask editor in this room
  document.querySelector('main').removeChild(_timerElm);

  _timerElm = null;
  room.me.after_render = null;
}

async function user_add(user) {

  user.send({
    command: 'blinkdata',
    last_blink: _timer,
    high_score: _high_score
  });

  user.after_render = function(ctx, pts) {
    let elapsed = Date.now() - user.last_blink;

    render_timer(ctx, elapsed, pts);
    if (elapsed > user.high_score && user.setAvatarText) {
      user.setAvatarText((elapsed / 1000).toFixed(2));
    }
  }
}

async function handle_blinkdata(user, blink_data) {
  console.log('blink data', blink_data);
  user.last_blink = blink_data.last_blink;
  user.high_score = blink_data.high_score;
  if (user.setAvatarText) {
    user.setAvatarText((user.high_score / 1000).toFixed(2));
  }
}

function detect_blink(room, lm) {

  const leftEyeLandmarks  = [lm[36], lm[39], lm[37], lm[38], lm[41], lm[40]];
  const rightEyeLandmarks = [lm[45], lm[42], lm[44], lm[43], lm[46], lm[47]];

  detectBlinkLeft( leftEyeLandmarks,  _leftEyeLidDistances);
  detectBlinkRight(rightEyeLandmarks, _rightEyeLidDistances);

  if (_leftEyeBlinked || _rightEyeBlinked) {
    _timer = Date.now();
    room.send({
      command: 'blinkdata',
      last_blink: _timer,
      high_score: _high_score
    });
  }

}



const detectBlinkLeft = (lm, distances) => {

  const blinked = detectBlink(lm[0], lm[1], lm[2], lm[3], lm[4], lm[5], distances);

  // Keep a blink status for 0.150 seconds, then reset:

  if(blinked) {

    // Set blinked! Reset after 150ms.

    _leftEyeBlinked = true;

    if(_leftEyeTimeOut > -1) { clearTimeout(_leftEyeTimeOut); }

    _leftEyeTimeOut = setTimeout(() => { _leftEyeBlinked = false; }, 150);
  }
}

const detectBlinkRight = (lm, distances) => {

  const blinked = detectBlink(lm[0], lm[1], lm[2], lm[3], lm[4], lm[5], distances);

  if(blinked) {

    // Set blinked! Reset after 150ms.

    _rightEyeBlinked = true;

    if(_rightEyeTimeOut > -1) { clearTimeout(_rightEyeTimeOut); }

    _rightEyeTimeOut = setTimeout(() => { _rightEyeBlinked = false; }, 150);
  }
}


export default { room_enter, room_leave, user_add, handle_blinkdata }
