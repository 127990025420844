
import MyAvatar from "../components/MyAvatar";
import { Mask } from "../components/Mask";
import { Media } from "../components/Media";

// regex create links from json text
function linkify(text) {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank" class="break-all">' + url + '</a>';
    });
}

async function handle_qa(user, msg) {

  let mask;
  if (user.metadata) {
    mask = new Mask ();
    mask.deserialize(user.metadata);
  }
  else if (user.mask) {
    mask = user.mask;
  } else {
    mask = Mask.myMask;
  }
  addChatLine(mask, user.avatar_pts, msg.message);
}

function isVisible() {
  return document.querySelector('#room-chat.modal-visible');}

function addChatLine(avatar, pts, text) {
    if (!isVisible()) {
      document.querySelector('button.icon-chat').classList.add('unread');
    }

    const canvas = document.createElement('canvas');
    MyAvatar.update_canvas(canvas, avatar, pts);
    let li = document.createElement('li');
    li.appendChild(canvas);
    let p = document.createElement('p');

    // SFG: create link text
    let textOut = linkify(text);

    // p.innerText = text;
    p.innerHTML = textOut;

    li.appendChild(p);
    document.getElementById('chat').appendChild(li);
    li.scrollIntoView();
}


async function room_enter(room) {


  // chat form
  var sendMessage = function(evt) {
    evt.preventDefault();
    let chat_message = document.querySelector('.chat-message');
    let text = chat_message.value;
    addChatLine(Mask.myMask, MyAvatar.get_points(), text);
    room.send({command: 'chat', message: text});
    document.querySelector('.chat-message').value = '';

  };


  // qa form
  var sendQa = function(evt) {
    if(evt) evt.preventDefault();
    let qa_message = document.querySelector('.qa-message');
    let text = qa_message.value;
    room.send({command: 'qa', message: text});

    room.fire_event('handle_qa', room.me, {message: text});

    document.querySelector('.qa-message').value = 'sending ...';

    // SFG: empty message
    setTimeout(function(){
      document.querySelector('.qa-message').value = '';
    }, 800);

  };

  var form = document.getElementById("qa-form");
  form.addEventListener("submit", sendQa, true);

  const qaSubmitBtn = document.querySelector('.qa-submit');
  qaSubmitBtn.addEventListener('click', (e) => {
    sendQa();
  });


}

async function room_leave(room) {

}


export default { room_enter, room_leave, handle_qa }
