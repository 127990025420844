


/* Activity recording */



let prev_pts;


function frame_delta(pts1, pts2) {

  // simplest delta function

  return {
    pts: pts1.map(function(pt1,i) {
      return {
        x: pt1.x-pts2[i].x,
        y: pt1.y-pts2[i].y,
      };
    })
  };

}



let idx = 0;
let past_delta = [];

let records = [];

let rec_uid = 0;

function inspector() {
  // find a local peak

  const MAX_QUANT_NOMOVE = 20;
  const MIN_QUANT_MOVE = 150;
  const MIN_LENGTH = 10;
  const MAX_LENGTH = 30;


  // we find a frame n for which
  /*
   * quant[n] <= MAX_QUANT_NOMOVE
   * There is an m between n+MIN_LENGTH and n+MAX_LENGTH such that
   * * There is an quant[n:m] > MIN_QUANT_MOVE
   * * quant[m] M= MAX_QUANT_NOMOVE
   */


  for(let n=0; n < past_delta.length; n++) {
  }

}


// * record separately per user
// * extract separately per user

let Record = {
  record_pts: function(uid, pts) {
    //console.log('pts', pts);
    if (rec_uid != 0 && rec_uid != uid) {
      console.log('skipping uid', uid);
      return;
    }
    rec_uid = uid;
    if (prev_pts) {
      if (past_delta.length < 2000) {
        let delta = frame_delta(prev_pts, pts);
        delta.quant = delta.pts.map(
          (pt => Math.abs(pt.x)+Math.abs(pt.y)))
            .reduce((a,b) => a+b);
        past_delta.push(delta);
      }
    }
    prev_pts = pts;

  },

  get_pts: function (pts) {
    if (past_delta.length == 0) {
      return pts;
    }
    idx = idx % past_delta.length;
    let delta = past_delta[idx].pts;
    idx = idx + 1;
    return pts.map(function(p,n) {
      return {
        x: p.x + delta[n].x,
        y: p.y + delta[n].y
      };
    });

  }
};


export { Record }

