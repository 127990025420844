
let _room;

function show_overlay() {
  document.querySelector('.js-status-full ').classList.remove('hide');
}


async function room_enter(room) {
  _room = room;
}


window.showOverlay = function() {
  show_overlay();
  _room.send({ command: "overlay" });
}

async function handle_overlay() {
  show_overlay();
}

export default { room_enter, handle_overlay }

