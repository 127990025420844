// Adds RoomQr code to room info modal.
// needs work: Qr code points to https://rooms.wealgo.org/rooms/roomname ...

var _room = null;

let room_url_public;
let room_url_private;


async function room_enter(room) {

  _room = room;
  room_url_public = 'https://where.wealgo.org/' + room.room_def.name;
  room_url_private = 'https://where.wealgo.org/' + room.room_def.name + window.location.search;

  // using api.qrserver.com
  let qr = document.createElement('div');
  qr.className = 'qr_container';
  var url = 'https://api.qrserver.com/v1/create-qr-code/?data=' + encodeURIComponent(room_url_public);
  qr.innerHTML = `<img id='barcode' src="${url}" alt="${room_url_public}" title="${room_url_public}" width="200" height="200" />`;
  let modal_qr = document.querySelector('.js-share-qr');
  modal_qr.appendChild(qr);


  // Public / Private URL
  let public_url = `For Invites:<br><a class="underline" href="${room_url_public}">${room_url_public}</a><br><br>`;
  let private_url = `For You:<br><a class='underline' href="${room_url_private}">${room_url_private}</a><br><br>
                     <span class="smll"><i>Note: Only Room Owners see this private link.<br>
                     Keep your Private link safe and share only with people you invite</i></span><br /><br />`;
  let div = document.createElement('div');
  div.innerHTML = public_url;

  let modal_url = document.querySelector('.js-share-url');
  modal_url.appendChild(div);

  // insert private url if user is admin
  if(room.me.is_admin) {
    modal_url.insertAdjacentHTML('beforeend', private_url);
  }


}

async function room_leave(room) {

  // document.querySelector('main').removeChild(room.div_qr);

}


export default { room_enter, room_leave }
