function Message(classname) {
  var elements = document.querySelectorAll(".message");
  for (let i = 0; i < elements.length; i++) {
    let elm = elements[i];
    if (elm.classList.contains(classname)) {
      elm.style.display = "block";
    } else {
      elm.style.display = "none";
    }
  }
}

export { Message };
