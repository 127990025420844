
// Generate Mask

  const NewColors =
  [
    "#00A0FF",
    "transparent",
    "#FFFFFF",
    "#FF0000",
    "#00a0ff",
    "#ff0000",
    "#ff8d00",
    "#ffff00",
    "#00ff00",
    "#c700c7",
    "#932e00",
    "#b3b3b3",
    "#ffffff",
    "#000000"
  ];


  const groups = [
    [0],
    [1,2],
    [3,4],
    [5,6],
    [7,8],
    [9],
    [10],
    [11],
    [12],
    [13],
    [14,15],
    [16,17]
  ];


function generateMask() {

  let newMask = [];
  groups.forEach((element, index) => {

    const randomNewColor = (index === 0)
        ? "transparent"
        :  NewColors[Math.floor(Math.random() * NewColors.length)]

    element.forEach((el) => {
      newMask.push({'region':el, 'fill':randomNewColor, 'stroke': randomNewColor, 'dots': randomNewColor, glitterFill: false});
    })
  });

  return newMask;

}

export { generateMask };
