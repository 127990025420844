// PlayVideo
// plays prerecorded Wealgo video(s) when no one is in the room

var _room = null;

// play video array
// https://blog.enginesoft.in/play-multiple-videos-one-after-another-using-html5-and-javascript/
// https://stackoverflow.com/questions/10668399/play-next-video-in-an-array-on-ended

var room_video;
let recording = `
        <video id="recording" playsinline>
          <source type="video/mp4" media="all and (min-width: 1024px)">
        </video>
`;

// unmute button
const _unmute = `<div id="unmute" class='unmute_btn'></div>`;

let lastPoints = new Date().getTime() - 10000;
let intervalHandle;

let _recording;
let _container;
let is_continuous;

// now with css filter on same icon
// const playbackLabel = document.querySelector('.js-status-playback');
// const liveLabel = document.querySelector('.js-status-live');
// const toolTipLiveStatus = document.querySelector('.tooltiptext-live-status');

// sorry room is full
const fullLabel = document.querySelector('.js-status-full');

// SFG: doing logo animation status indication
let logoStatus = document.querySelector('.js-room-status-logo')
let tooltipStatus = document.querySelector('.js-room-status-tooltip')

// removed from in_room dd(20211016)
// const btnNightMode = document.querySelector('.js-nightmode');

let is_playing = false;
async function user_data() {

  if (_container) {
    lastPoints = new Date().getTime();
    if (is_playing) {

      _container.classList.add('hide');
      _recording.pause();
      logoStatus.classList.remove('playback');
      tooltipStatus.innerHTML = 'Room is Live';
      is_playing = false;
    }
  }

}


async function room_enter(room) {
  let video_idx = 0;

  is_continuous = room.room_def.programming === 'continuous';

  _room = room;
  room_video = room.room_def.room_video;

  // create play-video container
  if(room_video) {
    let div = document.createElement('div');
    div.className = 'play-recording hide';
    div.innerHTML = recording;
    document.querySelector('main').appendChild(div);
    room.div_recording = div;

    _recording = document.getElementById('recording');
    _recording.setAttribute('src', room_video[0]); // playing first from array, untill magic happens ;)
    _container = document.querySelector('.play-recording');

    _recording.onended = function() {
      // move to next video
      video_idx = (video_idx + 1) % room_video.length;
      _recording.setAttribute('src', room_video[video_idx]);
    }
  }

  // create button for unmuting sound; autoplay must be muted initially
  // still not allowing autoplay (also while muted) on safari / firefox
  let unmute = document.createElement('div');
  unmute.className = 'unmute_container hide'
  unmute.innerHTML = _unmute;
  document.querySelector('main').appendChild(unmute);
  room.div_unmute = unmute;

  // click to unmute & hide button
  unmute.addEventListener("click", function(){
    _recording.muted = false;
    unmute.classList.add('hide');
  })



// we play recording with sound, on error, mute sound & set unmute button
function playRecording() {
  var promise = _recording.play();
  if (promise !== undefined) {
      promise.catch(error => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
          unmute.classList.remove('hide');
          _recording.muted = true;
          _recording.play();
        }).then(() => {
          // Auto-play started
        });
      }
    }


  // set interval timer to check last points
  intervalHandle = setInterval(function() {

    if (!_room.is_live && !is_continuous) {
      // delay playback when nothing live
      lastPoints = new Date().getTime();
    }
    let now = new Date().getTime();
    var duration = now - lastPoints;

    if(room.me.is_viewer) {
      // liveLabel.classList.remove('playback');
      // toolTipLiveStatus.innerHTML = 'Room is Live';

      room.me.is_queued ? fullLabel.classList.remove('hide') : fullLabel.classList.add('hide') ;
      // document.querySelector('.qa-container').classList.remove('hide');


    }

    if (duration > 5*1000) {
        if(room.me.is_viewer) {
          _container.classList.remove('hide');

          // liveLabel.classList.add('playback');
          // toolTipLiveStatus.innerHTML = 'Room Playback';

          // SFG: live status via animated logo
          logoStatus.classList.add('playback');
          tooltipStatus.innerHTML = 'Playback Recording';

          // _recording.play();
          playRecording();
          is_playing = true;

      }
    }
  }, 1000);

}


async function room_leave(room) {
  _room = null
  // document.querySelector('main').removeChild(room.div_recording);
  // document.querySelector('main').removeChild(room.btn_hide);
  // document.querySelector('main').removeChild(room.btn_show);
  // document.querySelector('main').removeChild(room.div_unmute);
  clearInterval(intervalHandle);
}


export default { user_data, room_enter, room_leave }
