
import { Mask } from "../components/Mask";
import { MyAvatar } from "../components/MyAvatar";
import { Media } from "../components/Media";
import { generateMask } from "../components/GenerateMask";

const help_text = `

  <h2>This room has not yet started</h2>
  About this Room <br />
  <small>Wednesdays, 23rd April, 2021 | 20:00 - 21:00hrs CET</small>

  <br /><br />

  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  Vivamus in mi facilisis, finibus ipsum at, pharetra erat. Curabitur eu viverra odio.
  Praesent varius imperdiet nulla. Donec metus augue, suscipit non odio et, hendrerit imperdiet felis.

  <br /><br />
`;

const qa_container = `
  <div class='qa-inner'>

    <form id="qa-form" class='qa-form'>
      <input type='text' class='qa-message' placeholder='Ask a question' autofocus>
    </form>

    <div class='qa-submit material-icons overlay'>send</div>

  </div>

`;


// add random user avatar to QA box ...
function addQaAvatar() {
  const canvas = document.createElement('canvas');
  canvas.width = 1200;
  canvas.height = 900;
  canvas.style.width = '90px';
  canvas.style.height = '60px';
  let div = document.createElement('div');
  Mask.myMask.current();
  Mask.myMask.render(canvas.getContext('2d'), MyAvatar.get_points());
  div.appendChild(canvas);
  document.getElementById('qa-form').appendChild(div);
}


async function room_enter(room) {

  room.set_help_text(help_text);
  room.show_help();

  // Remove UI elements from audience
  document.querySelector('.showdoors').style.display = 'none';
  document.querySelector('.room-controls').style.display = 'none';
  document.querySelector('.room-functions').style.display = 'none';


  // create qa-container & append to main
  let qa = document.createElement('div');
  qa.className = 'qa-container';
  qa.innerHTML = qa_container;
  document.querySelector('main').appendChild(qa);
  room.div_qa = qa

  // set autofocus on input
  document.querySelector('.qa-message').focus();

  // set Qa random user avatar
  addQaAvatar();

  // qa form
  var sendQa = function(evt) {
    if(evt) evt.preventDefault();
    let qa_message = document.querySelector('.qa-message');
    let text = qa_message.value;
    room.send({command: 'qa', message: text});
    document.querySelector('.qa-message').value = 'question sent ...';

    // SFG: empty message
    setTimeout(function(){
      document.querySelector('.qa-message').value = '';
    }, 1200);

  };

  var form = document.getElementById("qa-form");
  form.addEventListener("submit", sendQa, true);

  const qaSubmitBtn = document.querySelector('.qa-submit');
  qaSubmitBtn.addEventListener('click', (e) => {
    sendQa();
  });

}

async function room_leave(room) {

  document.querySelector('main').removeChild(room.div_qa);

  // Reset UI elements from audience
  document.querySelector('.showdoors').style.display = 'block';
  document.querySelector('.room-controls').style.display = 'block';
  document.querySelector('.room-functions').style.display = 'block';


}


export default { room_enter, room_leave }
