
var _room = null;
var _titleElm = document.getElementById('js-room-title');

function updateTitle() {
  _titleElm.style.display = 'block';
  if (!_room) {
    _titleElm.innerText = 'ENTER Wealgo';
  } else {


    if(_room.me.is_queued) {
      _titleElm.innerHTML =
          (_room.room_def.title || _room.name);
    } else {
      _titleElm.innerHTML =
          (_room.room_def.title || _room.name);

          // revisit for room info details
          //  +
          // '<span class="userCounter">OCCUP: ' + (_room.users.length+1)+ '</span>';
    }
  }
  document.querySelector( ".js-room-title" ).classList.remove( "invisible" );
}

async function room_enter(room) {
  _room = room;
  updateTitle();
}

async function user_add() {
  updateTitle();
}

async function user_remove() {
  updateTitle();
}

async function room_leave() {
  _room = null;
  updateTitle();
}

export default { user_add, user_remove, room_enter, room_leave }
