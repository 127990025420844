
import { Codec } from "./Codec";
import { Settings } from "../globals";
import Smoother from "../components/Smoother";

// Points of a sample face
const _samplePoints = [
  {x:736,y:320},{x:728,y:378},{x:719,y:431},{x:709,y:486},{x:696,y:543},{x:669,y:592},{x:633,y:632},{x:582,y:664},{x:517,y:676},{x:444,y:668},{x:390,y:637},{x:343,y:595},{x:314,y:544},{x:300,y:486},{x:289,y:423},{x:280,y:371},{x:270,y:312},{x:722,y:256},{x:703,y:213},{x:653,y:200},{x:598,y:206},{x:549,y:223},{x:485,y:221},{x:432,y:206},{x:377,y:199},{x:327,y:211},{x:301,y:256},{x:514,y:262},{x:514,y:297},{x:515,y:336},{x:520,y:375},{x:568,y:422},{x:542,y:426},{x:512,y:431},{x:483,y:427},{x:459,y:420},{x:675,y:294},{x:655,y:282},{x:622,y:281},{x:583,y:290},{x:618,y:300},{x:651,y:305},{x:441,y:288},{x:409,y:275},{x:379,y:277},{x:352,y:289},{x:379,y:297},{x:412,y:293},{x:604,y:519},{x:570,y:501},{x:539,y:493},{x:513,y:502},{x:483,y:496},{x:448,y:506},{x:418,y:524},{x:451,y:537},{x:483,y:537},{x:511,y:537},{x:542,y:533},{x:572,y:533},{x:591,y:517},{x:540,y:511},{x:512,y:514},{x:483,y:512},{x:431,y:519},{x:481,y:514},{x:512,y:515},{x:540,y:511}
  ];

const _mapPoints = [
  127, // 0
  227,
  137,
  215,
  136,
  150,
  176,
  148,
  152, // 8
  377, // 9
  400, // 10
  379, // 11
  365, // 12
  435, // 13
  366, // 14
  447, // 15
  356, // 16
  71,
  63,
  105,
  66,
  55,
  285,
  296,
  334,
  293,
  301,
  6, // 27, nosebridge
  197,
  5,
  4,
  48,
  97,
  2,
  326,
  294,
  33,
  160,
  158,
  133,
  153,
  144,
  362,
  385,
  387,
  263,
  373,
  380,
  61,
  39,
  37,
  0,
  267,
  269,
  291,
  321,
  314,
  17,
  84,
  91,
  62,
  82,
  13,
  312,
  308,
  316,
  15,
  86
];

let smoothers;

function get_bounds(pts) {
  const x2 = Math.max(...(pts.map((p) => p.x)));
  const x1 = Math.min(...(pts.map((p) => p.x)));
  const y2 = Math.max(...(pts.map((p) => p.y)));
  const y1 = Math.min(...(pts.map((p) => p.y)));
  return {x: x1, y: y1, width: x2-x1, height: y2-y1};
}


class Face {

  _buffer; // ArrayBuffer with encoded landmarks

  _pts; // [{x:..,y:..}, ..]
  _pos; // {x:.., y:..}  in range -1,1
  _bounds;

  static fromBuffer(buf) {
    let face = new Face();
    face._buffer = buf;
    face._buffer = Codec.decodeLandmarks(buf, { width: 4096, height: 4096 }, { x: 0, y: 0});

    return face;
  }

  static fromBrfv5(brfv5Face, pos) {
    let face = new Face();
    face._pts = brfv5Face.landmarks;
    face._bounds = brfv5Face.bounds;
    face._pos = pos || { x:0, y:0 };

    return face;
  }


  static fromDefault(pos) {
    let face = new Face();
    face._pts = _samplePoints;
    face._bounds = { x:200,y:200, width:800,height:800};
    face._pos = pos || { x:0, y:0 };

    return face;
  }



  static fromMediaPipe(results, pos) {
    if (!smoothers) {
        let n;
        smoothers = [];
        for(n=0; n < 68; n++) {
            smoothers[n] = { x: new Smoother(), y: new Smoother() };
        }
    }

    let face = new Face();

    face._pts = _mapPoints.map( (i,n) => {
      let p = results.multiFaceLandmarks[0][i];
    //  console.log(i,p);
      return { x: smoothers[n].x.filter(p.x*640), y: smoothers[n].y.filter(p.y*480)};
    });
    face._bounds = get_bounds(face._pts);
    face._pos = pos || { x:0, y:0 };

    return face;
  }

  // Adjust position based on nose direction
  //
  move(room, me_size) {

    if (room.w === 0) {
      return this._pos;
    }

    const _width = Settings.CAMERA_WIDTH;
    const _height = Settings.CAMERA_HEIGHT;

    // Determine screen bounds using face bounds.
    // First we mirror the face and calculate relative to center of face
    let b = {
      left: this._bounds.x + this._bounds.width - _width / 2,
      right: _width / 2 - this._bounds.x,
      top: _height / 2 - this._bounds.y,
      bottom: this._bounds.y + this._bounds.height - _height / 2,
    };

    // Translate these to [-1,1] coordinates
    let bounds = {
      left: (((b.left / _width) * me_size) / room.w) * 2 - 1,
      right: 1 - (((b.right / _width) * me_size) / room.w) * 2,
      top: (((b.top / _width) * me_size) / room.h) * 2 - 1,
      bottom: 1 - (((b.bottom / _width) * me_size) / room.h) * 2,
    };

    // find move rate by nose position
    const landmarks = this._pts;
    const noseX = landmarks[30].x;
    const noseY = landmarks[30].y;
    const noseExpectY = (landmarks[29].y*3 + landmarks[33].y*2) / 5;
    const noseExpectX = (landmarks[31].x + landmarks[35].x) / 2;
    const moveX = -(noseX - noseExpectX) / 2;
    const moveY = (noseY - noseExpectY) / 2;

    // Move and limit new position to bounds
    let pos = this._pos;
    let newx = pos.x + moveX * Settings.MOVE_SPEED_X;
    let newy = pos.y + moveY * Settings.MOVE_SPEED_Y;
    pos.x = Math.min( bounds.right, Math.max(bounds.left, newx));
    pos.y = Math.min( bounds.bottom, Math.max(bounds.top, newy));

    // console.log(pos.x);
    // console.log(pos.y);
    let nx = pos.x - ((((landmarks[30].x - _width / 2 ) / _width) * me_size) / room.w) * 2 ;
    let ny = pos.y - ((((_height / 2 - landmarks[30].y) / _width) * me_size) / room.h) * 2 ;

    // Set door to 0-4 (0=None, 1=E, 2=N, 3=W, 4=S)
    if (ny > -.2 && ny < .2 && pos.x == bounds.right) {
      pos.door = 1;
    } else if (nx > -.2 && nx < .2 && pos.y == bounds.top) {
      pos.door = 2;
    } else if (ny > -.2 && ny < .2 && pos.x == bounds.left) {
      pos.door = 3;
    } else if (nx > -.2 && nx < .2 && pos.y == bounds.bottom) {
      pos.door = 4;
    } else {
      pos.door = 0;
    }

    this._pos = pos;
    return pos;

  }


  getBuffer() {
    if (!this._buffer) {
      this._buffer = Codec.encodeLandmarks(
        { width: Settings.CAMERA_WIDTH, height: Settings.CAMERA_HEIGHT },
        this._bounds,
        this._pts,
        this._pos
      );
    }

    return this._buffer;
  }


  getPts() {
  }
}

export { Face };
