
import { Media } from "../components/Media";

//
// Stage is for people on the stage

async function room_enter(room) {

  // hide UI elements from Stage
  document.querySelector('.control-type.share').style.display = 'none';
  document.querySelector('.control-type.map-icon').style.display = 'none';
  document.querySelector('.control-type.help-icon').style.display = 'none';


  const password = /brf4/;
  const is_on_stage = password.test(location.search);

  if (is_on_stage) {
    await room.add_mod('MaskEditor');
    await room.add_mod('ReadChat');
    await room.add_mod('FaceScan');
  }
  else {
    await room.add_mod('Audience');

    // mute
    Media.LocalAudioTrack.enabled = false;
  }
}

async function room_leave(room) {
}



export default { room_enter, room_leave }
