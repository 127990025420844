/// Handler for Face Light Switch aka Nightmode
const toggleSwitch = document.querySelector(
  '.theme-switch input[type="checkbox"]'
);

toggleSwitch.addEventListener("change", switchTheme, false);

function switchTheme(e) {
  if (e.target.checked) {
    document.body.classList.add("facelight_on");
  } else {
    document.body.classList.remove("facelight_on");
  }
}


// Global Modal Handlers
// bottom nav expanded == buttons, collapsed == li
let buttons = document.querySelectorAll('nav button, .more-nav-popup li, .js-terms');
let modals = document.querySelectorAll('.modal');

function showModal(id) {
  let m = document.getElementById(id);
  m.classList.add('modal-visible');
  if (document.querySelector('.modal-visible #voice-tab.open')) {
    window.current_room.fire_event('open_voice_editor');
  }
  if (document.querySelector('#room-chat.modal-visible')) {
    document.querySelector('button.icon-chat').
          classList.remove('unread');
  }
}

function hideModals() {
  if (document.querySelector('.modal-visible #voice-tab.open')) {
    window.current_room.fire_event('close_voice_editor');
  }
  modals.forEach(m => {
    m.classList.remove('modal-visible');
  });

}

buttons.forEach(b => {
  b.addEventListener('click', event => {
    hideModals();
    showModal(b.dataset.modal);
  });
});

modals.forEach(m => {
  let x = m.querySelectorAll('button.js-modal-close');
  x.forEach(y => {
    y.addEventListener('click', hideModals);
  })
});

function removeClasses(el, classArr) {
  el.classList.remove(...classArr);
}

// Global modal subnav tabs handlers
const tabTriggerClassesArr = [];
const tabTriggerBtns = document.querySelectorAll('.tabs li.tab');

tabTriggerBtns.forEach(function(tabTriggerBtn){

  tabTriggerClassesArr.push(tabTriggerBtn.getAttribute("data-tab-trigger"));

    tabTriggerBtn.addEventListener('click', function(evt){
      const currentTabData = document.querySelector('.tab-content[data-tab-content="' + this.dataset.tabTrigger + '"]');
      let modalBodyClosest = currentTabData.closest(".modal-body");


      let elAvatarEditor = document.querySelector(".avatar-editor");



      const tabClass = evt.target.getAttribute("data-tab-trigger");
      removeClasses(elAvatarEditor, tabTriggerClassesArr);

      switch (tabClass) {
        case "face-editor":
          elAvatarEditor.classList.add("face-editor");
          elAvatarEditor.querySelector('.modal-body').classList.remove("modal-body__wide");
          break;
        case "voice-editor":
          elAvatarEditor.querySelector('.modal-body').classList.remove("modal-body__wide");
          break;
        case "my-avatars":
          elAvatarEditor.classList.add("my-avatars");
          elAvatarEditor.querySelector('.modal-body').classList.add("modal-body__wide")
          break;
        default:
          break;
      }

      let modalNav = tabTriggerBtn.closest(".modal-subheader");

      if (document.querySelector('.modal-visible #voice-tab.open')) {
        window.current_room.fire_event('close_voice_editor');
      }

      // remove classess only in nearest modal-body & modal-subheader classes
      Array.from(modalBodyClosest.querySelectorAll('.tab-content.open')).forEach((el) => el.classList.remove('open'));
      Array.from(modalNav.querySelectorAll('.tabs li.active')).forEach((el) => el.classList.remove('active'));

      // add classes
      currentTabData.classList.add('open');
      this.classList.add('active');

      if (document.querySelector('.modal-visible #voice-tab.open')) {
        window.current_room.fire_event('open_voice_editor');
      }
    });
});


// hide WeAlgo chrome for recording a clean video
window.hideChrome = function(hideChrome) {
  document.querySelector('header').style.display = 'none';
  document.querySelector('nav.menu').style.display = 'none';
  document.querySelector('.room-controls').style.display = 'none';
  document.querySelector('.terms').style.display = 'none';
}
