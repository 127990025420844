
let voices;

async function handle_chat(user, msg) {

  let utt = new SpeechSynthesisUtterance(msg.message);
  utt.lang = msg.lang;
  speechSynthesis.speak(utt);
}


async function room_enter() {

  document.getElementById('chat').style.display = 'none';
}


export default { handle_chat, room_enter };

