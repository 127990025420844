const Feedback = () => {

  // SFG: store Rate checkbox settings local storage
  const rateContainer = document.querySelector(".js-rate_container");
  const closeRate = document.querySelector(".js-close-rate");
  const hideRates = document.querySelector(".js-hide-rates");

  window.showRateStorage = localStorage;
  if (!showRateStorage.getItem("showRate")) {
    showRateStorage.setItem("showRate", "dontShow"); // Use "show" instead of "dontShow" to show the rating modal
  }

  closeRate.addEventListener("click", (evt) => {
    rateContainer.classList.add("hide");
    if (hideRates.checked == true) {
      showRateStorage.setItem("showRate", "dontShow");
    }
  });
};

export { Feedback };
