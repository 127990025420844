
async function room_enter(room) {

  const help_text = `


    <h2 class=''>This room has not yet started</h2><br><br>

    <h2>${room.room_def.title}</h2>
    ${room.room_def.room_start} | ${room.room_def.room_stop} CET<br><br>
    Topic: ${room.room_def.topic} <br><br>
    Room Type: ${room.room_def.type} <br>
    Privacy: ${room.room_def.privacy} <br>


    <br /><br />
  `;



  room.set_help_text(help_text);
  room.show_help();

  // Remove UI elements from audience
  document.querySelector('.js-help-close').style.display = 'none';

  console.log(room.room_def)  ;

}


async function room_leave(room) {

  // Reset UI elements from audience
  document.querySelector('.js-help-close').style.display = 'block';


}


export default { room_enter, room_leave }
