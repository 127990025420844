
const help_text = `
  This is a portal to your Private Room.
  <br /><br />
  Your Private Room is known to you only, because you created its name —which is also its URL—.
  Keep it save or share with confidants only.
  <br /><br />
  To create a Private Room, type its name in the input box below and hit GO to enter.
  <br /><br />
  <br /><br />
  <form id="private_room">
    <input type="text" name="room_name" required />
    <input class='button' type="submit" value='GO'/>
  </form>

  <span id='backToLobby' class="back fab overlay blue material-icons">keyboard_backspace</span>

`;


async function room_enter(room) {
  room.set_help_text(help_text);
  room.show_help();

  document.querySelector('.help-close').style.display = 'none';


  let form = document.getElementById('private_room');
  form.addEventListener('submit', function(evt) {
    evt.preventDefault();
    console.log('entering private room', form.elements.room_name.value);
    enter_private_room(room, form.elements.room_name.value);
    return false;
  });

  // back to Lobby
  let backToLobby = document.getElementById('backToLobby');
  backToLobby.addEventListener('click', function(evt) {
    room.switchTo('lobby');
  });


}


function enter_private_room(room, room_name) {
  room.switchTo(room_name);

}

export default { room_enter };
