
/* Enncoder and decoder for messages passed between peers
 * Messages contain landmarks,masks or metadata
 */




/// Decode the landmarks from the format specified in encodeLandmarks
///
/// Scaled using  size and offset
function decodeLandmarks(data, size, offset) {

  let { width, height } = size;

  let face = { x: data[2], y: data[3], width: data[4], height: data[5] };

  var aspect = data[6] / 0x1000;

  var new_aspect = width / height;
  var scaleX, scaleY, offsetX, offsetY;
  if (aspect > new_aspect) {
    // bound by width
    scaleX = width / 0x1000;
    scaleY = scaleX / aspect; // (width/0xffff) * (old_w/old_h)
    offsetX = 0;
    offsetY = (height - 0x1000 * scaleY) / 2;
  } else {
    // bound by height
    scaleY = height / 0x1000;
    scaleX = scaleY * aspect;
    offsetX = (width - 0x1000 * scaleX) / 2;
    offsetY = 0;
  }

  var face2 = {
    x: width - (face.x * scaleX + offsetX) - face.width * scaleX,
    y: face.y * scaleY + offsetY,
    width: face.width * scaleX,
    height: face.height * scaleY,
  };

  var pts = new Array(68);
  for (var i = 0; i < 68; i++) {
    pts[i] = {
      x: Math.round((data[8 + i * 2] * face2.width) / 0x1000 + face2.x + offset.x),
      y: Math.round((data[9 + i * 2] * face2.height) / 0x1000 + face2.y + offset.y),
    };
  }
  let pos_x = (data[144] / 0x4000) -2;
  let pos_y = (data[145] / 0x4000) -2;
  return { pts: pts, pos: { x:pos_x, y:pos_y, door: data[1] }};
}


function setMark(data, value) {
  data[7] = value;
}

function getMark(data) {
  return data[7];
}

/**
 * Encodes the landmark positions of a recognized face
 *
 * @param size {Object} Total size of video frame {width,height}
 * @param face {Object} Position and size of face within frame {x,y,width,height}
 * @param pts {Array}   Array with 68 landmarks [{x,y},...]
**/
function encodeLandmarks(size, face, pts, pos) {

  // Format in 16-bit words:
  // word 0 version byte = 0
  // word 1 door
  // word 2-3 x,y of face (in 4096x4096) coords
  // word 4-5 w,h of face (in 4096x4096) coords
  // word 6 aspect ratio (width/height)* 0x1000
  // word 7 mark = 0
  // word 8-141 landmarks coordinates relative to face bounding box

  const { width, height }  = size;
  var data = new Uint16Array(146);

  // version bytes
  data[0] = 1;
  // Activated door
  data[1] = pos.door;

  // face position and size in 4096x4096 coordinates
  data[2] = (face.x * 0x1000) / width;
  data[3] = (face.y * 0x1000) / height;
  data[4] = (face.width * 0x1000) / width;
  data[5] = (face.height * 0x1000) / height;

  // aspect ratio
  data[6] = (width / height) * 0x1000;
  data[7] = 0;

  for (var i = 0; i < 68; i++) {
    data[8 + 2 * i] = ((face.width - pts[i].x + face.x) * 0x1000) / face.width;
    data[9 + 2 * i] = ((pts[i].y - face.y) * 0x1000) / face.height;
  }

  data[144] = (pos.x + 2) * 0x4000;
  data[145] = (pos.y + 2) * 0x4000;
  return data;

}

const Codec = { encodeLandmarks, decodeLandmarks, setMark, getMark };
export { Codec };

