import { handleType, handleRegion, getSelectedRegionIndexes, REGION_IDX_NONE, REGION_IDX_ALL, } from "../MaskEditor";

const HandleColorsTypes = () => {
  const colorTypes = document.querySelectorAll(".js-color-type");

  const clearActive = () => {
    colorTypes.forEach((item) => {
      item.classList.remove("color-type--active");
    });
  };

  colorTypes.forEach((item) => {
    item.addEventListener("click", (evt) => {
      clearActive();
      evt.target.classList.add("color-type--active");
      handleType(evt.target.dataset.colortype, colorTypes);
    });
  });

  // Handle select all button
  const selectAll = document.querySelectorAll(".js-select-all");
  selectAll.forEach((item) => {
    item.addEventListener("click", () => {
      if (getSelectedRegionIndexes()[0] == REGION_IDX_ALL) {
        handleRegion(REGION_IDX_NONE);
      } else {
        handleRegion(REGION_IDX_ALL);
      }
    });
  });
};

export { HandleColorsTypes };
