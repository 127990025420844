const Hints = () => {

  // SFG: re-written: dont want pop up & local storage. anyoing.

  const hintsContainer = document.querySelector(".js-hints_container");
  const closeHints = document.querySelector(".js-close_hints");
  const showHints = document.querySelector(".js-hints-button");

  // SFG: show hints on button click
  showHints.addEventListener("click", (evt) => {
    hintsContainer.classList.remove("hide");
  });

  closeHints.addEventListener("click", (evt) => {
    hintsContainer.classList.add("hide");
  });
};

export { Hints };
