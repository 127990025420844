
// WebRtc wrapper
//
// Mostly uses flow described in https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
//
// Except that we actually only initiate renegotiation from one side of the peer
// (frankly, the solution in the blog seems to miss several cases of "cross" negiotiation)
//
// Works in conjuction with the signilling server /signalserve/signal.js

import { Media } from "../components/Media";

import { Settings } from "../globals";

// Use these ICE servers instead of server provided ones
let override_servers = [{"urls":["stun:stun2.l.google.com:19302"],"username":"","credential":""},{"urls":["turn:turn.wealgo.org:443?transport=tcp"],"username":"wealgo","credential":"gy7c1lR588ZasP91gz"},{"urls":["turn:turn.wealgo.org:443?transport=udp"],"username":"wealgo","credential":"gy7c1lR588ZasP91gz"}];


async function handle_sdpOffer(user, msg) {
  console.assert(user);

  try {
    const description = msg.sdp;
    const pc = user.peer;
    const offerCollision = description.type == "offer" &&
                         (user.makingOffer || pc.signalingState != "stable");

    user.ignoreOffer = !user.polite && offerCollision;
    if (user.ignoreOffer) {
      return;
    }
    if (offerCollision) {
      await Promise.all([
        pc.setLocalDescription({type: "rollback"}),
        pc.setRemoteDescription(description)
      ]);
    } else {
      await pc.setRemoteDescription(description);
    }
    if (description.type == "offer") {
      let answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);
      answer.sdp = setMediaBitrates(answer.sdp);

      user.send({command: 'sdp-offer', sdp: answer});
    }
  }
  catch(e) {
    console.error('handle description', e);
  }
};

async function handle_iceCandidate(user, msg) {

  try {
    let c = new RTCIceCandidate(msg.candidate);
    await user.peer.addIceCandidate(c);
  }
  catch(e) {
    if (!user.ignoreOffer) {
      console.error('Error adding ICE candidate', e);
    }
  }
}

async function user_remove(user) {

  if (user.channel) {
    await user.channel.close();
    delete user.channel;
  }

  if (user.trackElm) {
    user.trackElm.remove();
    delete user.trackElm;
  }


  if (user.peer) {
    user.peer.ontrack = null;
    user.peer.onremovetrack = null;
    user.peer.onremovestream = null;
    user.peer.onicecandidate = null;
    user.peer.oniceconnectionstatechange = null;
    user.peer.onsignalingstatechange = null;
    user.peer.onicegatheringstatechange = null;
    user.peer.onnegotiationneeded = null;
    await user.peer.close();
    delete user.peer;
  }

}

let current_room;

async function face_scanned(data) {
  for(let n in current_room.users) {
    if (current_room.users[n].channel && current_room.users[n].channel.readyState === "open")
    current_room.users[n].channel.send(data);
  }
}
async function room_enter(room) {
  current_room = room;
  /*for(let u of room.users) {
    await user_add(u);
  }*/
}

async function user_add(user) {
  //if (current_room.me.is_viewer || user.is_viewer)
  //  return;
  //if (current.me.is_queued) return;
  if (user.peer) return;

  console.assert(user.uid);
  console.assert(user.send);

  let options = {
    iceServers: override_servers,
    iceTransportPolicy:"all",
    iceCandidatePoolSize:0
  };

  user.peer = new RTCPeerConnection(options);
  user.makingOffer = false;
  user.polite = !user.incoming;

  // We resolve this promise when the data channel is open
  return new Promise(function(resolve, reject) {

    console.log('init peer connection with ', user.uid);

    user.channel = user.peer.createDataChannel('pts', {
      negotiated: true,
      ordered: false,
      maxRetransmits: 0,
      id: 1
    });
    user.channel.binaryType = 'arraybuffer';
    user.channel.onopen = function() {
      console.log('Data channel opened to ', user.uid);
      resolve();
    }
    user.channel.onmessage = async function(ev) {
      await user.handle_data(ev.data);
    }

    user.peer.onicecandidate = function(ev) {
      if (!ev.candidate || !ev.candidate.candidate) {
      } else {
        user.send({
          command: 'ice-candidate',
          candidate: ev.candidate
        });
      }
    }

    user.peer.onnegotiationneeded = async () => {
      try {

        if (user.polite) {
          // only negotiate from one side. Otherwise cross-communication always causes trouble
          return;
        }
        let pc = user.peer;
        user.makingOffer = true;
        const offer = await pc.createOffer({offerToReceiveAudio: 1});
        if (pc.signalingState != "stable") return;
        await pc.setLocalDescription(offer);
        //console.log("Sending before ", offer.sdp);
        offer.sdp = setMediaBitrates(offer.sdp);
        //console.log("Sending after ", offer.sdp);
        user.send({command:'sdp-offer', sdp: offer});
      } catch (e) {
        console.error(`negotiating error ${e}`);
      } finally {
        user.makingOffer = false;
      }
    };

    user.peer.ontrack = function(ev) {
      let stream = new MediaStream();
      stream.addTrack(ev.track, stream);

      // Enable stream except when we are a viewer of a scheduled event
      // In that case, we will enable on live event.
      stream.enabled = (!current_room.me.is_viewer || current_room.room_def.programming !== 'scheduled');
      console.log('Track received, enabled=', stream.enabled, current_room);

      user.track = stream;

      user.trackElm = document.createElement('audio');
      user.trackElm.autoplay = true;
      user.trackElm.srcObject = stream;


      // add track from remote peer
      document.getElementById('_audio_container').appendChild(user.trackElm);

    }

    if (Media.AudioDestination) {
      user.peer.addTrack(Media.AudioDestination.stream.getAudioTracks()[0]);
    }
    else {
      console.warn('No audio destination');
    }
    //user.peer.addTrack(Media.LocalAudioTrack);
  });
}



function setMediaBitrates(sdp) {
  return setMediaBitrate(sdp, "audio", Settings.AUDIO_BITRATE);
}

function setMediaBitrate(sdp, media, bitrate) {
  var lines = sdp.split("\n");
  var line = -1;
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].indexOf("m="+media) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    console.debug("Could not find the m line for", media);
    return sdp;
  }
  console.debug("Found the m line for", media, "at line", line);

  // Pass the m line
  line++;

  // Skip i and c lines
  while(lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf("b") === 0) {
    console.debug("Replaced b line at line", line);
    lines[line] = "b=AS:"+bitrate;
    return lines.join("\n");
  }

  // Add a new b line
  console.debug("Adding new b line before line", line);
  var newLines = lines.slice(0, line)
  newLines.push("b=AS:"+bitrate)
  newLines = newLines.concat(lines.slice(line, lines.length))
  return newLines.join("\n")
}

export default { room_enter, user_add, user_remove, handle_sdpOffer, handle_iceCandidate, face_scanned };
